export const inputfieldstyle = {
  // width: "80%",
  margin: "10px 0",
  backgroundColor: "#ee",
};

export const inputfieldstyle1 = {
  width: "100%",
  margin: "10px 10px 10px 0px",
  backgroundColor: "#ee",
};
export const inputfieldstyle2 = {
  width: "100%",
  // margin: "10px 10px 10px 0px",
  backgroundColor: "#ee",
};

export const savedField = {
  border: "4px solid yellow",
};

export const buttoncontainded = {
  color: "white",
  backgroundColor: "#45b653",
  padding: "10px 65px",
  fontSize: "1rem",
  fontWeight: "bold",
  letterSpacing: "1px",
  borderRadius: "25px",
  outline: "none",
  border: "1px solid #45b653",
  cursor: "pointer",
  textTransform: "capitalize",
};

export const buttoncontaindedDisabled = {
  color: "white",
  backgroundColor: "grey",
  padding: "10px 65px",
  fontSize: "1rem",
  fontWeight: "bold",
  letterSpacing: "1px",
  borderRadius: "25px",
  outline: "none",
  border: "1px solid grey",
  cursor: "pointer",
  textTransform: "capitalize",
};

export const disabledbuttoncontainded = {
  color: "white",
  backgroundColor: "grey",
  padding: "10px 65px",
  fontSize: "1rem",
  fontWeight: "bold",
  letterSpacing: "1px",
  borderRadius: "25px",
  outline: "none",
  border: "1px solid #45b653",
  cursor: "pointer",
};

export const btnMapDept = {
  color: "white",
  backgroundColor: "#ff9c00",
  padding: "10px 20px",
  fontSize: "1rem",
  fontWeight: "bold",
  letterSpacing: "1px",
  borderRadius: "20px",
  outline: "none",
  border: "1px solid #ff9c00",
  position: "absolute",
  right: "0px",
  bottom: "0px",
  cursor: "pointer",
};

export const downloadBtn = {
  color: "white",
  backgroundColor: "#45b653",
  padding: "5px 14px",
  fontSize: "1rem",
  fontWeight: "bold",
  borderRadius: "20px",
  outline: "none",
  border: "1px solid #45b653",
  textTransform: "capitalize",
};

export const previewBtn = {
  color: "white",
  backgroundColor: "#2f7abe",
  padding: "5px 14px",
  fontSize: "1rem",
  fontWeight: "bold",
  borderRadius: "20px",
  outline: "none",
  border: "1px solid #2f7abe",
  textTransform: "capitalize",
};

export const buttoncontaindedfixed = {
  color: "white",
  backgroundColor: "#45b653",
  padding: "10px 65px",
  fontSize: "1rem",
  fontWeight: "bold",
  letterSpacing: "1px",
  borderRadius: "20px",
  outline: "none",
  border: "1px solid #45b653",
  position: "fixed",
  right: "5px",
  bottom: "2%",
  zIndex: 10,
};

export const backBtnfixed = {
  color: "white",
  backgroundColor: "#ff9c00",
  padding: "10px 65px",
  fontSize: "1rem",
  fontWeight: "bold",
  letterSpacing: "1px",
  borderRadius: "20px",
  outline: "none",
  position: "fixed",
  left: "5px",
  bottom: "2%",
  zIndex: 10,
};
export const backBtn = {
  color: "white",
  backgroundColor: "#ff9c00",
  padding: "5px 35px",
  fontSize: "1rem",
  fontWeight: "bold",
  letterSpacing: "1px",
  borderRadius: "20px",
  outline: "none",

  zIndex: 10,
};

export const cancelBtn = {
  color: "#fff",
  backgroundColor: "#ff9c00",
  padding: "7px 20px",
  borderRadius: "20px",
  border: "2px solid #ff9c00",
  textTransform: "capitalize",
  outline: "none",
};

export const okBtn = {
  color: "#fff",
  backgroundColor: "#45b653",
  padding: "7px 30px",
  borderRadius: "20px",
  outline: "none",
  border: "2px solid #45b653",
  textTransform: "capitalize",
};

export const filterBtn = {
  color: "#fff",
  backgroundColor: "#45b653",
  padding: "7px 30px",
  borderRadius: "20px",
  outline: "none",
  border: "2px solid #45b653",
  textTransform: "capitalize",
};

export const buttoncontaindedfixeddisabled = {
  color: "white",
  backgroundColor: "grey",
  padding: "10px 65px",
  fontSize: "1rem",
  fontWeight: "bold",
  letterSpacing: "1px",
  borderRadius: "20px",
  outline: "none",
  border: "1px solid #45b653",
  position: "fixed",
  right: "5px",
  bottom: "2%",
  zIndex: 10,
};

export const buttoncontaindeddraft = {
  color: "white",
  backgroundColor: "#45b653",
  padding: "10px 25px",
  fontSize: "1rem",
  fontWeight: "bold",
  letterSpacing: "1px",
  borderRadius: "20px",
  outline: "none",
  border: "1px solid #45b653",
  position: "fixed",
  left: "5px",
  bottom: "2%",
  transition: "transform 0.3s ease-in-out",
};

export const modalSubmitBtn = {
  borderRadius: "20px",
  textTransform: "capitalize",
  backgroundColor: "#45b653",
  color: "#fff",
  letterSpacing: "1px",
  padding: "8px 20px",
  // fontWeight: "bold",
};

export const modalDraftBtn = {
  padding: "8px 20px",
  borderRadius: "20px",
  textTransform: "capitalize",
  backgroundColor: "#2f7abe",
  color: "#fff",
};

export const buttoncontaindeddraftSaving = {
  ...buttoncontaindeddraft,
  backgroundColor: "#777",
  transform: "translateY(-20px)",
};
export const buttonfixed = {
  color: "white",
  backgroundColor: "#45b653",
  padding: "10px 35px",
  fontSize: "1rem",
  fontWeight: "bold",
  letterSpacing: "1px",
  borderRadius: "25px",
  outline: "none",
  border: "1px solid #45b653",
  position: "fixed",
  bottom: "15px",
  right: "10px",
};

export const buttonoutlined = {
  color: "white",
  padding: "10px 65px",
  fontSize: "1rem",
  border: "1px solid white",
  fontWeight: "bold",
  letterSpacing: "1px",
  borderRadius: "25px",
};

export const naviconstyle = {
  fontSize: "1.7rem",
  margin: "10px 0",
  width: "100%",
  color: "#141414",
};

export const userseebutton = {
  width: "30px",
  height: "30px",
  backgroundColor: "#3079bd",
  color: "white",
};
export const usereditbutton = {
  // width: "92px",
  textTransform: "inherit",
  height: "30px",
  backgroundColor: "#45b653",
  color: "white",
};

export const usereditbuttonDisabled = {
  // width: "92px",
  textTransform: "capitalize",
  height: "30px",
  backgroundColor: "grey",
  color: "white",
};

export const userviewbutton = {
  // width: "92px",
  textTransform: "inherit",
  height: "30px",
  backgroundColor: "#ff9c00",
  color: "white",
};
export const userdeletebutton = {
  width: "30px",
  height: "30px",
  backgroundColor: "#ff9c00",
  color: "white",
};

export const userdeletebuttonDisabled = {
  width: "30px",
  height: "30px",
  backgroundColor: "grey",
  color: "white",
};

export const emailattribute = {
  textTransform: "lowercase",
};

export const tablehead = {
  borderRadius: "25px",
  // backgroundColor: "#007bff",
  color: "#3097bd",
};

export const hoverbackground = {
  backgroundColor: "#ff9c00",
  color: "white",
};
export const cardborder = {
  borderRadius: "20px",
};

export const frameworktable = {
  backgroundColor: "white",
  color: "#3079bd",
  fontSize: "1.1rem",
  fontWeight: "bold",
};

export const frameworksimgs = {
  width: "22px",
  height: "22px",
  margin: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const frameworksimgs2 = {
  width: "25px",
  height: "25px",
  margin: '0 2px'
};

export const frameworksimgs3 = {
  width: "22px",
  height: "22px",
  margin: "auto",
};
