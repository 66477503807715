import axios from "axios";
import { baseUrl, config } from "../baseurl";
import encrypt from "./crypto";
import { api } from "./HandleError";

export const fetchAllOrganizations = () => {
  return api.get(`/getallorganizations`);
};

export const addOrganization = (encryptedOrgData) => {
  return api.post(`/addorg`, { orgData: encryptedOrgData });
};

export const updateOrganization = (encryptedOrgEditedData) => {
  return axios.put(
    `${baseUrl}/editorg`,
    { orgEditedData: encryptedOrgEditedData },
    config
  );
};

export const getAllDepartments = () => {
  return axios.get(`${baseUrl}/getAllDepartments`, config);
};

export const deleteOrganization = (params) => {
  return axios.delete(`${baseUrl}/deleteorg`, { ...config, params });
};

export const getOrganizationUsers = (organizationEmail) => {
  return axios.get(`${baseUrl}/getOrganizationUsers`, {
    ...config,
    params: { organizationEmail: encrypt(organizationEmail.replace(/"/g, "")) },
  });
};

// export const getOrganizationUsers = (organizationName) => axios.post(
//   `${baseUrl}/getOrganizationUsers`,
//   {organizationName},
//   config
// );

export const getAllOrganizations = () =>
  axios.get(`${baseUrl}/getallorganizations`, config);
