import React, {useState} from "react";
import {Box,Button,useMediaQuery,useTheme} from "@mui/material";
import Hamburger from "../../components/Navbars/Hamburger.js";
import {Loader} from "../../components/Loader/Loader.jsx";
import {useLocation} from "react-router-dom";
import {backBtnfixed} from "../../styles";
import DetailsCard from "../../components/Cards/DetailsCard";
import OperationalUnitsTableCard from "../../components/Cards/OUDCard";
import NameCard from "../../components/Cards/NameCard";

const ViewUser = () => {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const {user: userDetails} = location.state;
  const containerUserStyle = {
    maxWidth: isSmallScreen ? "90vw" : "50vw",
    margin: isSmallScreen ? "5rem auto" : "7.5rem auto",
    padding: "2rem",
    backgroundColor: "#f9f8f9",
    borderRadius: "35px",
  };
  const userDetailsCleaned = {
    adminRights: userDetails?.adminRights,
    Country: userDetails?.country,
    deleteStatus: !userDetails?.deleteStatus ? "Active" : "Inactive",
    Email: userDetails?.email,
    lastLogin: userDetails?.lastLogin,
    LoginAttempts: userDetails?.loginAttempts,
    Mobile: `${userDetails?.countryCode} ${userDetails?.mobile}`,
    Organization: userDetails?.organizationName,
    ReportingTo: userDetails?.reportingTo,
    userStatus: userDetails?.userStatus ? "Active" : "Inactive",
  };

  const handleBack = () => {
    window.history.back();
  }

  let parsedOperationalUnits;
  try {
    parsedOperationalUnits = JSON.parse(userDetails.operationalUnits);
  } catch (error) {
    parsedOperationalUnits = null;
  }

  return (
    <>
      <Hamburger/>
      <Box sx={containerUserStyle}>
        {/* First Card: UserName and Role with Avatar */}
        <NameCard details={userDetails}/>

        {/* Second Card: Basic Details */}
        <DetailsCard
          details={{
              email: userDetailsCleaned?.Email,
              mobile: userDetailsCleaned?.Mobile,
              country: userDetailsCleaned?.Country,
              organization: userDetailsCleaned?.Organization,
              lastLogin: userDetailsCleaned?.lastLogin,
              loginAttempts: userDetailsCleaned?.LoginAttempts,
              userStatus: userDetailsCleaned?.userStatus,
              deleteStatus: userDetailsCleaned?.deleteStatus,
          }}
          isSmallScreen={isSmallScreen}
        />

        {/* Third Card: Operational Units and Departments */}
        {parsedOperationalUnits && (
          <OperationalUnitsTableCard
            operationalUnits={parsedOperationalUnits}
            isSmallScreen={isSmallScreen}
          />
        )}
      </Box>
      <Button onClick={handleBack} style={backBtnfixed}>Back</Button>
      <Loader loading={loading}/>
    </>
  );
};

export default ViewUser;