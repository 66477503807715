import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaUsers, FaWpforms } from "react-icons/fa6";
import { BsBuildings } from "react-icons/bs";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DashboardLayout from "../../components/DashboradLayout/DashboardLayout";
import { MdCloudDownload } from "react-icons/md";
import { Loader } from "../../../components/Loader/Loader";
import { STATUS } from "react-joyride";
import { getLoginAttempts, getUserRole } from "../../../services/loggedIn";
import { JoyRide } from "../../../Joyride";
import { useDispatch, useSelector } from "react-redux";
import { setRunTour, setShowTourModal, setTourCompleted } from "../../../redux/slice/TourGuideSlice";
import ConfirmModal from "../../../components/PopUpDialog/ConfirmModal";

const DashboardLandingPage = () => {
  const [loading, setLoading] = useState(true);
  const loginAttempts = 1
  parseInt(getLoginAttempts());

  const userRole = getUserRole();
  const dispatch = useDispatch();

  const { showTourModal, runTour, tourCompleted } = useSelector((state) => state.tourGuide);



  useEffect(() => {
    // Check if it's the user's first login
    if (loginAttempts <= 3) {
      // setRunTour(true);
      dispatch(setShowTourModal(true))
      dispatch(setTourCompleted(false))
    }
  }, []);



  const handleRunTour = () => {
    dispatch(setRunTour(true))
    dispatch(setShowTourModal(false))
  }

  const handleCancelTour = () => {
    dispatch(setShowTourModal(false))
  }

  const steps = [
    {
      target: "#navbar",
      content: "The menu items here correspond to the cards below.",
      disableBeacon: true,
    },
    {
      target: ".dash-landing-page-container",
      content:
        "These cards provide quick access to different sections of the application.",
    },
    {
      target: ".link-box.blue",
      content: "Click on the ESG & KPI Monitoring card to view monitoring dashboard.",
    },
    {
      target: ".link-box.review",
      content: "Click on Data review card to approve/reject KPI Responses filled by users.",
    },
    {
      target: ".link-box.green2",
      content: "Click on the card to access and download reports.",
    },


  ];


  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [])

  if (loading) {
    return <Loader loading={loading} />
  }

  return (
    <div>
      <DashboardLayout>
        <JoyRide steps={steps} />
        <div className="dash-landing-page-container">
          <div className="link-box-container">
            <Link
              to="/dashboard/tracker/kpi-monitoring"
              className="link-box blue"
            >
              <div className="circle">
                <DashboardIcon
                  sx={{ fontSize: "5rem", color: "white" }}
                  className="circle-icon"
                />

                {/* <h3>Organization Management</h3> */}
              </div>
              <h4 className="landing-head" style={{ textAlign: "center" }}>
                ESG & KPI <br /> Monitoring
              </h4>
            </Link>

            {userRole === "SPOC" &&
              <Link
                to="/dashboard/spoc-review"
                className="link-box review"
              >
                {/* <div className="link-box disable"> */}
                <div className="circle">
                  <FaUsers className="circle-icon" />
                  {/* <h3>Form Manager</h3> */}
                </div>
                <h4 className="landing-head" style={{ textAlign: "center" }}>
                  Data <br /> Review
                </h4>
              </Link>
            }

            <Link to="/download_report" className="link-box green2">
              <div className="circle">
                <MdCloudDownload
                  sx={{ fontSize: "5rem", color: "white" }}
                  className="circle-icon"
                />

                {/* <h3>Organization Management</h3> */}
              </div>
              <h4 className="landing-head" style={{ textAlign: "center" }}>
                Download & Preview
                <br /> Report
              </h4>
            </Link>
            <Link to="/dashboard/tracker/department" className="link-box green">
              <div className="circle">
                <FaWpforms className="circle-icon" />

                {/* <h3>User Management</h3> */}
              </div>
              <h4 className="landing-head" style={{ textAlign: "center" }}>
                Department <br /> Tracker
              </h4>
            </Link>

            <Link
              to="/dashboard/tracker/operation-unit"
              className="link-box orange"
            >
              <div className="circle">
                <BsBuildings className="circle-icon" />

                {/* <h3>Form Manager</h3> */}
              </div>
              <h4 className="landing-head" style={{ textAlign: "center" }}>
                Operation Unit <br /> Tracker
              </h4>
            </Link>

            <Link
              to="/dashboard/tracker/user-adoption"
              className="link-box purple"
            >
              <div className="circle">
                <FaUsers className="circle-icon" />
                {/* <h3>Form Manager</h3> */}
              </div>
              <h4 className="landing-head" style={{ textAlign: "center" }}>
                User <br /> Adoption
              </h4>
            </Link>

          </div>
        </div>
      </DashboardLayout>

      {showTourModal &&
        <ConfirmModal
          open={showTourModal}
          onClose={handleCancelTour}
          title={"Page Tour"}
          message={"Would you like a tour of the page?"}
          confirmText={"Yes, show me around"}
          cancelText={"No, thanks"}
          onConfirm={handleRunTour}
        />
      }
    </div>
  );
};

export default DashboardLandingPage;
