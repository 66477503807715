import React from "react";
import { useLocation } from "react-router-dom";
import CustomNavLink from "./CustomNavLink";
import { ListItemButton, ListItemIcon } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { ProfileIcon } from "./ProfileIcon";
import { makeStyles } from "@mui/styles";
import TourBtn from "../TourBtn/TourBtn";

const useStyles = makeStyles((theme) => ({
  navbar: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    textDecoration: "none",
    backgroundColor: "#f9f8f9",
    padding: "12px 16px",
    borderRadius: "20px",
    width: "70%",
  },
  navItem: {
    display: "flex",
    padding: "0px",
    textDecoration: "none",
    listStyle: "none",
    position: "relative",
  },
  dropdownMenu: {
    display: "none",
    gridTemplateColumns: "1fr 1fr",
    gap: "10px",
    margin: 0,
    padding: 0,
    backgroundColor: "#f9f9f9",
    borderRadius: "5px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    position: "absolute",
    top: "100%",
    left: "-190px",
    minWidth: "40vw",
    zIndex: 1,
  },
  dropdownMenuVisible: {
    display: "grid",
  },
  navLink: {
    transition: "background-color 0.3s ease",
  },
  trackerIcon: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    padding: "0.1rem",
    borderRadius: "1rem",
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
    position: "relative",
    "&:hover $dropdownMenu": {
      display: "grid",
    },
  },
}));

const Navbar = ({ navList, handleLogout, isSPOC }) => {
  const classes = useStyles();
  const location = useLocation();
  const activeTracker = location.pathname.startsWith("/dashboard/tracker");

  return (
    <>
      <ul className={classes.navbar}>
        {navList.map((item) => (
          <li
            key={item.id}
            className={classes.navItem}
            style={{
              color: !activeTracker ? "gray" : "#3079bd",
              fontWeight: !activeTracker ? "normal" : "bold",
            }}
          >
            {item.dropMenu ? (
              <>
                <div className={classes.trackerIcon}>
                  <h3 style={{ margin: 0 }}>{item.name}</h3>
                  <ExpandMore />
                  <ul className={classes.dropdownMenu}>
                    {item.dropMenu.map((subItem) => (
                      <li
                        key={subItem.id}
                        style={{ listStyle: "none", padding: "10px 0" }}
                      >
                        <CustomNavLink
                          to={subItem.navigateTo}
                          isSpocNavbar={isSPOC}
                          isTrackerMenu={true}
                        >
                          <ListItemButton className={classes.navLink}>
                            <ListItemIcon
                              sx={{
                                minWidth: "0px",
                                paddingRight: "8px",
                              }}
                            >
                              {subItem.icon}
                            </ListItemIcon>
                            <h3 style={{ width: "max-content" }}>
                              {" "}
                              {subItem.name}{" "}
                            </h3>
                            {/* <ListItemText primary={subItem.name} /> */}
                          </ListItemButton>
                        </CustomNavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            ) : (
              <CustomNavLink to={item.navigateTo} isSpocNavbar={isSPOC}>
                <h3>{item.name}</h3>
              </CustomNavLink>
            )}
          </li>
        ))}
      </ul>
      <li className="tourIcon-ProfileIcon">
        <div>
          <TourBtn />
        </div>
        <div className="profile-icon">

          <ProfileIcon handleLogout={handleLogout} />
        </div>
      </li>
    </>
  );
};

export default Navbar;
