import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Paper, Select, useMediaQuery, } from "@mui/material";
import "./demo.css";
import MenuIcon from "@mui/icons-material/Menu";
import DescribedTable from "../../components/Tables/DescribedTables/DescribedTable";
import Sunburst from "../../components/Sunburst/Sunburst";
import CircularProgress from "@mui/material/CircularProgress";
import ScoreCardTable from "../../components/Tables/ScoreCardTable/ScoreCardTable";
import DashboardLayout from "../../components/DashboradLayout/DashboardLayout";
import encrypt, { decryptData } from "../../../services/crypto";
import { Loader } from "../../../components/Loader/Loader";
import { useInView } from "framer-motion";
import { getLoginAttempts, getUserEmail } from "../../../services/loggedIn";
import { getComplianceData, getFilterData, getSunBurstFilter, } from "../../services/MonitoriDashboardApis";
import { STATUS } from "react-joyride";
import { JoyRide } from "../../../Joyride";
import { useDispatch, useSelector } from "react-redux";
import { setRunTour, setShowTourModal, setTourCompleted } from "../../../redux/slice/TourGuideSlice";
import ConfirmModal from "../../../components/PopUpDialog/ConfirmModal";

const DemoPage = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { amount: 0.1 });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [isdropdownmenuOpen, setIsDropdownMenuOpen] = useState(false);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  // const token = getToken();
  const spocEmail = getUserEmail();

  const [sunburstData, setSunburstData] = useState([]);
  const [pillarThemeComp, setPillarThemeComp] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sunDataLoading, setSunDataLoading] = useState(false);
  const [frequencyType, setFrequencyType] = useState("Monthly");
  const [filterData, setFilterData] = useState(null);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedQuarters, setSelectedQuarters] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState("All");
  const [selectedOperationalUnits, setSelectedOperationalUnits] =
    useState("All");
  const [financialYears, setFinancialYears] = useState([]);
  const loginAttempts = parseInt(getLoginAttempts());


  const dispatch = useDispatch();

  const { showTourModal, runTour, tourCompleted } = useSelector((state) => state.tourGuide);



  useEffect(() => {
    // Check if it's the user's first login
    if (loginAttempts <= 3) {
      // setRunTour(true);
      dispatch(setShowTourModal(false))
      dispatch(setTourCompleted(false))
    }
  }, []);



  const handleRunTour = () => {
    dispatch(setRunTour(true))
    dispatch(setShowTourModal(false))
  }

  const handleCancelTour = () => {
    dispatch(setShowTourModal(false))
  }

  const steps = [
    {
      target: ".filter-dropdown-box",
      content: "Use the dropdowns to filter and view data",
      disableBeacon: true,
    },
    {
      target: ".sunburst-img",
      content: "Click on each section to do into details of filled and unfilled KPIs",
      disableBeacon: true,
    },
    {
      target: ".dash-landing-page-container",
      content:
        "These cards provide quick access to different sections of the application.",
    },
    {
      target: ".pillar-stat-box",
      content: "The scorecard shows Pillar wise filled to total KPIs",
    },
    {
      target: ".describe-table",
      content: "The table gives a detailed view of filled KPIs",
    },
  ];


  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const now = new Date();
  const year = now.getFullYear();
  function formatMonths() {
    const monthIndex = now.getMonth();
    const shortYear = year.toString().slice(-2);
    const formattedMonth = `${months[monthIndex]}'${shortYear}`;
    return formattedMonth;
  }

  const formattedMonth = formatMonths();

  const handleFrequencyTypeChange = (type) => {
    setFrequencyType(type);
  };

  const month = formattedMonth;

  const currentDate = new Date();
  const getFinancialYear = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    // Financial year starts from April, so if the current month is April or later,
    // it belongs to the current financial year; otherwise, it belongs to the previous financial year
    const financialYearStartMonth = 3; // April (0-based index)
    if (month >= financialYearStartMonth) {
      return `${year}-${year + 1}`;
    } else {
      return `${year - 1}-${year}`;
    }
  };

  const currentFinancialYear = getFinancialYear(currentDate);

  const createFilterData = () => {
    const filterDataStucture = {
      [selectedYear]: {}
    };

    // Add selected months to their respective quarters
    selectedQuarters.forEach(quarter => {
      const quarterMonths = selectedMonths.filter(month =>
        filterData.filterDataStucture[selectedYear][quarter].includes(month)
      );

      if (quarterMonths.length > 0) {
        filterDataStucture[selectedYear][quarter] = quarterMonths;
      }
    });

    return filterDataStucture

  };

  const filterData2 = useMemo(() => {
    const filterDataStucture = createFilterData()
    //  {
    //   [selectedYear]: { [selectedQuarters]: selectedMonths },
    // };
    return {
      filterDataStucture,
      departments:
        selectedDepartments === "All"
          ? filterData?.departments
          : [selectedDepartments],
      operationalUnits:
        selectedOperationalUnits === "All"
          ? filterData?.operationalUnits
          : [selectedOperationalUnits],
    };
  }, [
    selectedYear,
    selectedQuarters,
    selectedMonths,
    selectedDepartments,
    selectedOperationalUnits,
    filterData,
  ]);

  // // console.log("filterData2", filterData2);


  const getFilteredSuburstData = useCallback(
    async (initialFilterData = null) => {
      try {
        setSunDataLoading(true);
        const filterDataToUse = initialFilterData || filterData2;
        const reqData = {
          spocEmail,
          Year: year,
          Month: month,
          filterData: filterDataToUse,
        };
        const encryptedData = encrypt({ reqData });

        const response = await getFilterData(encryptedData);
        const responseData = response.data;
        // // console.log("responseData", responseData);
        const decryptingData = decryptData(responseData.sunburstData);
        const parsedData = JSON.parse(decryptingData);
        // // console.log("parsedData sunburst", parsedData);
        setSunburstData(parsedData);
        setSunDataLoading(false);
      } catch (error) {
        console.error("error", error);
        setSunDataLoading(false);
      }
    },
    [filterData2, spocEmail, year, month]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await getFilteredSuburstData(sunburstData.data);

        const encryptedData0 = encrypt({ spocEmail });

        const [sunburstFilterData, complainceData] = await Promise.all([
          getSunBurstFilter(spocEmail),
          getComplianceData(encryptedData0),
        ]);

        setFilterData(sunburstFilterData.data);
        setSelectedYear(currentFinancialYear);
        if (
          sunburstFilterData.data.filterDataStucture &&
          sunburstFilterData.data.filterDataStucture[currentFinancialYear]
        ) {
          setSelectedQuarters(
            Object.keys(
              sunburstFilterData.data.filterDataStucture &&
              sunburstFilterData.data.filterDataStucture[currentFinancialYear]
            ).filter(
              (quarter) =>
                sunburstFilterData.data.filterDataStucture[
                  currentFinancialYear
                ][quarter].length > 0
            )
          );
          setSelectedMonths(
            Object.values(
              sunburstFilterData.data.filterDataStucture &&
              sunburstFilterData.data.filterDataStucture[currentFinancialYear]
            ).flatMap((months) => months)
          );
        }
        // setSelectedDepartments(res.data.departments);
        // setSelectedOperationalUnits(res.data.operationalUnits);

        const fYears = Object.keys(sunburstFilterData.data.filterDataStucture);
        setFinancialYears(fYears);
        const responseData0 = complainceData.data;
        const decryptingData0 = decryptData(responseData0);
        const parsedData0 = JSON.parse(decryptingData0);
        setPillarThemeComp(parsedData0);
        // console.log("pillarthemecomp", parsedData0)
        // loadSelections();
      } catch (error) {
        console.error("error", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const departments = [selectedDepartments];
  const operationalUnits = [selectedOperationalUnits];

  useEffect(() => {
    if (selectedDepartments && selectedOperationalUnits) {
      getFilteredSuburstData();
    }
  }, [selectedDepartments, selectedMonths, selectedQuarters]);


  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setSelectedQuarters([]);
    setSelectedMonths([]);
  };

  const handleQuarterChange = (event) => {
    const selectedQuarters = event.target.value;
    setSelectedQuarters(selectedQuarters);
    const selectedMonths = selectedQuarters.flatMap(
      (quarter) => filterData.filterDataStucture[selectedYear][quarter] || []
    );
    setSelectedMonths(selectedMonths);
  };

  const handleMonthChange = (event) => {
    const selectedMonths = event.target.value;
    setSelectedMonths(selectedMonths);
    const selectedQuarters = new Set(
      selectedMonths.flatMap((month) =>
        Object.entries(filterData.filterDataStucture[selectedYear])
          .filter(([_, months]) => months.includes(month))
          .map(([quarter]) => quarter)
      )
    );
    setSelectedQuarters(Array.from(selectedQuarters));
  };

  const handleDepartmentChange = (event) => {
    const selectedDepartments = event.target.value;
    setSelectedDepartments(selectedDepartments);
    // saveSelections();
  };

  const handleOperationalUnitChange = (event) => {
    const selectedOperationalUnits = event.target.value;
    setSelectedOperationalUnits(selectedOperationalUnits);
    setSelectedDepartments("");
    // saveSelections();
  };
  const handleDropdownToggle = () => {
    setIsDropdownMenuOpen(!isdropdownmenuOpen);
    // // console.log(isdropdownmenuOpen)
  };
  if (loading) {
    return <Loader loading={loading} />
  }
  return (
    <>
      <div className="monitoring-outside-container">
        <DashboardLayout>
          <JoyRide steps={steps} />
          <div className="monitor-inner-container">
            <Box className="monitor-heading-box">
              <h1 className="frame_heading">ESG/KPI Monitoring</h1>
            </Box>

            <div style={{}}>
              <div className="sunburst-progress-box">
                <div className="sunburst-filter-outside-box">
                  <Box sx={{ height: "fit-content" }}>
                    {isMobile ? (
                      <>
                        <MenuIcon
                          style={{ zIndex: "100", color: "#45b653" }}
                          onClick={handleDropdownToggle}
                        />
                        {isdropdownmenuOpen ? (
                          <Box
                            style={{
                              margin: "10px 0",
                              width: "fit-content",
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "flex-start",
                              alignItems: "flex-start",
                            }}
                          >
                            {/* Year/month select based on frequency */}

                            <FormControl
                              sx={{
                                height: "fit-content",
                                minWidth: 100,
                                margin: "10px",
                              }}
                            >
                              <InputLabel id="year-label">
                                Select Year
                              </InputLabel>
                              <Select
                                labelId="year-label"
                                id="year"
                                label="Select Year"
                                value={selectedYear}
                                onChange={handleYearChange}
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      textAlign: "left",
                                    },
                                  },
                                }}
                              >
                                <MenuItem value="">
                                  <em>Select Year</em>
                                </MenuItem>
                                {financialYears.map((year) => (
                                  <MenuItem key={year} value={year}>
                                    {year}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormControl
                              sx={{
                                height: "fit-content",
                                minWidth: 100,
                                margin: "10px",
                              }}
                            >
                              <InputLabel id="year-label">
                                Select Quarter(s)
                              </InputLabel>
                              <Select
                                labelId="quarter-label"
                                id="quarter"
                                label="Select Quarter(s)"
                                multiple
                                value={selectedQuarters}
                                onChange={handleQuarterChange}
                                renderValue={(selected) =>
                                  selected.join(", ")
                                }
                              >
                                <MenuItem value="">
                                  <em>Select Quarter</em>
                                </MenuItem>
                                {filterData &&
                                  filterData.filterDataStucture &&
                                  Object.entries(
                                    filterData.filterDataStucture[
                                    selectedYear
                                    ] || {}
                                  ).map(
                                    ([quarter, months]) =>
                                      months.length > 0 && (
                                        <MenuItem
                                          key={quarter}
                                          value={quarter}
                                        >
                                          <Checkbox
                                            checked={selectedQuarters.includes(
                                              quarter
                                            )}
                                          />
                                          <ListItemText primary={quarter} />
                                        </MenuItem>
                                      )
                                  )}
                              </Select>
                            </FormControl>
                            <FormControl
                              sx={{
                                height: "fit-content",
                                minWidth: 100,
                                margin: "10px",
                              }}
                            >
                              <InputLabel id="month-label">
                                Select Month(s)
                              </InputLabel>
                              <Select
                                labelId="month-label"
                                id="month"
                                label="Select Month(s)"
                                multiple
                                value={selectedMonths}
                                onChange={handleMonthChange}
                                renderValue={(selected) =>
                                  selected.join(", ")
                                }
                              >
                                <MenuItem value="">
                                  <em>Select Month</em>
                                </MenuItem>
                                {filterData &&
                                  filterData.filterDataStucture &&
                                  Object.entries(
                                    filterData.filterDataStucture[
                                    selectedYear
                                    ] || {}
                                  )
                                    .flatMap(([_, months]) => months)
                                    .map((month) => (
                                      <MenuItem key={month} value={month}>
                                        <Checkbox
                                          checked={selectedMonths.includes(
                                            month
                                          )}
                                        />
                                        <ListItemText primary={month} />
                                      </MenuItem>
                                    ))}
                              </Select>
                            </FormControl>
                            {/* Operational Unit dropdown */}
                            <FormControl
                              sx={{
                                height: "fit-content",
                                minWidth: 100,
                                margin: "10px",
                              }}
                            >
                              <InputLabel id="operational-unit-label">
                                Select Operational Unit(s)
                              </InputLabel>
                              <Select
                                labelId="operational-unit-label"
                                id="operational-unit"
                                label="Select Operational Unit(s)"
                                // multiple
                                value={selectedOperationalUnits}
                                onChange={handleOperationalUnitChange}
                                // renderValue={(selected) => selected.join(", ")}
                                MenuProps={{
                                  PaperProps: {
                                    style: {},
                                  },
                                }}
                              >
                                <MenuItem value="">
                                  <em>Select Operational Unit</em>
                                </MenuItem>
                                <MenuItem value="All">All</MenuItem>
                                {filterData &&
                                  filterData?.operationalUnits &&
                                  filterData?.operationalUnits.map(
                                    (operationalUnit) => (
                                      <MenuItem
                                        key={operationalUnit}
                                        value={operationalUnit}
                                      >
                                        {operationalUnit}
                                      </MenuItem>
                                    )
                                  )}
                              </Select>
                            </FormControl>

                            {/* Select Departments */}
                            <FormControl
                              sx={{
                                height: "fit-content",
                                minWidth: 100,
                                margin: "10px",
                              }}
                            >
                              <InputLabel id="department-label">
                                Select Department(s)
                              </InputLabel>
                              <Select
                                labelId="department-label"
                                id="department"
                                // multiple
                                label="Select Department(s)"
                                value={selectedDepartments}
                                onChange={handleDepartmentChange}
                                MenuProps={{
                                  PaperProps: {
                                    style: {},
                                  },
                                }}
                              >
                                <MenuItem value="">
                                  <em>Select Department</em>
                                </MenuItem>
                                <MenuItem value="All">All</MenuItem>
                                {filterData &&
                                  filterData?.departments &&
                                  filterData?.departments.map(
                                    (department) => (
                                      <MenuItem
                                        key={department}
                                        value={department}
                                      >
                                        {department}
                                      </MenuItem>
                                    )
                                  )}
                              </Select>
                            </FormControl>
                          </Box>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <Box className="filter-dropdown-box">
                        <div className="sunburst-instruction-box">
                          Select filters to render respective data
                        </div>
                        {/* Year/month select based on frequency */}
                        <FormControl
                          sx={{ minWidth: 180, height: "fit-content" }}
                        >
                          <InputLabel id="year-label">Select Year</InputLabel>
                          <Select
                            labelId="year-label"
                            id="year"
                            label="Select Year"
                            value={selectedYear}
                            onChange={handleYearChange}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 250,
                                  textAlign: "left",
                                },
                              },
                            }}
                          >
                            <MenuItem value="">
                              <em>Select Year</em>
                            </MenuItem>
                            {financialYears.map((year) => (
                              <MenuItem key={year} value={year}>
                                {year}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl sx={{ minWidth: 180 }}>
                          <InputLabel id="year-label">
                            Select Quarter(s)
                          </InputLabel>
                          <Select
                            labelId="quarter-label"
                            id="quarter"
                            label="Select Quarter(s)"
                            multiple
                            value={selectedQuarters}
                            onChange={handleQuarterChange}
                            renderValue={(selected) => selected.join(", ")}
                            sx={{ maxWidth: 180 }}
                          >
                            <MenuItem value="">
                              <em>Select Quarter</em>
                            </MenuItem>
                            {filterData &&
                              filterData.filterDataStucture &&
                              Object.entries(
                                filterData.filterDataStucture[selectedYear] ||
                                {}
                              ).map(
                                ([quarter, months]) =>
                                  months.length > 0 && (
                                    <MenuItem key={quarter} value={quarter}>
                                      <Checkbox
                                        checked={selectedQuarters.includes(
                                          quarter
                                        )}
                                      />
                                      <ListItemText primary={quarter} />
                                    </MenuItem>
                                  )
                              )}
                          </Select>
                        </FormControl>
                        <FormControl sx={{ minWidth: 180 }}>
                          <InputLabel id="month-label">
                            Select Month(s)
                          </InputLabel>
                          <Select
                            labelId="month-label"
                            id="month"
                            label="Select Month(s)"
                            multiple
                            value={selectedMonths}
                            onChange={handleMonthChange}
                            renderValue={(selected) => selected.join(", ")}
                            sx={{ maxWidth: 180 }}
                          >
                            <MenuItem value="">
                              <em>Select Month</em>
                            </MenuItem>
                            {filterData &&
                              filterData.filterDataStucture &&
                              Object.entries(
                                filterData.filterDataStucture[selectedYear] ||
                                {}
                              )
                                .flatMap(([_, months]) => months)
                                .map((month) => (
                                  <MenuItem key={month} value={month}>
                                    <Checkbox
                                      checked={selectedMonths.includes(month)}
                                    />
                                    <ListItemText primary={month} />
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                        {/* Operational Unit dropdown */}
                        <FormControl sx={{ minWidth: 180 }}>
                          <InputLabel id="operational-unit-label">
                            Select Operational Unit(s)
                          </InputLabel>
                          <Select
                            labelId="operational-unit-label"
                            id="operational-unit"
                            label="Select Operational Unit(s)"
                            // multiple
                            value={selectedOperationalUnits}
                            onChange={handleOperationalUnitChange}
                            // renderValue={(selected) => selected.join(", ")}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 250,
                                },
                              },
                            }}
                          >
                            <MenuItem value="">
                              <em>Select Operational Unit</em>
                            </MenuItem>
                            <MenuItem value="All">All</MenuItem>
                            {filterData &&
                              filterData?.operationalUnits &&
                              filterData?.operationalUnits.map(
                                (operationalUnit) => (
                                  <MenuItem
                                    key={operationalUnit}
                                    value={operationalUnit}
                                  >
                                    {operationalUnit}
                                  </MenuItem>
                                )
                              )}
                          </Select>
                        </FormControl>

                        {/* Select Departments */}
                        <FormControl sx={{ minWidth: 180 }}>
                          <InputLabel id="department-label">
                            Select Department(s)
                          </InputLabel>
                          <Select
                            labelId="department-label"
                            id="department"
                            // multiple
                            label="Select Department(s)"
                            value={selectedDepartments}
                            onChange={handleDepartmentChange}
                            // renderValue={(selected) => selected.join(", ")}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 250,
                                },
                              },
                            }}
                          >
                            <MenuItem value="">
                              <em>Select Department</em>
                            </MenuItem>
                            <MenuItem value="All">All</MenuItem>
                            {filterData &&
                              filterData?.departments &&
                              filterData?.departments.map((department) => (
                                <MenuItem key={department} value={department}>
                                  {department}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        {/* </Box> */}
                      </Box>
                    )}
                    <>
                      {sunDataLoading ? (
                        <Box className="sunburst-only">
                          <CircularProgress />
                        </Box>
                      ) : (
                        <div className="sunburst-img">
                          <Sunburst
                            data={sunburstData}
                            keyId="Sunburst"
                            width={430}
                            height={260}
                            offsetX={50}
                            offsetY={0}
                            isMobile={isMobile}
                          />
                        </div>
                      )}
                    </>
                  </Box>
                </div>
                <ScoreCardTable
                  pillarDataFormat={
                    pillarThemeComp && pillarThemeComp.pillarDataFormat
                  }
                />
              </div>

              <div style={{ width: "100%", top: "20%" }}>
                <div style={{ borderRadius: "20px" }} className="describe-table">
                  <DescribedTable
                    themeTopicDataFormat={
                      pillarThemeComp && pillarThemeComp.themeTopicDataFormat
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </DashboardLayout>

        {showTourModal &&
          <ConfirmModal
            open={showTourModal}
            onClose={handleCancelTour}
            title={"Page Tour"}
            message={"Would you like a tour of the page?"}
            confirmText={"Yes, show me around"}
            cancelText={"No, thanks"}
            onConfirm={handleRunTour}
          />
        }
      </div>
    </>
  );
};

export default DemoPage;
