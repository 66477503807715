import React, {useEffect, useState} from "react";
import axios from "axios";
import {baseUrl} from "../../../baseurl";
import {Backdrop,Button,CircularProgress,FormControl,InputLabel,MenuItem,Select,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {tablehead} from "../../../styles";
import {useSortBy, useTable} from "react-table";
import {MdArrowDownward, MdArrowUpward} from "react-icons/md";
import DashHamburger from "../../components/Navbars/DashHamburger";
import {FaFileArrowDown} from "react-icons/fa6";
import {getUserEmail} from "../../../services/loggedIn";

const DeptCompTable = () => {
  // const token = getToken();
  const spocEmail = getUserEmail();
  const [compData, setCompData] = useState([]);
  const [currentYear, setCurrentYear] = useState(""); // Default current financial year
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [frequency, setFrequency] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);
  const [showNoMonthsMessage, setShowNoMonthsMessage] = useState(false);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const currentDate = new Date();
  const currentYear1 = `${currentDate.getFullYear()}-${
    currentDate.getFullYear() + 1
  }`;
  const currentMonth = currentDate.toLocaleString("en-us", {
    month: "short",
  });
  const slicedYear = "2023-2024".split("-")[1].slice(2);
  const concatedmonth = `${currentMonth}'${slicedYear}`;

  useEffect(() => {
    const fetchDeptCompData = async () => {
      try {
        setLoading(true);
        // const headers = {
        //   Authorization: `${token}`,
        //   "Content-Type": "application/json",
        // };
        const res = await axios.post(
          `${baseUrl}/getDepartmentCompliance`,
          { spocEmail: spocEmail },
          { withCredentials: true }
        );
        setCompData(res.data); // Assuming response contains data property

        // Extract available years
        const yearsSet = new Set();
        Object.keys(res.data).forEach((department) => {
          Object.keys(res.data[department]).forEach((year) => {
            yearsSet.add(year);
          });
        });
        const sortedYears = Array.from(yearsSet).sort();
        setYears(sortedYears);

        setCurrentYear(currentYear1);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    fetchDeptCompData();
  }, [spocEmail]);

  useEffect(() => {
    const monthsSet = new Set();
    let hasMonths = false;
    Object.keys(compData).forEach((department) => {
      if (selectedYear && compData[department][selectedYear]) {
        compData[department][selectedYear].months.forEach((monthObj) => {
          const monthKey = Object.keys(monthObj)[0];
          monthsSet.add(monthKey);
          hasMonths = true;
        });
      }
    });

    if (hasMonths) {
      setMonths(Array.from(monthsSet));
      setShowNoMonthsMessage(false);
    } else {
      // setMonths([`No Data stored at a monthly frequency in the year ${selectedYear}`]);
      setMonths([]);
      setShowNoMonthsMessage(true);
    }
  }, [compData, selectedYear, frequency, selectedMonth]);

  const handleChangeFrequency = (event) => {
    setFrequency(event.target.value);
    // setSelectedYear("");
    // setSelectedMonth("");
  };

  const handleChangeSelectedYear = (event) => {
    setSelectedYear(event.target.value);
    // setSelectedMonth("");
  };

  const handleChangeSelectedMonth = (event) => {
    setSelectedMonth(event.target.value);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Department",
        accessor: "department",
        canSort: true,
      },
      {
        Header: "Total",
        accessor: "total",
        canSort: true,
      },
      {
        Header: "Filled",
        accessor: "filled",
        canSort: true,
      },
      {
        Header: "Progress",
        id: "progress",
        Cell: ({ row }) => {
          const filledPercentage =
            row.original.total > 0
              ? (row.original.filled / row.original.total) * 100
              : 0;

          return (
            <div
              style={{
                width: "100%",
                height: "10px",
                backgroundColor: "#dadada",
                borderRadius: "6px",
                position: "relative",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  width: `${filledPercentage}%`,
                  height: "100%",
                  backgroundColor:
                    filledPercentage > 50 ? "#85cc00" : "#ffbf00",
                  transition: "width .2s ease-out",
                  position: "relative",
                  borderRadius: "6px",
                  zIndex: 50,
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: "50%",
                    transform: "translateX(-50%)",
                    color: "#fff",
                    pointerEvents: "none",
                    opacity: 0,
                    transition: "opacity .2s ease-out",
                    whiteSpace: "nowrap",
                    padding: "2px",
                    borderRadius: "2px",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                  }}
                >
                  {`${filledPercentage.toFixed(2)}%`}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        Header: "Score",
        id: "score",
        Cell: ({ row }) => {
          const filledPercentage =
            row.original.total > 0
              ? (row.original.filled / row.original.total) * 100
              : 0;
          const score =
            filledPercentage > 50 ? 100 : Math.round(filledPercentage * 2);
          return <div>{score}</div>;
        },
      },
    ],
    []
  );

  const tableData = React.useMemo(() => {
    let flatData = [];
    let total = 0;
    let filled = 0;
    Object.keys(compData).forEach((department) => {
      Object.keys(compData[department]).forEach((year) => {
        if (frequency === "annual") {
          if (selectedYear) {
            total =
              compData[department][selectedYear]?.annualFrequency?.total || 0;
            filled =
              compData[department][selectedYear]?.annualFrequency?.filled || 0;
          }
        } else if (frequency === "monthly") {
          if (
            selectedYear &&
            selectedMonth &&
            compData[department][selectedYear]
          ) {
            const monthData = compData[department][selectedYear].months.find(
              (month) => {
                return Object.keys(month)[0] === selectedMonth;
              }
            );
            if (monthData) {
              total = monthData[selectedMonth].total;
              filled = monthData[selectedMonth].filled;
            } else {
              total = 0;
              filled = 0;
            }
          }
        } else {
          // If no specific year or month is selected, display the data for the current fiscal year
          if (currentYear1) {
            const annualFrequency =
              compData[department][currentYear1]?.annualFrequency || {};
            if (currentYear1) {
              total = annualFrequency?.total || 0;
              filled = annualFrequency?.filled || 0;
            }

            if (compData[department][currentYear1]?.months) {
              const monthData = compData[department][currentYear1].months.find(
                (month) => Object.keys(month)[0] === concatedmonth
              );
              if (monthData) {
                total += monthData[concatedmonth].total;
                filled += monthData[concatedmonth].filled;
              }
            }
          }
        }
      });
      flatData.push({
        department,
        total,
        filled,
      });
    });
    return flatData;
  }, [compData, frequency, selectedYear, selectedMonth]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: tableData,
        initialState: {
          sortBy: [{ id: "filled", desc: true }],
        },
      },
      useSortBy
    );

  return (
    <>
      {loading ? (
        <Backdrop open={loading}>
          {" "}
          <CircularProgress />{" "}
        </Backdrop>
      ) : (
        <div className="container-user">
          <DashHamburger />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "16px",
                marginBottom: "16px",
                marginLeft: "18px",
              }}
            >
              {/* Frequency select */}
              <FormControl variant="filled" sx={{ minWidth: 200 }}>
                <InputLabel id="frequency-label">Select Frequency</InputLabel>
                <Select
                  labelId="frequency-label-standard"
                  id="frequency"
                  value={frequency}
                  onChange={handleChangeFrequency}
                >
                  <MenuItem value="">
                    <em>Select Frequency</em>
                  </MenuItem>
                  <MenuItem value="annual">Annual</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                </Select>
              </FormControl>

              {/* Year/month select based on frequency */}
              {frequency === "annual" && (
                <FormControl variant="filled" sx={{ minWidth: 200 }}>
                  <InputLabel id="year-label">Select Year</InputLabel>
                  <Select
                    labelId="year-label-standard"
                    id="year"
                    value={selectedYear}
                    onChange={handleChangeSelectedYear}
                  >
                    <MenuItem value="">
                      <em>Select Year</em>
                    </MenuItem>
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {frequency === "monthly" && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "16px",
                      flexDirection: "row-reverse",
                    }}
                  >
                    <FormControl variant="filled" sx={{ minWidth: 200 }}>
                      <InputLabel id="month-label">Select Month</InputLabel>
                      <Select
                        labelId="month-label-standard"
                        id="month"
                        value={selectedMonth}
                        onChange={handleChangeSelectedMonth}
                      >
                        <MenuItem value="">
                          <em>Select Month</em>
                        </MenuItem>
                        {months.map((month) => (
                          <MenuItem key={month} value={month}>
                            {month}
                          </MenuItem>
                        ))}
                        {showNoMonthsMessage && (
                          <MenuItem value="" disabled>
                            {`No Data stored at a monthly frequency in the year ${selectedYear}`}
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <FormControl variant="filled" sx={{ minWidth: 200 }}>
                      <InputLabel id="year-label-standard">Select Year</InputLabel>
                      <Select
                        labelId="year-label"
                        id="year"
                        value={selectedYear}
                        onChange={handleChangeSelectedYear}
                      >
                        <MenuItem value="">
                          <em>Select Year</em>
                        </MenuItem>
                        {years.map((year) => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </>
              )}
            </Box>

            <Button
              variant="outlined"
              sx={{ marginRight: "30px" }}
              startIcon={<FaFileArrowDown />}
            >
              Download
            </Button>
          </Box>
          {frequency === "monthly" && showNoMonthsMessage && (
            <Typography
              variant="h6"
              sx={{ marginLeft: "18px", fontSize: "14px", color: "red" }}
            >
              No Data stored at a monthly frequency in the year {selectedYear}
            </Typography>
          )}
          <TableContainer style={{ overflow: "scroll" }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              {...getTableProps()}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "25px",
                padding: "10px",
              }}
            >
              <TableHead>
                {headerGroups.map((headerGroup) => (
                  <TableRow
                    sx={tablehead}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => (
                      <TableCell
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        sx={{
                          fontSize: "1.1rem",
                          color: "#3079bd",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <MdArrowDownward />
                            ) : (
                              <MdArrowUpward />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <TableRow {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <TableCell {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </>
  );
};

export default DeptCompTable;
