import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const preservedInputsSlice = createSlice({
  name: 'preservedInputs',
  initialState,
  reducers: {
    updatePreservedInput: (state, action) => {
      const { kpiCode, period, value, department, operationalUnit } = action.payload;
      
      // Only update if the kpiCode matches the department and OU in its suffix
      const expectedSuffix = `-${department}-${operationalUnit}`;
      if (kpiCode.endsWith(expectedSuffix)) {
        // Initialize the object structure if it doesn't exist
        if (!state[kpiCode]) {
          state[kpiCode] = {};
        }
        state[kpiCode][period] = value;
      }
    },
    clearPreservedInputs: () => initialState,
    clearPreservedInputsByDeptAndOU: (state, action) => {
      const { department, operationalUnit } = action.payload;
      const targetSuffix = `-${department}-${operationalUnit}`;
      
      // Create a new object excluding the matched keys
      const updatedState = Object.keys(state).reduce((acc, key) => {
        if (!key.endsWith(targetSuffix)) {
          acc[key] = state[key];
        }
        return acc;
      }, {});
      
      return updatedState;
    },
  },
});

export const { 
  updatePreservedInput, 
  clearPreservedInputs,
  clearPreservedInputsByDeptAndOU 
} = preservedInputsSlice.actions;

export const preservedInputsReducer = preservedInputsSlice.reducer;

export default preservedInputsSlice.reducer;