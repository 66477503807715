import React from "react";
import Hamburger from "../../../components/Navbars/Hamburger";

const DashboardLayout = ({ children }) => {
  return (
    <>
      <Hamburger />
      {children}
    </>
  );
};

export default DashboardLayout;
