import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, } from "@mui/material";
import "./framework.css";
import "./Home.css";
import "./Standards.css";
import { useLocation, useNavigate } from "react-router-dom";
import Hamburger from "../Navbars/Hamburger.js";
import { baseUrl } from "../../baseurl.js";
import { backBtnfixed, buttoncontaindedfixed } from "../../styles.js";
import FrameWorkCard from "../AssignmentManager/FrameWorkCard.jsx";
import PillarScoreCard from "../AssignmentManager/PillarScoreCard.jsx";
import encrypt, { decryptData } from "../../services/crypto.js";
import { getDynamicSelection } from "../../services/FormManagerApi.js";
import MultiStepAssignManager from "../../pages/MultiStepAssignManager/MultiStepAssignManager.jsx";
import { Loader } from "../Loader/Loader.jsx";
import { STATUS } from "react-joyride";
import { getLoginAttempts } from "../../services/loggedIn.js";
import { JoyRide } from "../../Joyride.jsx";
import { useDispatch, useSelector } from "react-redux";
import { setRunTour, setShowTourModal, setTourCompleted } from "../../redux/slice/TourGuideSlice.js";
import ConfirmModal from "../PopUpDialog/ConfirmModal.jsx";

const FrameWorks = () => {
  const location = useLocation();
  const requestData = location.state?.prioritiesJson?.priority;
  const selectedOption = location.state?.prioritiesJson?.priority;
  const org = location.state.org;
  const data = location.state?.org?.name;
  const [loading, setLoading] = useState(false);
  const [frameworkData, setFrameworkData] = useState({});
  const [scorecard, setScorecard] = useState({});
  const [selectedCategories, setSelectedCategories] = useState(
    location.state?.selectedCategories || []
  );
  const [selectedData, setSelectedData] = useState(
    location.state?.selectedData || {}
  );
  const [selectedSubcategories, setSelectedSubcategories] = useState(
    location.state?.selectedSubcategories || {}
  );
  const [submittedData, setSubmittedData] = useState(null);
  const loginAttempts = getLoginAttempts();

  const dispatch = useDispatch();

  const { showTourModal, tourCompleted } = useSelector((state) => state.tourGuide);

  useEffect(() => {
    // Check if it's the user's first login
    if (loginAttempts <= 3) {
      // setRunTour(true);
      dispatch(setShowTourModal(false))
      dispatch(setTourCompleted(false))
    }
  }, []);

  const handleRunTour = () => {
    dispatch(setRunTour(true))
    dispatch(setShowTourModal(false))
  }

  const handleCancelTour = () => {
    dispatch(setShowTourModal(false))
  }
  const steps = [
    {
      target: ".pillar-container",
      content: "Select pillars,themes and topics for assignment",
      disableBeacon: true,
    },
    {
      target: ".pillar-cards",
      content: "Each card has topics nested under themes which are further nested inside pillars.",
      disableBeacon: true,
    },
    {
      target: ".pillar-check-box",
      content: "All the pillars,themes and topics are selected by default,if the user doesn't make any modifications,these selections are processed.",
      disableBeacon: true,
    },
    {
      target: ".theme-check",
      content: "On selecting the themes, topics are to be selected",
      disableBeacon: true,
    },
    {
      target: ".topic-check",
      content: "The last step is selection under themes",
      disableBeacon: true,
    },
    {
      target: ".score-card",
      content: "You can view the number of selected themes and topics for a particular pillar",
      disableBeacon: true,
    },
    {
      target: ".org-continue-btn",
      content:
        "Continue to go to next page",
    }
  ];


  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const json = {
        priorities: requestData,
      };

      const encryptData = encrypt(json);

      try {
        const response = await getDynamicSelection(encryptData);

        const decryptedData = decryptData(response.data.encryptedData);

        const parsedData = JSON.parse(decryptedData);

        setFrameworkData(parsedData.frameworks);
        const categories = Object.keys(parsedData.frameworks);
        const selectedData = {};
        const selectedSubcategories = {};

        categories.forEach((category) => {
          selectedData[category] = {};
          Object.keys(parsedData.frameworks[category]).forEach((subcategory) => {
            selectedData[category][subcategory] = {};

            parsedData.frameworks[category][subcategory].forEach((element) => {
              selectedData[category][subcategory][element] = true;
            });
            if (Object.keys(selectedData[category][subcategory]).length > 0) {
              selectedSubcategories[category] = [
                ...(selectedSubcategories[category] || []),
                subcategory,
              ];
            }
          });
        });

        setSelectedCategories((prev) => (prev.length > 0 ? prev : categories));
        setSelectedData((prev) => (Object.keys(prev).length > 0 ? prev : selectedData));
        setSelectedSubcategories((prev) => (Object.keys(prev).length > 0 ? prev : selectedSubcategories));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  const handleCategoryCheckboxChange = (category) => {
    setSelectedCategories((prevSelected) =>
      prevSelected.includes(category)
        ? prevSelected.filter((selected) => selected !== category)
        : [...prevSelected, category]
    );

    setSelectedData((prevSelectedData) => {
      const updatedData = { ...prevSelectedData };

      if (!prevSelectedData[category]) {
        return updatedData;
      }
      delete updatedData[category];

      return updatedData;
    });

    setSelectedSubcategories((prevSelectedSubcategories) => {
      const updatedSubcategories = { ...prevSelectedSubcategories };
      if (!prevSelectedSubcategories[category]) {
        return updatedSubcategories;
      }
      delete updatedSubcategories[category];

      return updatedSubcategories;
    });
  };

  const handleSubcategoryCheckboxChange = (category, subcategory) => {
    setSelectedSubcategories((prevSelected) => ({
      ...prevSelected,
      [category]: prevSelected[category]
        ? prevSelected[category].includes(subcategory)
          ? prevSelected[category].filter(
            (selected) => selected !== subcategory
          )
          : [...prevSelected[category], subcategory]
        : [subcategory],
    }));

    setSelectedData((prevSelectedData) => {
      const updatedData = { ...prevSelectedData };
      if (!updatedData[category]) {
        updatedData[category] = {};
      }
      if (!prevSelectedData[category]?.[subcategory]) {
        updatedData[category][subcategory] = {};
        frameworkData[category][subcategory].forEach((element) => {
          updatedData[category][subcategory][element] = true;
        });
      } else {
        delete updatedData[category][subcategory];
      }
      return updatedData;
    });
  };
  const handleCheckboxChange = (category, subcategory, element) => {
    setSelectedData((prevSelectedData) => {
      const updatedData = { ...prevSelectedData };
      updatedData[category][subcategory][element] =
        !prevSelectedData[category][subcategory][element];
      const allElementsChecked = Object.values(
        updatedData[category][subcategory]
      ).every((val) => val);
      if (allElementsChecked) {
        setSelectedSubcategories((prevSelected) => ({
          ...prevSelected,
          [category]: prevSelected[category]
            ? [...prevSelected[category], subcategory]
            : [subcategory],
        }));
      } else if (
        !allElementsChecked &&
        !prevSelectedData[category][subcategory][element]
      ) {
        setSelectedSubcategories((prevSelected) => ({
          ...prevSelected,
          [category]: prevSelected[category],
        }));
      }
      updateScorecard();
      return updatedData;
    });
    updateScorecard();
  };

  const handleClearSelection = () => {
    setSelectedCategories([]);
    setSelectedSubcategories({});
    setSelectedData({});
  };

  useEffect(() => {
    updateScorecard();
  }, [selectedCategories, selectedSubcategories, selectedData, submittedData]);

  const updateScorecard = () => {
    const newScorecard = {};

    Object.keys(frameworkData).forEach((category) => {
      if (selectedCategories.includes(category)) {
        let themesCount = 0;
        let topicsCount = 0;

        Object.entries(selectedData[category] || {}).forEach(
          ([subcategory, elements]) => {
            if (selectedSubcategories[category]?.includes(subcategory)) {
              themesCount++;
              topicsCount += Object.values(elements).filter(Boolean).length;
            }
          }
        );

        newScorecard[category] = { themes: themesCount, topics: topicsCount };
      }
    });

    setScorecard(newScorecard);
  };
  const handleSubmit = async () => {
    // Define formattedSelectedData before using it
    const formattedSelectedData = {
      organizationEmail: location.state.org?.organizationEmail,
      pillar: selectedCategories.join(","),
      theme: Object.values(selectedSubcategories).flat().join(","),
      topic: getCheckedTopics(selectedData).join("~"),
      priorities: requestData,
    };
    try {
      setLoading(true);

      const encryptData = encrypt({ formattedSelectedData });

      const response = await axios.post(
        `${baseUrl}/getKPIList`,
        { encryptedData: encryptData },
        { withCredentials: true }
      );

      const decryptedData = decryptData(response.data.encryptedData);

      const parsedData = JSON.parse(decryptedData);
      navigate("/assignment/select-kpis", {
        state: {
          responseData:
            parsedData.extractedItemList || location.state?.responseData,
          selectedValues: formattedSelectedData,
          org: org,
          selectedCategories: selectedCategories,
          selectedData: selectedData,
          selectedSubcategories: selectedSubcategories,
          selectedOption: selectedOption,
          scorecard: scorecard,
          changedFrequencies: location.state?.changedFrequencies,
          checkedRows: location.state?.checkedRows,
          selectedFrequencies: location.state?.selectedFrequencies,
          checkedAll: location.state?.checkedAll,
          frameworkFilter: location.state?.frameworkFilter,
          // selectedCount: location.state?.selectedCount,
          selectedUsers: location.state?.selectedUsers,
        },
      });
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
      setLoading(false);
    }
  };
  const getCheckedTopics = (selectedData) => {
    const checkedTopics = [];
    for (const category in selectedData) {
      for (const subcategory in selectedData[category]) {
        for (const element in selectedData[category][subcategory]) {
          if (selectedData[category][subcategory][element]) {
            checkedTopics.push(element);
          }
        }
      }
    }
    return checkedTopics;
  };
  useEffect(() => {
    if (location.state?.scorecard) {
      setScorecard(location.state.scorecard);
    }
  }, []);

  const step = 2;

  const handleBack = () => {
    navigate("/assignment/select-priority", {
      state: {
        org: org,
        selectedOption: requestData,
        selectedData: selectedData,
        selectedCategories: selectedCategories,
        selectedSubcategories: selectedSubcategories,
        responseData: location.state?.responseData,
        scorecard: scorecard,
        changedFrequencies: location.state?.changedFrequencies,
        checkedRows: location.state?.checkedRows,
        selectedFrequencies: location.state?.selectedFrequencies,
        checkedAll: location.state?.checkedAll,
        frameworkFilter: location.state?.frameworkFilter,
        selectedCount: location.state?.selectedCount,
        selectedUsers: location.state?.selectedUsers,
      },
    });
  };
  if (loading) {
    return <Loader loading={loading} />;
  }

  return (
    <>
      <Hamburger />
      <JoyRide steps={steps} />
      <div className="standards-container">
        <MultiStepAssignManager step={step} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <h3 className="frame_heading">Select Pillar Theme and Topic</h3>
        </Box>

        <div
          style={{
            margin: "20px 0",
            padding: "1rem",
            width: "95%",
            background: "white",
          }}
        >
          <FrameWorkCard
            frameworkData={frameworkData}
            selectedCategories={selectedCategories}
            handleCategoryCheckboxChange={handleCategoryCheckboxChange}
            handleSubcategoryCheckboxChange={handleSubcategoryCheckboxChange}
            selectedSubcategories={selectedSubcategories}
            handleCheckboxChange={handleCheckboxChange}
            selectedData={selectedData}
          />
          <PillarScoreCard scorecard={scorecard} />
          <Button
            sx={{ textTransform: "none" }}
            style={{
              color: "gray",
              padding: "5px 15px",
              borderRadius: "20px",
              border: "1px solid gray",
              margin: "0 10px",
            }}
            onClick={handleClearSelection}
          >
            Clear Selection
          </Button>
          <Button onClick={handleBack} style={backBtnfixed}>
            Back
          </Button>
          <Button style={buttoncontaindedfixed} onClick={handleSubmit} className="org-continue-btn">
            Continue
          </Button>
        </div>

      </div>
      {showTourModal &&
        <ConfirmModal
          open={showTourModal}
          onClose={handleCancelTour}
          title={"Page Tour"}
          message={"Would you like a tour of the page?"}
          confirmText={"Yes, show me around"}
          cancelText={"No, thanks"}
          onConfirm={handleRunTour}
        />
      }
    </>
  );
};
export default FrameWorks;
