import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  backBtn,
  backBtnfixed,
  buttoncontaindedfixed,
  inputfieldstyle,
  inputfieldstyle1,
} from "../../styles";
import { toast } from "react-toastify";
import { baseUrl, config } from "../../baseurl";
import { useLocation, useNavigate } from "react-router-dom";
import { decryptData } from "../../services/crypto";
import UserMultiStep from "./UserMultiStep";
import { BsInfoCircle } from "react-icons/bs";
import { fetchAllOrganizations } from "../../services/OrganizationManagemnetApi";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getOrg, getUserEmail, getUserRole } from "../../services/loggedIn";

export const UserDetails = () => {
  const location = useLocation();

  const checkedDepartments = location.state?.checkedDepartments;
  const [username, setUsername] = useState(
    location.state?.data?.userName || ""
  );
  const [email, setEmail] = useState(location.state?.data?.email || "");
  const [phone, setPhone] = useState(location.state?.data?.mobile || "");
  const [password, setPassword] = useState(
    location.state?.data?.password || ""
  );
  const [org, setOrg] = useState({
    organizationEmail: "",
    name: "",
  });
  const [confiPassword, setConfiPassword] = useState(
    location.state?.data?.password || ""
  );
  const [reportingTo, setreportingTo] = useState(
    location.state?.data?.reportingTo || ""
  );
  const [error, seterror] = useState("");
  const [admin, setadmin] = useState(location.state?.data?.adminRights || "");
  const [orgnames, setnames] = useState([]);
  const [code, setCode] = useState(location.state?.data?.countryCode || "");
  const [mobileError, setMobileError] = useState(false);
  const navigate = useNavigate();
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [country, setCountry] = useState(
    location.state?.orgData?.country || ""
  );
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowconfirmpassword] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState(
    location.state?.data?.projects || []
  );
  const [selectedManagers, setSelectedManagers] = useState(
    location.state?.data?.selectedManagers || []
  );
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [managers, setManagers] = useState([]);
  const [allAdmins, setAllAdmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOrgSelected, setIsOrgSelected] = useState(false);
  const [isAddingManager, setIsAddingManager] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowconfirmpassword(!showConfirmPassword);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (location.state?.data) {
      setIsEditMode(true);
    }
    // Cleanup function
    return () => {
      setIsEditMode(false);
    };
  }, [location.state]);

  useEffect(() => {
    if (location.state?.data) {
      setIsEditMode(true);
      setOrg({
        organizationEmail: location.state.data.organizationEmail,
        name: location.state.data.organizationName,
      });
      setadmin(location.state.data.adminRights);
      setIsOrgSelected(true); // Set this to true when returning to the page
    }
    if (location.state?.orgEmail && location.state?.organizationName) {
      setOrg({
        organizationEmail: location.state.orgEmail,
        name: location.state.organizationName,
      });
      setIsOrgSelected(true); // Set this to true when returning to the page

      // Auto-fill email domain when organization is selected
      if (!isEditMode && !email) {
        const emailDomain = location.state.orgEmail.split('@')[1];
        if (emailDomain) {
          setEmail(`@${emailDomain}`);
        }
      }

      if (location.state?.isAddingManager) {
        setIsAddingManager(true);
        setadmin("Manager");
        setIsOrgSelected(true);
      } else {
        setadmin("");
      }
    }

  }, [location.state]);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;

    // Password validation conditions
    const hasUpperCase = /[A-Z]/.test(newPassword);
    const hasLowerCase = /[a-z]/.test(newPassword);
    const hasNumber = /\d/.test(newPassword);
    const hasSpecialChar = /[!@#$%^&*]/.test(newPassword);
    const isLengthValid = newPassword.length >= 8;

    // Constructing the error message dynamically
    let errorMessage = " ";
    if (!hasUpperCase) errorMessage += " one uppercase, ";
    if (!hasLowerCase) errorMessage += " one lowercase ,";
    if (!hasNumber) errorMessage += "  one number, ";
    if (!hasSpecialChar) errorMessage += "  one special CHAR, ";
    if (!isLengthValid)
      errorMessage += "Password must be at least 8 CHAR long. ";

    // Update the error state and password state
    seterror(errorMessage);
    if (errorMessage === " ") {
      seterror("");
    }
    setPassword(newPassword);
  };

  const validateMobileNumber = (value) => {
    const isValid = /^\d+$/.test(value);
    setMobileError(!isValid);
    return isValid;
  };
  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, "");
    const limitedValue = numericValue.slice(0, 10);
    setPhone(limitedValue);
  };
  const [passwordError, setPasswordError] = useState("");

  useEffect(() => {
    if (location.state?.data?.organization) {
      const organizationName = location.state.data.organization;
      const organizationObject = orgnames.find(
        (org) => org.organizationName === organizationName
      );

      if (organizationObject) {
        setOrg({
          organizationEmail: organizationObject.organizationEmail,
          name: organizationObject.organizationName,
        });
      }
    }
  }, [location.state, orgnames]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValidEmail || isEditMode) {
      e.preventDefault();
      const userData = {
        userName: username,
        email,
        password,
        mobile: phone,
        countryCode: code,
        organizationName: org.name,
        organizationEmail: org.organizationEmail,
        reportingTo: reportingTo === "" ? "N/A" : reportingTo,
        adminRights: admin,
        operationalUnits: "",
        country: country,
        projects: selectedProjects,
        selectedManagers: selectedManagers,
      };
      if (password.length >= 6) {
        if (password === confiPassword && phone.length === 10) {
          navigate("/user/add-dept-ou", {
            state: {
              data: userData,
              backenddata: orgnames,
              checkedDepartments: checkedDepartments,
              organizationName: location.state?.organizationName || org.name,
              orgEmail: location.state?.orgEmail || org.organizationEmail,
            },
          });
        } else if (password !== confiPassword) {
          toast.error("Password not same a confirm passwod");
        } else if (phone.length !== 10) {
          toast.error("Mobile Number should be of 10 digits");
        }
      }
    } else {
      toast.error("Email already exist");
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/countryCodes`, config);
        // Assuming the response data is an object with country names as keys and country codes as values
        setCountryData(response.data);

        // After setting country data, find and set the selected country
        if (country && code) {
          const countryObject = Object.entries(response.data)
            .map(([label, value]) => ({ label, value }))
            .find((c) => c.label === selectedCountry && c.value === code);

          if (countryObject) {
            setSelectedCountry(countryObject);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserData();
  }, []);
  const countries = Object.entries(countryData).map(([label, value]) => ({
    label,
    value,
  }));

  const handleChange = (event, newValue) => {
    if (newValue) {
      setCountry(newValue.label);
      setCode(newValue.value);
      setSelectedCountry(newValue);
    } else {
      setCountry(null);
      setCode(null);
    }
  };

  // Function to filter organizations
  async function filterOrganizationsForUser(role, email, orgData, userData) {
    // console.log("userData", userData);

    // Check if userData exists and has the Manager key
    if (!userData || !userData.Manager) {
      console.error("User data is not in the expected format or no managers");
      return [];
    } else {
      console.error("User data is not in the expected format or no managers");
    }

    if (role === "Manager") {
      // Find the manager in the users data
      const manager = userData.Manager.find((user) => user.email === email);

      if (!manager) {
        // // console.log("Manager not found");
        return []; // If manager not found, return empty array
      }

      // Get the list of organizations the manager is assigned to
      const managerProjects = Array.isArray(manager.projects)
        ? manager.projects.map((project) => project.orgEmail)
        : [];

      // Filter the organization data
      return orgData.filter((org) =>
        managerProjects.includes(org.organizationEmail)
      );
    } else {
      // Find the manager in the users data
      const consultant = userData.Consultant.find(
        (user) => user.email === email
      );

      if (!consultant) {
        // // console.log("Manager not found");
        return []; // If manager not found, return empty array
      }

      // Get the list of organizations the manager is assigned to
      const consultantProjects = Array.isArray(consultant.projects)
        ? consultant.projects.map((project) => project.orgEmail)
        : [];

      // Filter the organization data
      return orgData.filter((org) =>
        consultantProjects.includes(org.organizationEmail)
      );
    }
  }
  useEffect(() => {
    const organizationName = getOrg();

    const fetchData = async () => {
      setLoading(true);
      try {
        // Execute both API calls simultaneously
        const [usersResponse, organizationsResponse] = await Promise.all([
          axios.post(
            `${baseUrl}/getAllManagersAndConsultant`,
            { organizationName },
            { withCredentials: true }
          ),
          fetchAllOrganizations(),
        ]);

        // Process users data
        const decryptedUsersData = decryptData(
          usersResponse.data.encryptedData
        );
        const parsedUsersData = JSON.parse(decryptedUsersData);
        setAllAdmins(parsedUsersData.users.Admin);
        setManagers(parsedUsersData.users.Manager);

        // Process organizations data
        const decryptedOrgsData = decryptData(organizationsResponse.data);
        const parsedOrgsData = JSON.parse(decryptedOrgsData);
        setProjects(parsedOrgsData.unmarshalledData);
        setnames(parsedOrgsData.unmarshalledData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (org.organizationEmail) {
      const filtered = projects.filter(
        (project) => project.organizationEmail !== org.organizationEmail
      );
      setFilteredProjects(filtered);
    } else {
      setFilteredProjects(projects);
    }
  }, [org, projects]);

  const handleConfirmPasswordChange = (e) => {
    setConfiPassword(e.target.value);
    if (e.target.value !== password) {
      setPasswordError("Passwords do not match");
    } else {
      setPasswordError("");
    }
  };

  function handleSelectChange(event) {
    const selectedOrgEmail = event.target.value;
    const selectedOrgObject = orgnames.find(
      (org) => org.organizationEmail === selectedOrgEmail
    );

    if (selectedOrgObject) {
      setOrg({
        organizationEmail: selectedOrgObject.organizationEmail,
        name: selectedOrgObject.organizationName,
      });
      setIsOrgSelected(true);
    } else {
      setIsOrgSelected(false);
    }
  }
  useEffect(() => {
    const validateEmail = async () => {
      if (isEditMode) {
        setIsValidEmail(true); // Always set to true in edit mode
        return;
      }

      const isCompleteEmail = /\S+@\S+\.\S+/.test(email);

      if (isCompleteEmail) {
        const data = {
          userKey: email,
        };
        await axios
          .post(`${baseUrl}/checkUser`, data, { withCredentials: true })
          .then((response) => {
            setIsValidEmail(false);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        setIsValidEmail(true);
      }
    };

    validateEmail();
  }, [email]);

  const step = 0;

  const handleManagerReportingTo = (event) => {
    const selectedAdmin = event.target.value;
    setreportingTo(selectedAdmin);
  };

  const handleProjectsChange = (event) => {
    const selectedValues = event.target.value;
    const selectedProjects = selectedValues.map((email) => {
      const project = projects.find((p) => p.organizationEmail === email);
      // // // console.log("projet", project);
      return {
        orgEmail: project.organizationEmail,
        orgName: project.organizationName,
      };
    });
    setSelectedProjects(selectedProjects);
  };

  const handleManagerChange = (event) => {
    const selectedManager = event.target.value;
    setSelectedManagers(selectedManager);
    setreportingTo(selectedManager);
  };

  const AdminUsers = ["Admin", "Manager", "Consultant"];
  const ClientUsers = ["Management", "Spoc", "User"];
  const ManagerUsers = ["Consultant", "User"];

  const handleRolesChange = (e) => {
    setadmin(e.target.value);
    setSelectedManagers("");
    setSelectedProjects([]);
  };

  const getUserRoles = () => {
    const userRole = getUserRole();
    const currentOrg = getOrg();
    const isAdminOrManager = userRole === "Admin" || userRole === "Manager";
    const consultant = userRole === "Consultant";
    const isEnvintOrg = org.name === currentOrg;

    // Always return roles if admin is set
    if (admin || isOrgSelected) {
      if (consultant) {
        return ClientUsers.filter(
          (user) => user !== "Management" && user !== "Spoc"
        );
      }

      if (isAdminOrManager) {
        if (isEnvintOrg) {
          if (userRole === "Admin") return AdminUsers;
          if (userRole === "Manager")
            return AdminUsers.filter((user) => user !== "Admin");
        }
        return ClientUsers;
      }
      return ClientUsers;
    }

    return []; // Return an empty array if no organization is selected and no role is set
  };
  const emailRegex = /^[a-zA-Z0-9._-]*@?[a-zA-Z0-9.-]*\.?[a-zA-Z]*$/
  // /^[a-zA-Z0-9._@]*$/;
  const nameRegex = /^[a-zA-Zà-ÿÀ-Ÿ' .-]*$/;
  const handleEmailChange = (e) => {
    const value = e.target.value;
    if (emailRegex.test(value)) {
      setEmail(value);
    }
  };
  const handleUsernameChange = (e) => {
    const value = e.target.value;
    if (nameRegex.test(value)) {
      setUsername(value);
    }
  };

  const handleCopy = (e) => {
    e.preventDefault();
    toast.warn("Copying is disabled!");
  };

  const handleCut = (e) => {
    e.preventDefault();
    toast.warn("Cutting is disabled!");
  };

  const handlePaste = (e) => {
    e.preventDefault();
    toast.warn("Pasting is disabled!");
  };

  return (
    <>
      <div className="add-container">
        <Box sx={{ marginBottom: "20px" }}>
          <UserMultiStep step={step} />
        </Box>
        <h3 className="frame_heading">User Details</h3>
        <p
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <BsInfoCircle />
          &nbsp; All the fields are required.
        </p>

        <Box
          sx={{
            width: "50%",
            margin: "0px auto",
            padding: "20px",
            borderRadius: "20px",
          }}
          boxShadow={3}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  style={inputfieldstyle}
                  required
                  error={!isEditMode && !isValidEmail}
                  helperText={
                    !isEditMode && !isValidEmail ? "Email already exists." : ""
                  }
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent form submission
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Name"
                  variant="outlined"
                  type="text"
                  value={username}
                  onChange={handleUsernameChange}
                  style={inputfieldstyle1}
                  required
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent form submission
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Mobile Number"
                  variant="outlined"
                  type="tel"
                  value={phone}
                  onChange={handleMobileNumberChange}
                  style={inputfieldstyle1}
                  required
                  error={mobileError}
                  helperText={mobileError ? "Enter only numbers" : ""}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent form submission
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  style={inputfieldstyle1}
                  options={countries}
                  getOptionLabel={(option) =>
                    `${option.label} (${option.value})`
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Select a country" />
                  )}
                  onChange={handleChange}
                  value={selectedCountry}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent form submission
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Password"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={handlePasswordChange}
                  onCopy={handleCopy}
                  onCut={handleCut}
                  onPaste={handlePaste}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent form submission
                    }
                  }}
                  // style={inputfieldstyle}
                  required
                  error={error}
                  helperText={error ? error : ""}
                  InputProps={{
                    endAdornment: (
                      <div
                        onClick={togglePasswordVisibility}
                        edge="end"
                        style={{
                          color: "#3079bd",
                          marginRight: "-10px",
                          padding: "0",
                          cursor: "pointer",
                        }}
                      >
                        {showPassword ? (
                          <VisibilityIcon
                            style={{ padding: "0", width: "20px" }}
                          />
                        ) : (
                          <VisibilityOffIcon
                            style={{ padding: "0", width: "20px" }}
                          />
                        )}
                      </div>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Confirm Password"
                  variant="outlined"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confiPassword}
                  required
                  onChange={handleConfirmPasswordChange}
                  error={passwordError}
                  helperText={passwordError ? passwordError : ""}
                  onCopy={handleCopy}
                  onCut={handleCut}
                  onPaste={handlePaste}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent form submission
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <div
                        onClick={toggleConfirmPasswordVisibility}
                        edge="end"
                        style={{
                          color: "#3079bd",
                          marginRight: "-10px",
                          padding: "0",
                          cursor: "pointer",
                        }}
                      >
                        {showConfirmPassword ? (
                          <VisibilityIcon
                            style={{ padding: "0", width: "20px" }}
                          />
                        ) : (
                          <VisibilityOffIcon
                            style={{ padding: "0", width: "20px" }}
                          />
                        )}
                      </div>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel id="demo-simple-select-label">
                  Organization
                </InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={handleSelectChange}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent form submission
                    }
                  }}
                  value={org.organizationEmail || ""}
                // style={inputfieldstyle}
                >
                  <MenuItem value="" disabled>
                    Select Organization
                  </MenuItem>
                  {location.state?.orgEmail && location.state?.orgEmail ? (
                    <MenuItem
                      key={org.organizationEmail}
                      value={org.organizationEmail}
                    >
                      {org.name}
                    </MenuItem>
                  ) : getUserRole() === "Manager" ? (
                    orgnames?.map((value) => (
                      <MenuItem
                        key={value.organizationEmail}
                        value={value.organizationEmail}
                      >
                        {value.organizationName}
                      </MenuItem>
                    ))
                  ) : (
                    orgnames.map((value) => (
                      <MenuItem
                        key={value.organizationEmail}
                        value={value.organizationEmail}
                      >
                        {value.organizationName}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel id="roles-select-label">Roles</InputLabel>
                <Select
                  fullWidth
                  labelId="roles-select-label"
                  id="roles-select"
                  value={admin}
                  onChange={handleRolesChange}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                  disabled={isAddingManager}
                >
                  {!isOrgSelected && !admin ? (
                    <MenuItem value="" disabled>
                      Select organization to display respective roles
                    </MenuItem>
                  ) : (
                    getUserRoles().map((user, i) => (
                      <MenuItem value={user} key={i}>
                        {user}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </Grid>
              {admin === "Manager" && (
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="reporting-to-label">
                      Select Admin to Report to
                    </InputLabel>
                    <Select
                      labelId="reporting-to-label"
                      label="Select Admin to Report to"
                      id="reporting-to"
                      value={reportingTo}
                      onChange={handleManagerReportingTo}
                      renderValue={(selected) => {
                        // Find the manager objects for the selected emails
                        const selectedAdminObjects = allAdmins.filter(
                          (manager) => selected.includes(manager.email)
                        );
                        // Return a string of selected manager names
                        return selectedAdminObjects
                          .map((admin) => admin.userName)
                          .join(", ");
                      }}
                    >
                      {allAdmins?.map((manager, index) => (
                        <MenuItem key={index} value={manager.email}>
                          {/* <Checkbox
                            checked={reportingTo.indexOf(manager.email) > -1}
                          /> */}
                          <ListItemText
                            primary={manager.userName}
                            secondary={manager.email}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {admin === "Manager" && (
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="project-label">
                      Select Organizations for Manager
                    </InputLabel>
                    <Select
                      labelId="project-label"
                      label="Select Organizations for Manager"
                      id="project"
                      multiple
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent form submission
                        }
                      }}
                      // required
                      value={selectedProjects.map((p) => p.orgEmail)}
                      onChange={handleProjectsChange}
                      renderValue={(selected) =>
                        selectedProjects.map((p) => p.orgName).join(", ")
                      }
                    >
                      {filteredProjects?.map((project, index) => (
                        <MenuItem key={index} value={project.organizationEmail}>
                          <Checkbox
                            checked={selectedProjects.some(
                              (p) => p.orgEmail === project.organizationEmail
                            )}
                          />
                          <ListItemText
                            primary={project.organizationName}
                            secondary={project.organizationEmail}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {admin === "Consultant" && (
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="manager-label">
                      Select Manager to report to
                    </InputLabel>
                    <Select
                      labelId="manager-label"
                      id="manager-label"
                      // multiple
                      value={selectedManagers}
                      onChange={handleManagerChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent form submission
                        }
                      }}
                      renderValue={(selected) => {
                        // Find the selected manager object
                        const selectedManager = managers.find(
                          (manager) => manager.email === selected
                        );

                        // Return the selected manager's name
                        return selectedManager ? selectedManager.userName : "";
                      }}
                    >
                      {managers.map((manager, index) => (
                        <MenuItem key={index} value={manager.email}>
                          <ListItemText
                            primary={manager.userName}
                            secondary={manager.email}
                          />
                        </MenuItem>
                      ))}
                      {/* )} */}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {admin === "Consultant" && (
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="project-label">
                      Select Projects for Consultant
                    </InputLabel>
                    <Select
                      labelId="project-label"
                      id="project"
                      multiple
                      value={selectedProjects.map((p) => p.orgEmail)}
                      onChange={handleProjectsChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent form submission
                        }
                      }}
                      renderValue={(selected) =>
                        selectedProjects.map((p) => p.orgName).join(", ")
                      }
                    >
                      {filteredProjects?.map((project, index) => (
                        <MenuItem key={index} value={project.organizationEmail}>
                          <Checkbox
                            checked={selectedProjects.some(
                              (p) => p.orgEmail === project.organizationEmail
                            )}
                          />
                          <ListItemText
                            primary={project.organizationName}
                            secondary={project.organizationEmail}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
            <Button
              type="submit"
              style={backBtnfixed}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <Button type="submit" style={buttoncontaindedfixed}>
              Continue
            </Button>
          </form>
        </Box>
      </div>
    </>
  );
};
