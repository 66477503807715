import React, {useEffect, useRef, useState} from 'react';
import './ProgressBar.css'; // Import CSS for styling

const ProgressBar = ({ currentScore, totalScore }) => {
  const [animate, setAnimate] = useState(false);
  const [percentageFilled, setPercentageFilled] = useState(0);
  const [animationSpeed, setAnimationSpeed] = useState(1.5); // Adjust animation speed here (in seconds)


  const progressRef = useRef(null);

  useEffect(() => {
    setAnimate(true);
    setPercentageFilled((currentScore / totalScore) * 100);
  }, [currentScore, totalScore]);

  // Calculate the dash offset based on the percentage filled
  const dashOffset = 283 - (283 * percentageFilled) / 100; // 283 is the circumference of a circle with radius 45

  useEffect(() => {
    if (animate) {
      const progressRing = progressRef.current;
      progressRing.style.transition = `stroke-dashoffset ${animationSpeed}s ease-out`;
      progressRing.style.strokeDashoffset = dashOffset;
    }
  }, [animate, animationSpeed, dashOffset]);

  // Determine color based on percentageFilled
  let color;
  if (percentageFilled >= 0 && percentageFilled < 30) {
    color = 'red'; 
  } else if (percentageFilled >= 30 && percentageFilled < 80) {
    color = '#ff9c00'; 
  } else if (percentageFilled >= 80 && percentageFilled <= 100) {
    color = '#45b653'; // Light green
  } else {
    color = '#45b653'; // Dark green
  }

  return (
    <div className="round-progress-bar">
      <svg className="progress-ring" width="100" height="100">
        {/* Background circle */}
        <circle
          className="progress-ring-circle-back"
          stroke="#e0e0e0"
          strokeWidth="4" // Adjust thickness here
          fill="transparent"
          r="45"
          cx="50"
          cy="50"
        />
        {/* Progress circle */}
        <circle
          ref={progressRef}
          className="progress-ring-circle"
          stroke={color} 
          strokeWidth="4" 
          fill="transparent"
          r="45"
          cx="50"
          cy="50"
          style={{
            strokeDasharray: 283,
            strokeDashoffset: 283 
          }}
        />
      </svg>

      <div className="progress-text">
        <div className="progress-count-round">
          <span style={{ color: "#3079bd", fontSize: '25px' }}>{currentScore}</span>/{totalScore}
        </div>
        <div className="progress-percent">{percentageFilled.toFixed(1)}%</div>
      </div>
    </div>
  );
};

export default ProgressBar;
