import axios from "axios";
import { toast } from "react-toastify";
import encrypt from "../../../services/crypto";
import { captureData } from "../../../services/ClientSideApis";
import { getUserEmail } from "../../../services/loggedIn";

import { clearPreservedInputsByDeptAndOU } from '../../../redux/slice/PreservedSlice'; // Adjust import path as needed


// export const handleSubmit = async (
//   updateDrafTableData,
//   formData,
//   fdata,
//   currentYears,
//   currentMonths,
//   data,
//   setLoading,
//   setShowConfirmModal,
//   setModalInView,
//   modalInView,
//   editedFields,
//   countryCode
// ) => {
//   // console.log("currentYears save draft", currentYears);
//   // console.log("currentMonths save draft", currentMonths);
//   // console.log("updateDrafTableData:", updateDrafTableData);
//   // console.log("formData:", formData);
//   // console.log("fdata:", fdata);
//   // console.log("currentYears:", currentYears);
//   // console.log("currentMonths:", currentMonths);
//   // console.log("data:", data);

//   try {
//     setLoading(true);

//     const formDataToSend = [];
//     let containsDraftedData = false;

//     Object.keys(formData).forEach((kpiCode) => {
//       const kpiData = Object.values(fdata)
//         .flat()
//         .find((item) => item.KPICode === kpiCode);

//       // console.log("kpiData", kpiData);
//       // console.log(" formData[kpiCode]", formData[kpiCode]);
//       if (
//         formData[kpiCode] !== undefined &&
//         formData[kpiCode] !== "undefined" &&
//         kpiData?.KPIStatus !== "approved"
//       ) {
//         // console.log("currentYears[kpiCode]", currentYears[kpiCode]);

//         const inputYearMonth = currentYears[kpiCode] || currentMonths[kpiCode];
//         // console.log("inputYearMonth", inputYearMonth);

//         const frequency =
//           inputYearMonth?.length === 9
//             ? { Year: inputYearMonth }
//             : { Month: inputYearMonth };

//         const isSavedOrDraftedForYearOrMonth = Object.values(fdata)
//           .flat()
//           .some(
//             (item) =>
//               item.KPICode === kpiCode &&
//               (item.KPIStatus === "saved" || item.KPIStatus === "drafted") &&
//               ((frequency.Year && item.Year === frequency.Year) ||
//                 (frequency.Month && item.Month === frequency.Month))
//           );

//         // console.log(
//           "isSavedOrDraftedForYearOrMonth",
//           isSavedOrDraftedForYearOrMonth
//         );

//         let saveDrafted = false;

//         if (kpiData?.KPIStatus === "drafted") {
//           containsDraftedData = true;
//           saveDrafted = true; // Save drafted data
//         } else if (
//           (!isSavedOrDraftedForYearOrMonth || editedFields.includes(kpiCode)) &&
//           formData[kpiCode] !== ""
//         ) {
//           // console.log("1");

//           saveDrafted = true; // Save new or edited data
//         }

//         if (saveDrafted) {
//           let kpiInput;
//           if (kpiData?.Format === "Telephone") {
//             const phoneNumber = formData[kpiCode];
//             kpiInput = {
//               countryCode: countryCode,
//               number: phoneNumber,
//             };
//           } else if (kpiData?.Format === "Number") {
//             kpiInput = formData[kpiCode].toString(); // Ensure number fields are handled correctly
//           } else {
//             kpiInput = formData[kpiCode];
//           }

//           formDataToSend.push({
//             KPICode: kpiCode,
//             KPIInput: kpiInput,
//             format: kpiData?.Format,
//             draftStatus: "saved", // Ensure the draftStatus is updated to 'saved'
//             frequency: frequency,
//             department: data.orgdept,
//             operationalUnit: data.orgplt,
//             userEmail: getUserEmail(),
//           });

//           // console.log("formDataToSend", formDataToSend);
//         }
//       }
//     });

//     let encryptedKPIData;

//     if (updateDrafTableData?.length > 0) {
//       encryptedKPIData = encrypt({ updateDrafTableData });
//     } else if (formDataToSend?.length > 0) {
//       encryptedKPIData = encrypt({ formDataToSend });
//     }

//     // console.log("encryptedKPIData", encryptedKPIData);

//     if (formDataToSend.length === 0) {
//       setLoading(false);
//       toast.warn("No new data to submit.");
//       return "0";
//     }

//     if (modalInView) {
//       setShowConfirmModal(false);
//       return await continueSubmission(formDataToSend, setLoading);
//     }

//     if (containsDraftedData && typeof setShowConfirmModal === "function") {
//       // // console.log("modalinview1");
//       const userConfirmed = await setShowConfirmModal(true);
//       // // console.log("user", userConfirmed);
//       // // console.log("1");
//       setModalInView(true);
//       // // console.log("2");
//       if (!userConfirmed) {
//         setLoading(false);
//         return "0";
//       }
//     }
//     // // console.log("modalinview", modalInView);

//     //  else if (containsDraftedData) {
//     //   // Fallback to window.confirm if showConfirmModal is not provided
//     //   const userConfirmed = window.confirm(
//     //     "It will save the drafted data also. Are you sure you want to continue?"
//     //   );
//     //   if (!userConfirmed) {
//     //     setLoading(false);
//     //     return "0";
//     //   }
//     // }

//     if (formDataToSend?.length > 0) {
//       return await continueSubmission(formDataToSend, setLoading);
//     } else if (updateDrafTableData?.length > 0) {
//       return await continueSubmission(updateDrafTableData, setLoading);
//     }
//     setLoading(false);

//     // return await continueSubmission(formDataToSend || updateDrafTableData, setLoading);
//   } catch (error) {
//     setLoading(false);
//     console.error("Error submitting form:", error);
//     toast.error("Error submitting form. Please try again later.");
//   }
// };

// const continueSubmission = async (formDataToSend, setLoading) => {
//   // console.log("Form dAta to send======", formDataToSend);
//   const encryptedKPIData = encrypt({ formDataToSend });

//   await captureData(encryptedKPIData);

//   setLoading(false);
//   toast.success("Form submitted successfully!");
//   localStorage.removeItem("draftData");
//   return formDataToSend.length;
// };


export const handleSubmit = async (
  dispatch,
  updateDrafTableData,
  formData,
  fdata,
  currentYears,
  currentMonths,
  data,
  setLoading,
  setShowConfirmModal,
  setModalInView,
  modalInView,
  editedFields,
  countryCode,
  setError
) => {
  try {
    setLoading(true);
    const formDataToSend = [];
    let containsDraftedData = false;
    const processedEntries = new Set();

    // console.log("updateDrafTableData:", updateDrafTableData);
    // console.log("formData:", formData);
    // console.log("fdata:", fdata);
    // console.log("modalInView", modalInView);
    // console.log("setError", typeof setError);



    // First, process all drafted items
    const draftedItems = Object.values(fdata).flat().filter(item => item.KPIStatus === "drafted");

    draftedItems.forEach((draftedItem) => {
      const kpiCode = draftedItem.KPICode;
      const yearMonth = draftedItem.Year || draftedItem.Month;
      const formDataValue = formData[kpiCode]?.[yearMonth];
      const entryKey = `${kpiCode}-${yearMonth}`;
      processedEntries.add(entryKey);

      // Use formDataValue if it exists, otherwise use the original drafted value
      let valueToSubmit = formDataValue !== undefined ? formDataValue : draftedItem.KPIInput;

      let kpiInput;
      if (draftedItem.Format === "Telephone") {
        if (typeof valueToSubmit === 'object') {
          kpiInput = valueToSubmit; // Use as is if it's already formatted
        } else {
          kpiInput = {
            countryCode: countryCode,
            number: valueToSubmit,
          };
        }
      } else if (draftedItem.Format === "Number") {
        kpiInput = valueToSubmit.toString();
      } else if (draftedItem.Format === "Ratio") {
        kpiInput = typeof valueToSubmit === 'object' ? valueToSubmit : { [yearMonth]: valueToSubmit };
      } else {
        kpiInput = valueToSubmit;
      }

      formDataToSend.push({
        KPICode: kpiCode,
        KPIInput: kpiInput,
        format: draftedItem.Format,
        draftStatus: "saved",
        frequency: draftedItem.Year ? { Year: draftedItem.Year } : { Month: draftedItem.Month },
        department: draftedItem.department || data.orgdept,
        operationalUnit: draftedItem.operationalUnit || data.orgplt,
        userEmail: getUserEmail(),
      });

      containsDraftedData = true;
    });

    // Then process new entries from formData that weren't drafted
    Object.keys(formData).forEach((kpiCode) => {
      const kpiData = Object.values(fdata)
        .flat()
        .find((item) => item.KPICode === kpiCode);

      const formDataByYear = formData[kpiCode];

      if (formDataByYear && typeof formDataByYear === "object") {
        Object.keys(formDataByYear).forEach((inputYearMonth) => {
          const entryKey = `${kpiCode}-${inputYearMonth}`;

          // Skip if we've already processed this entry
          if (processedEntries.has(entryKey)) {
            return;
          }

          const kpiValue = formDataByYear[inputYearMonth];
          if (kpiValue === undefined || kpiValue === "undefined" || kpiValue === "") {
            return;
          }

          const isYear = inputYearMonth.length === 9;
          const frequency = isYear
            ? { Year: inputYearMonth }
            : { Month: inputYearMonth };

          const existingData = Object.values(fdata)
            .flat()
            .find(
              (item) =>
                item.KPICode === kpiCode &&
                item.department === data.orgdept &&
                item.operationalUnit === data.orgplt &&
                ((isYear && item.Year === inputYearMonth) ||
                  (!isYear && item.Month === inputYearMonth))
            );

          // Skip if data is already saved or approved
          if (
            existingData &&
            (existingData.KPIStatus === "saved" ||
              existingData.KPIStatus === "approved")
          ) {
            return;
          }

          let kpiInput;
          if (kpiData?.Format === "Telephone") {
            kpiInput = {
              countryCode: countryCode,
              number: kpiValue,
            };
          } else if (kpiData?.Format === "Number") {
            kpiInput = kpiValue.toString();
          } else if (kpiData?.Format === "Ratio") {
            kpiInput = typeof kpiValue === 'object' ? kpiValue : { [inputYearMonth]: kpiValue };
          } else {
            kpiInput = kpiValue;
          }

          if ((kpiData?.Format === "Ratio" && isYear) || kpiData?.Format !== "Ratio") {
            formDataToSend.push({
              KPICode: kpiCode,
              KPIInput: kpiInput,
              format: kpiData?.Format,
              draftStatus: "saved",
              frequency: frequency,
              department: data.orgdept,
              operationalUnit: data.orgplt,
              userEmail: getUserEmail(),
            });
          }
        });
      }
    });

    // console.log("FormDataToSend:", formDataToSend);

    // Modified condition to allow submission when there's drafted data
    if (
      formDataToSend.length === 0 &&
      updateDrafTableData?.length === 0 &&
      !containsDraftedData
    ) {
      setLoading(false);
      toast.warn("No new data to submit.");
      return "0";
    }

    if (modalInView) {
      setShowConfirmModal(false);
      return await continueSubmission(formDataToSend?.length > 0 ? formDataToSend : updateDrafTableData, setLoading, dispatch, data, setError);
    } else if (containsDraftedData && typeof setShowConfirmModal === "function") {
      const userConfirmed = await setShowConfirmModal(true);
      setModalInView(true);
      if (!userConfirmed) {
        setLoading(false);
        return "0";

      }
    }

    if (formDataToSend?.length > 0) {
      return await continueSubmission(formDataToSend, setLoading, dispatch, data, setError);
    } else if (updateDrafTableData?.length > 0) {
      return await continueSubmission(updateDrafTableData, setLoading, dispatch, data, setError);
    }

    setLoading(false);
    return await continueSubmission(
      formDataToSend || updateDrafTableData,
      setLoading, dispatch, data, setError
    );
  } catch (error) {
    setLoading(false);
    setError(error.message)
    console.error("Error submitting form:", error);
    toast.error("Error submitting form. Please try again later.");
    throw error;
  }
};


const continueSubmission = async (formDataToSend, setLoading, dispatch, data, setError) => {
  // console.log("Form data to send======", formDataToSend);
  const encryptedKPIData = encrypt({ formDataToSend });

  try {
    setLoading(true);
    await captureData(encryptedKPIData);

    toast.success(
      `Form submitted successfully! ${formDataToSend.length} KPI(s) updated.`
    );
    dispatch(clearPreservedInputsByDeptAndOU({
      department: data.orgdept,
      operationalUnit: data.orgplt
    }));
    localStorage.removeItem("draftData");
    setLoading(false);
    return formDataToSend.length.toString();
  } catch (error) {
    setLoading(false);
    setError(error.message)
    console.error("Error in continueSubmission:", error);
    if (error.response) {
      console.error("Response data:", error.response.data);
      // console.error("Response status:", error.response.status);
      // console.error("Response headers:", error.response.headers);
    }
    toast.error("Error submitting form. Please try again later.");
    // throw error;
  }
};

export const handleSaveDraft = async (
  dispatch,
  updateDrafTableData,
  formData,
  fdata,
  currentYears,
  currentMonths,
  data,
  setLoading = () => { },
  setIsSavingDraft = () => { },
  editedFields,
  countryCode,
  showToast = true
) => {
  // console.log("updateDrafTableData 1", updateDrafTableData);

  try {
    if (showToast) {
      setLoading(true);
    }

    const formDataToSend = [];
    Object.keys(formData).forEach((kpiCode) => {
      const kpiData = Object.values(fdata)
        .flat()
        .find((item) => item.KPICode === kpiCode);

      if (formData[kpiCode] !== undefined && formData[kpiCode] !== "undefined" && kpiData.KPIStatus !== "approved") {
        const formDataByYear = formData[kpiCode];

        // Check if formDataByYear is an object with year-based keys
        if (formDataByYear && typeof formDataByYear === "object") {
          Object.keys(formDataByYear).forEach((inputYearMonth) => {
            const kpiValue = formDataByYear[inputYearMonth];

            if (kpiValue !== undefined && kpiValue !== "undefined") {
              const isYear = inputYearMonth.length === 9; // Assuming year is in YYYY-YYYY format
              const frequency = isYear
                ? { Year: inputYearMonth }
                : { Month: inputYearMonth };

              // Check if data is already saved or drafted for the same KPI code, year/month, department, and operational unit
              const isSavedOrDraftedForYearOrMonth = Object.values(fdata)
                .flat()
                .some(
                  (item) =>
                    item.KPICode === kpiCode &&
                    (item.KPIStatus === "saved" || item.KPIStatus === "drafted") &&
                    ((frequency.Year && item.Year === frequency.Year) ||
                      (frequency.Month && item.Month === frequency.Month))
                );

              // Proceed if the field was edited or no saved/drafted data exists for the year/month
              if ((!isSavedOrDraftedForYearOrMonth || editedFields.includes(kpiCode)) && kpiValue !== "") {
                let kpiInput;

                // Handle different input formats
                if (kpiData?.Format === "Telephone") {
                  const phoneNumber = kpiValue;
                  kpiInput = {
                    countryCode: countryCode,
                    number: phoneNumber,
                  };
                } else if (kpiData?.Format === "Number") {
                  kpiInput = kpiValue.toString();
                } else if (kpiData?.Format === "Ratio") {
                  kpiInput = typeof kpiValue === 'object' ? kpiValue : { [inputYearMonth]: kpiValue };
                } else {
                  kpiInput = kpiValue;
                }

                const draftStatus = "drafted";

                // For ratio type, only push once per year
                if (kpiData?.Format === "Ratio" && isYear) {
                  formDataToSend.push({
                    KPICode: kpiCode,
                    KPIInput: kpiInput,
                    format: kpiData?.Format,
                    draftStatus: draftStatus,
                    frequency: frequency,
                    department: data.orgdept,
                    operationalUnit: data.orgplt,
                    userEmail: getUserEmail(),
                  });
                } else if (kpiData?.Format !== "Ratio") {
                  // For non-ratio types, push as before
                  formDataToSend.push({
                    KPICode: kpiCode,
                    KPIInput: kpiInput,
                    format: kpiData?.Format,
                    draftStatus: draftStatus,
                    frequency: frequency,
                    department: data.orgdept,
                    operationalUnit: data.orgplt,
                    userEmail: getUserEmail(),
                  });
                }
              }
            }
          });
        }
      }
    });

    // Log the formData to send
    // console.log("formDataToSend draft handleasaveDraft function 1:", formDataToSend);

    // Make sure there's data to submit
    if (formData) {
      if (formDataToSend.length === 0) {
        setLoading(false);
        if (showToast) {
          toast.warn("No new data to draft.");
        }
        return "0";
      }
    }

    let encryptedKPIData;

    if (updateDrafTableData.length > 0) {
      // console.log("updateDrafTableData 2", updateDrafTableData);

      encryptedKPIData = encrypt({ updateDrafTableData });
    } else if (formDataToSend.length > 0) {
      // console.log("formDataToSend draft handleasaveDraft formDataToSend:", formDataToSend);

      encryptedKPIData = encrypt({ formDataToSend });
    }

    await captureData(encryptedKPIData);

    setLoading(false);
    if (showToast) {
      toast.success("Draft saved successfully!");
    }
    setIsSavingDraft(true); // Set the flag to indicate that a draft is being saved
    // Clear preserved inputs for the specific department and operational unit only
    dispatch(clearPreservedInputsByDeptAndOU({
      department: data.orgdept,
      operationalUnit: data.orgplt
    }));
    return formDataToSend.length > 0
      ? formDataToSend.length
      : updateDrafTableData.length;
  } catch (error) {
    console.error("Error saving draft:", error);
    toast.error("Error saving draft. Please try again later.");
  }
}
