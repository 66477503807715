import React, { useEffect, useState } from "react";
import "./UserManagement.css";
import Hamburger from "../../components/Navbars/Hamburger.js";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { buttoncontainded, inputfieldstyle1, tablehead } from "../../styles.js";
import { UserRow } from "../../components/Users/UserRow.jsx";
import { tableheaddata } from "./tableheaddata.js";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { FaFileArrowDown } from "react-icons/fa6";
import { decryptData } from "../../services/crypto.js";
import { deleteUser, fetchAllUser } from "../../services/UsermanagementApi.js";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../components/Loader/Loader.jsx";
import {
  getLoginAttempts,
  getOrg,
  getUserEmail,
  getUserRole,
} from "../../services/loggedIn";
import ConfirmModal from "../../components/PopUpDialog/ConfirmModal.jsx";
import Joyride, { STATUS } from "react-joyride";
import { JoyRide } from "../../Joyride.jsx";
import { useDispatch, useSelector } from "react-redux";
import { setRunTour, setShowTourModal, setTourCompleted } from "../../redux/slice/TourGuideSlice.js";

// The main component to display the user management page
const UserManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const { showTourModal, runTour, tourCompleted } = useSelector((state) => state.tourGuide);


  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  let [modalMode, setModalMode] = useState(null);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [releventOrgUsers, setReleventOrgUsers] = useState([]);
  const userRole = getUserRole();
  //
  // // console.log("releventOrgUsers", releventOrgUsers);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const loginAttempts = getLoginAttempts();

  useEffect(() => {
    // Check if it's the user's first login
    if (loginAttempts <= 3) {
      // setRunTour(true);
      dispatch(setShowTourModal(false))
      dispatch(setTourCompleted(false))
    }
  }, []);

  const handleRunTour = () => {
    dispatch(setRunTour(true))
    dispatch(setShowTourModal(false))
  }

  const handleCancelTour = () => {
    dispatch(setShowTourModal(false))
  }
  const steps = [
    {
      target: ".add-user-btn",
      content: "You can add a new user.",
      disableBeacon: true,
    },
    {
      target: ".user-table",
      content:
        "This table shows all the users. You can view, edit or delete each user.",
    },
    {
      target: ".viewusertable",
      content: "Click here to view user's department/ou association.",
    },
    {
      target: ".viewuser",
      content: "You can view the user's details.",
    },
    {
      target: ".edituser",
      content: "Click here to edit a user's details.",
    },
    {
      target: ".deleteuser",
      content: "Click here to delete a user.",
    },
    {
      target: ".user-list-download",
      content: "You can download the users list .",
    },
  ];

  // const finalSteps = getJoyrideSteps(userRole, steps);


  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        const allUserResponse = await fetchAllUser();
        const decryptedData = decryptData(allUserResponse.data.encryptedData);
        const parsedData = JSON.parse(decryptedData);

        setReleventOrgUsers(parsedData.users);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();
  }, []);
  const handleView = (user) => {
    setSelectedUser(user);
    navigate("/view-user", {
      state: {
        user: user,
      },
    });
  };
  const handleClose = () => {
    setModalMode(null);
  };
  const handleAddForm = () => {
    navigate("/user/add-user");
  };
  const handleAddUser = (user) => {
    //add user
    setUsers([...users, user]);
    setModalMode(null);
  };
  const handleDelete = (user) => {
    const e = getUserEmail();
    if (user.email === e) {
      toast.error("Unable to delete logged user");
    } else {
      setUserToDelete(user);
      setIsConfirmModalOpen(true);
    }
  };

  const handleConfirmDelete = () => {
    if (userToDelete) {
      const userData = {
        email: userToDelete.email,
      };
      deleteUser(userData)
        .then((response) => {
          setUsers((prevUsers) =>
            prevUsers.filter((u) => u.email !== userToDelete.email)
          );
          toast.success("User Deleted Successfully!");
        })
        .catch((error) => {
          console.error(error);
        });
      setIsConfirmModalOpen(false);
      setUserToDelete(null);
    }
  };

  const handleUpdate = (user) => {
    navigate("/user/update-user", {
      state: {
        user: user,
      },
    });
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(12);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const transformData = (data) => {
    return data.map((item) => {
      const newItem = { ...item };

      // Transform operationalUnits
      if (newItem.operationalUnits) {
        try {
          const units = JSON.parse(newItem.operationalUnits);
          // // console.log("units", units);
          // // console.log(
          //   "1",
          //   units
          //     .map((unit, index) => {
          //       const [key, value] = Object.entries(unit)[0];
          //       return `${key}- ${value}`;
          //     })
          //     .join("; ")
          // );

          newItem.operationalUnits = units
            .map((unit, index) => {
              const [key, value] = Object.entries(unit)[0];
              return `${key}- ${value}`;
            })
            .join("; ");
        } catch (error) {
          console.error("Error parsing operationalUnits:", error);
          newItem.operationalUnits = "N/A";
        }
      }

      // Remove unnecessary fields
      delete newItem.password;
      delete newItem.deleteStatus;
      delete newItem.otpFrequency;

      return newItem;
    });
  };

  const excelReportingName = "user_data_";

  const handleDownloadExcel = () => {
    let finalExcelReportingName = excelReportingName;

    const timestampInSecondsRounded = new Date()
      .toISOString()
      .split("T")[0]
      .replace(/-/g, "");

    const currentTime = new Date();

    // Get the current time as a string in the format HH:MM:SS
    const currentTimeString = currentTime
      .toLocaleTimeString()
      .split(":")
      .join("")
      .split(" ")[0];

    // Append the current time to the final excel reporting name
    finalExcelReportingName += `${timestampInSecondsRounded}${currentTimeString}.xlsx`;

    // Transform the data
    const transformedData = transformData(releventOrgUsers);

    const ws = XLSX.utils.json_to_sheet(transformedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Users");
    XLSX.writeFile(wb, finalExcelReportingName);
  };
  const filteredUsers = releventOrgUsers?.filter((user) => {
    return (
      (user.userName &&
        user.userName.toLowerCase().includes(search.toLowerCase())) ||
      (user.email && user.email.toLowerCase().includes(search.toLowerCase())) ||
      (user.mobile && user.mobile.includes(search))
    );
  });

  // Handle case where page might be out of bounds after filtering
  const maxPage = Math.max(
    0,
    Math.ceil(filteredUsers.length / rowsPerPage) - 1
  );
  const currentPage = page > maxPage ? maxPage : page;

  const paginatedUsers = filteredUsers
    .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
    .map((user, id) => (
      <UserRow
        key={user.userName}
        user={user}
        id={id + 1 + currentPage * rowsPerPage}
        onView={handleView}
        onDelete={handleDelete}
        onUpdate={handleUpdate}
      />
    ));
  return (
    <>
      <Hamburger />
      <JoyRide
        steps={steps}
      />
      <div className="container-user">
        <h1 className="frame_heading">User Management</h1>
        <div className="add-user-btn-search">
          <Button
            variant="contained"
            style={buttoncontainded}
            onClick={handleAddForm}
            className="add-user-btn"
          >
            Add User
          </Button>

          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              id="outlined-basic"
              label="Search User"
              variant="outlined"
              style={inputfieldstyle1}
              onChange={(e) => setSearch(e.target.value)}
            />
            <IconButton
              variant="outlined"
              onClick={handleDownloadExcel}
              className="user-list-download"
            >
              <FaFileArrowDown />
            </IconButton>
          </div>
        </div>
        <TableContainer
          sx={{ maxHeight: 440 }}
          style={{ overflow: "scroll" }}
          className="user-table"
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{
              backgroundColor: "#fff",
              borderRadius: "25px",
              // padding: "10px",

            }}
          >
            <TableHead>

              <TableRow style={tablehead}>
                {tableheaddata.map((item) => {
                  return (
                    <TableCell
                      style={{
                        fontSize: "1.1rem",
                        color: "#3079bd",
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                      }}
                      key={item.id}
                    >
                      {item.value}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedUsers}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[4, 12, 20]}
          component="div"
          count={filteredUsers.length} // Set count to the length of filteredUsers
          rowsPerPage={rowsPerPage}
          page={currentPage} // Use updated currentPage based on filtered results
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      <Loader loading={loading} />
      <ConfirmModal
        open={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        title="Confirm Delete"
        message={`Are you sure you want to delete ${userToDelete?.userName}?`}
        confirmText="Delete"
        cancelText="Cancel"
        onConfirm={handleConfirmDelete}
      />

      {showTourModal &&
        <ConfirmModal
          open={showTourModal}
          onClose={handleCancelTour}
          title={"Page Tour"}
          message={"Would you like a tour of the page?"}
          confirmText={"Yes, show me around"}
          cancelText={"No, thanks"}
          onConfirm={handleRunTour}
        />
      }
    </>
  );
};

export default UserManagement;
