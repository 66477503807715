// import {useLocation} from "react-router-dom"
import React, { useEffect, useState } from "react";
import { GeneralTables } from "../ReportingDashboard/GeneralTables";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { inputfieldstyle1 } from "../../../styles";

function DataTableTables({ orgdept, orgplt, renderd, formate }) {

  const [selectedOption, setSelectedOption] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (renderd) {
      const filtered = renderd;
      setFilteredData(filtered);
      filteredData.forEach((object, index) => { });
    }
  }, [renderd, formate]);

  const extractOptions = () => {
    let options = [];

    filteredData.forEach((entry) => {
      const yearOrMonth = entry.Year ? entry.Year : entry.Month;
      if (yearOrMonth && !options.includes(yearOrMonth)) {
        options.push(yearOrMonth);
      }
    });

    return options;
  };

  // Handle dropdown change
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // Function to filter data recursively
  const filterDataByFormat = (data, formate) => {
    let filtered = [];

    // Check if data is an array
    if (Array.isArray(data)) {
      // Iterate over each object in the array
      data.forEach((entry) => {
        // Recursively filter nested objects
        const innerFiltered = filterDataByFormat(entry, formate);
        filtered.push(...innerFiltered);
      });
    } else if (data && typeof data === "object") {
      // If data is an object, check if it has the Format property
      if (data.Format === formate) {
        filtered.push(data);
      } else {
        // Recursively search inside nested objects
        Object.values(data).forEach((value) => {
          const innerFiltered = filterDataByFormat(value, formate);
          filtered.push(...innerFiltered);
        });
      }
    }

    return filtered;
  };
  useEffect(() => {
    if (selectedOption) {
      filteredData.forEach((entry) => {
        const yearOrMonth = entry.Year ? entry.Year : entry.Month;
        if (yearOrMonth === selectedOption) {
        }
      });
    }
  }, [selectedOption, filteredData]);




  return (
    <div>
      {filteredData.length === 0 ? (
        <div>No previous data present</div>
      ) : (
        <div>
          <FormControl style={inputfieldstyle1}>
            <InputLabel>Select Month/Year</InputLabel>
            <Select
              value={selectedOption}
              onChange={handleChange}
              label="Select Month/Year"
            >
              <MenuItem value="">None</MenuItem>
              {extractOptions().map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedOption && (
            <div>
              {filteredData
                .filter(
                  (entry) =>
                    entry.Year === selectedOption ||
                    entry.Month === selectedOption
                )
                .map((detail, index) => (

                  <div key={index}>
                    {/* {// console.log("detail", detail)} */}
                    {/* Render each filtered entry */}
                    <GeneralTables
                      orgplt={orgplt}
                      orgdept={orgdept}
                      setTableFromat={detail.Format}
                      frequency={detail?.Month || detail?.Year}
                      tableRendering={detail.KPIInput}
                      tableDraftRendering={detail.KPIInput}
                      status={detail.KPIStatus}
                      kpicode={detail.KPICode}
                      reviewedBy={detail.reviewedBy}
                      comments={detail.comments}
                    />
                  </div>
                ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default DataTableTables;
