import axios from "axios";
import { baseUrl } from "../baseurl";
import { api } from "./HandleError";


export const getUserAssignments = (encryptData) => {
  return api.post(
    `/getUserAssignments`,
    { encryptedData: encryptData },
    // { withCredentials: true }
  );
};

export const getAssignmentKPIData = (encryptData) => {
  return api.post(
    `/getAssignmentKPIData`,
    { encryptedData: encryptData },
    // { withCredentials: true }
  );
};

export const captureData = (encryptedKPIData) => {
  return api.post(
    `/captureData`,
    { encryptedKPIData: encryptedKPIData },
    // { withCredentials: true }
  );
};

export const countryCodes = () => {
  return axios.get(`${baseUrl}/countryCodes`, {
    withCredentials: true
  });
};

export const getStatesAndDistricts = () => {
  return axios.get(`${baseUrl}/getStatesAndDistricts`, {
    withCredentials: true
  });
};

export const getOrganizationImage = (organizationEmail) => {
  return api.post(
    `/getOrganizationImage`,
    { organizationEmail },
    // { withCredentials: true }
  );
};
