import { Box, Button, Container, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import DataTableTables from "../../pages/ClientSide/ExtraFiles/DataTableTables";
import { useLocation, useNavigate } from "react-router-dom";
import { backBtnfixed } from "../../styles";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CommentIcon from '@mui/icons-material/Comment';
import { CommentPopUp } from "../../dashboard/pages/SPOCDataReview/CommentPopUp";



const PopUpDialog = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const popupProps = location.state;





  // // console.log("popupProps", popupProps);


  const handleBack = () => {
    navigate(-1);
  };



  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {popupProps.responseSaved &&
            popupProps?.responseSaved[0]?.CategoryTags &&
            popupProps?.responseSaved[0]?.CategoryTags.split(":")[1]}
          {popupProps.responseDrafted &&
            popupProps?.responseDrafted[0]?.CategoryTags &&
            popupProps?.responseDrafted[0]?.CategoryTags.split(":")[1]}
          &nbsp; (Previous Data)
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ my: 2 }}>
          {popupProps.responseSaved && (
            <Typography>
              1. {popupProps?.responseSaved[0]?.KPIdata}{" "}
              {popupProps?.responseSaved[0]?.KPICode}
            </Typography>
          )}
          {popupProps.responseDrafted && (
            <div style={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
              justifyContent: 'space-between'
            }}>
              <Typography sx={{ flex: "1" }}>
                1. {popupProps?.responseDrafted[0]?.KPIdata}{" "}
                {popupProps?.responseDrafted[0]?.KPICode}
              </Typography>

              {/* <div style={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
                justifyContent: 'center'
              }}>

                {detail.Description && (
                <Tooltip title="Guide">
                  <IconButton
                    disabled={detail.Description === undefined}

                    className="thumbdown"
                  onClick={() =>
                    handleOpenDecsModal(detail, i)
                  }
                  >
                    <MenuBookIcon style={{ color: "rgb(69, 182, 83)" }}
                    />{" "}
                  </IconButton>
                </Tooltip>
                )}{" "}
                <Tooltip title="Comment">
                  <span>
                    <IconButton
                      className="thumbdown"
                      onClick={() => handleCommentBoxOpen(popupProps?.responseDrafted[0])}
                    disabled={(formDataWithKpiStatus[detail.KPICode] ===
                      "approved") || (formDataWithKpiStatus[detail.KPICode] ===
                        "unfilled") || (formDataWithKpiStatus[detail.KPICode] ===
                          "saved")}
                    >
                      <CommentIcon style={{ color: "#3079bd" }} />
                    </IconButton>
                  </span>
                </Tooltip>
              </div> */}
            </div>
          )}
        </Box>
        <DataTableTables
          orgdept={popupProps.orgdept}
          orgplt={popupProps.orgplt}
          renderd={
            popupProps?.responseSaved
              ? popupProps.responseSaved
              : popupProps.responseDrafted
          }
          formate={popupProps.tdata}
        />
      </Box>
      <Box sx={{ mt: 4 }}>
        <Button style={backBtnfixed} onClick={handleBack}>
          Back
        </Button>
      </Box>

    </Container>
  );
};

export default PopUpDialog;
