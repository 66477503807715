import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Divider, Grid, TextField } from "@mui/material";
import "./organization.css";
import { buttoncontaindedfixed, inputfieldstyle1 } from "../../styles";
import Hamburger from "../../components/Navbars/Hamburger.js";
import { decryptData } from "../../services/crypto.js";
import { fetchAllOrganizations } from "../../services/OrganizationManagemnetApi.js";
import { toast } from "react-toastify";
import MultiStepAssignManager from "../../pages/MultiStepAssignManager/MultiStepAssignManager.jsx";
import OrganizationCards from "../AssignmentManager/OrganizationCards.jsx";
import { Loader } from "../Loader/Loader.jsx";
import { STATUS } from "react-joyride";
import { getLoginAttempts } from "../../services/loggedIn.js";
import { JoyRide } from "../../Joyride.jsx";
import ThankyouModal from "../PopUpDialog/ThankyouModal.jsx";
import { setRunTour, setShowTourModal, setTourCompleted } from "../../redux/slice/TourGuideSlice.js";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../PopUpDialog/ConfirmModal.jsx";

export const Organizations = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { showTourModal, tourCompleted } = useSelector((state) => state.tourGuide);


  const selectedOption = location.state?.selectedOption;
  const selectedData0 = location.state?.selectedData;
  const selectedCategories = location.state?.selectedCategories;
  const selectedSubcategories = location.state?.selectedSubcategories;
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [org, setOrg] = useState({
    organizationEmail: "",
    name: "",
  });
  const [search, setSearch] = useState("");
  const [selectedOrg, setSelectedOrg] = useState("");
  const [selectedFullOrgData, setSelectedFullOrgData] = useState();
  const [filtereOrgData, setFilteredOrgData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const loginAttempts = getLoginAttempts();


  useEffect(() => {
    // Check if it's the user's first login
    if (loginAttempts <= 3) {
      // setRunTour(true);
      dispatch(setShowTourModal(false))
      dispatch(setTourCompleted(false))
    }
  }, []);

  const handleRunTour = () => {
    dispatch(setRunTour(true))
    dispatch(setShowTourModal(false))
  }

  const handleCancelTour = () => {
    dispatch(setShowTourModal(false))
  }

  const steps = [
    {
      target: ".org-select-cards",
      content: "Select an organization for assignment",
      disableBeacon: true,
    },
    {
      target: ".org-continue-btn",
      content: "Continue to go to next page",
    },
  ];


  useEffect(() => {
    if (location.state?.org?.org) {
      const organizationName = location.state?.org?.org?.name;
      // console.log("filtereOrgData", filtereOrgData);

      const organizationObject = filtereOrgData.find(
        (org) => org.organizationName === organizationName
      );

      // console.log("organizationObject", organizationObject);


      if (organizationObject) {
        setOrg({
          organizationEmail: organizationObject.organizationEmail,
          name: organizationObject.organizationName,
        });
        setSelectedOrg(organizationObject.organizationEmail);
      }
    }
  }, [location.state, filtereOrgData]);

  useEffect(() => {
    const fetchOrganizations = async () => {
      setLoading(true);
      try {
        // Execute both API calls simultaneously
        const organizationsResponse = await fetchAllOrganizations();
        const decryptedData = decryptData(organizationsResponse.data);
        const parsedData = JSON.parse(decryptedData);

        const envintIndex = parsedData.unmarshalledData.findIndex(
          (org) => org.organizationName === "Envint"
        );
        // swap the first element with the envint element
        if (envintIndex !== -1) {
          const temp = parsedData.unmarshalledData[0];
          parsedData.unmarshalledData[0] =
            parsedData.unmarshalledData[envintIndex];
          parsedData.unmarshalledData[envintIndex] = temp;
        }

        // console.log("parsedData.unmarshalledData", parsedData.unmarshalledData);
        const filteredOrg = parsedData?.unmarshalledData.filter((item) => item.deleteStatus !== true)
        // console.log("filteredOrg", filteredOrg);


        setFilteredOrgData(filteredOrg);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrganizations();
  }, []);

  function handleSelectChange(selectedOrg) {
    // // console.log("selectedOrg", selectedOrg);

    setOrg({
      organizationEmail: selectedOrg.organizationEmail,
      name: selectedOrg.organizationName,
    });
    setSelectedOrg(selectedOrg.organizationEmail);
    setSelectedFullOrgData(selectedOrg);
  }

  function handleButtonClick() {
    const { organizationEmail, name } = org;

    if (!organizationEmail && !name) {
      return toast.error("Select at least one Organization");
    }

    if (selectedFullOrgData?.startOfReporting === undefined || null) {
      // return setShowModal(true);
    }
    navigate("/assignment/select-priority", {
      state: {
        org: org,
        selectedOption: selectedOption,
        selectedData: selectedData0,
        selectedCategories: selectedCategories,
        selectedSubcategories: selectedSubcategories,
        responseData: location.state?.responseData,
        scorecard: location.state?.scorecard,
        changedFrequencies: location.state?.changedFrequencies,
        checkedRows: location.state?.checkedRows,
        selectedFrequencies: location.state?.selectedFrequencies,
        checkedAll: location.state?.checkedAll,
        frameworkFilter: location.state?.frameworkFilter,
        // selectedCount: location.state?.selectedCount,
        selectedUsers: location.state?.selectedUsers,
      },
    });
  }

  const step = 0;
  // // // console.log("org", org);
  if (loading) {
    return <Loader loading={loading} />;
  }

  const handleNaviagteToEditOrg = () => {
    if (selectedFullOrgData) {
      navigate("/organization/update-org", {
        state: {
          user: selectedFullOrgData,
        },
      });
      setShowModal(false);
    }
  };

  return (
    <div className="add-container">
      <Hamburger />
      <JoyRide
        steps={steps}
      />
      <div>
        <MultiStepAssignManager step={step} />
      </div>
      <div className="org-container">
        <Box className="org-card" variant="outlined">
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <h3 className="frame_heading" style={{ width: "100%" }}>
              Select Organization
            </h3>
            <TextField
              id="outlined-basic"
              label="Search Organization"
              variant="outlined"
              style={inputfieldstyle1}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
          <Divider />
          <br />
          <div className="org-select-cards">
            <Grid container spacing={3}>
              {filtereOrgData
                ?.filter(
                  (org) =>
                    org.organizationName
                      ?.toLowerCase()
                      .includes(search.toLowerCase()) ||
                    org.organizationEmail?.includes(search.toLowerCase())
                )
                .map((org, index) => (
                  <Grid item xs={12} sm={6} md={4} key={org.organizationEmail}>
                    <OrganizationCards
                      handleSelectChange={handleSelectChange}
                      org={org}
                      index={index}
                      selectedOrg={selectedOrg}
                    />
                  </Grid>
                ))}
            </Grid>
          </div>
          <Button
            style={buttoncontaindedfixed}
            onClick={handleButtonClick}
            className="org-continue-btn"
          >
            Continue
          </Button>
        </Box>
      </div>

      {showModal && (
        <ThankyouModal
          closePopup={handleNaviagteToEditOrg}
          openPopup={showModal}
          handleNavigate={handleNaviagteToEditOrg}
          message={"Please Add Start of Reporting Before Assigning the KPI's"}
          title={"Add Start of Reporting"}
          confirmText={"OK"}
        />
      )}

      {showTourModal &&
        <ConfirmModal
          open={showTourModal}
          onClose={handleCancelTour}
          title={"Page Tour"}
          message={"Would you like a tour of the page?"}
          confirmText={"Yes, show me around"}
          cancelText={"No, thanks"}
          onConfirm={handleRunTour}
        />
      }
    </div>
  );
};
