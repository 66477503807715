import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { cancelBtn, okBtn } from "../../styles";

const SimpleModal = ({
  closeSimplePopup,
  openPopup,
  message,
  draftMessage,
  closePopup,
}) => {
  // // console.log("message", message.slice(0, 9));
  return (
    <Dialog
      //   TransitionComponent={Transition}
      onClose={closeSimplePopup ? closeSimplePopup : closePopup}
      aria-labelledby="customized-dialog-title"
      open={openPopup}
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {/* <h5 style={{ fontFamily: "Poppins, sans-serif", color: "grey" }}>
          Submitted Successfully
        </h5> */}
        <IconButton
          edge="end"
          backgroundColor="inherit"
          onClick={!message.includes("0") ? closeSimplePopup : closePopup}
          aria-label="close"
          sx={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ width: "450px", textAlign: "center" }}>
        <h1>
          {!message.includes("0")
            ? message.slice(0, 9)
            : draftMessage.slice(0, 9)}
        </h1>
        <h2>
          {!message.includes("0") ? message.slice(9) : draftMessage.slice(9)}
        </h2>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: "10px 20px",
        }}
      >
        {/* <Button
          onClick={!message.includes("0") ? closeSimplePopup : closePopup}
          style={cancelBtn}
        >
          Cancel
        </Button> */}
        <Button
          onClick={!message.includes("0") ? closeSimplePopup : closePopup}
          style={okBtn}
        >
          ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SimpleModal;
