import { Button, Divider } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { FaRegUserCircle } from "react-icons/fa";
import PersonIcon from "@mui/icons-material/Person";
import { getUserName, getUserRole } from "../../services/loggedIn";
import { useEffect, useState } from "react";
import { Profile } from "../../dashboard/pages/SPOCDataReview/Initials";
import TourBtn from "../TourBtn/TourBtn";

export const ProfileIcon = ({ handleLogout }) => {
  const [name, setName] = useState([]);
  const userName = getUserName();

  useEffect(() => {
    if (userName) {
      const nameParts = userName.split(" ");
      setName(nameParts);
    }
  }, [userName]);

  return (
    <>
      {/* <div
          style={{
            width: "45px",
            height: "45px",
            borderRadius: "50%",
            background: "rgb(220 237 227)",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "35px",
              height: "35px",
              borderRadius: "50%",
              background: "#3079bd",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1.2rem",
              textTransform: "uppercase",
            }}
          >
            {name.length > 1 ? name[0].charAt(0) + name[name.length - 1].charAt(0) : name[0]?.charAt(0) || ""}
          </div>
        </div> */}
      <Profile name={userName.split(" ")[0]} />
      <div className="profile-dropdown">
        <div className="iconName">
          <PersonIcon style={{ fontSize: "1.5rem", color: "darkgray" }} />
          <span style={{ textAlign: "left" }}>{name.join(" ")} </span>
        </div>
        <div className="iconName">
          <FaRegUserCircle style={{ fontSize: "1.5rem", color: "darkgray" }} />
          <span style={{ textAlign: "left" }}>{getUserRole()} </span>
        </div>
        <Divider />
        <Button
          fullWidth
          variant="contained"
          sx={{
            backgroundColor: "#45b653",
            color: "white",
            textTransform: "capitalize",
            fontSize: "1rem",
            marginTop: "20px",
          }}
          className="logout-button"
          onClick={handleLogout}
        >
          <LogoutIcon sx={{ marginRight: "6px" }} />
          Logout
        </Button>
      </div>
    </>
  );
};
