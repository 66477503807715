import React, { useEffect, useState } from "react";
import axios from "axios";
import { Backdrop, Table, TableContainer } from "@mui/material";
import { baseUrl } from "../../../baseurl";
import { FieldListTable } from "../../../components/Tables/Field_list";
import { AddMoreTable } from "../../../components/Tables/Add_more";
import { MatrixTable } from "../../../components/Tables/Matrix";
import encrypt, { decryptData } from "../../../services/crypto";
import { Loader } from "../../../components/Loader/Loader";

export const GeneralTables = ({
  orgplt,
  orgdept,
  setTableFromat,
  frequency,
  setKpiinputdata,
  tableRendering,
  tableDraftRendering,
  status,
  kpicode,
  reviewedBy,
  isreviewed,
  comments
}) => {
  // // // console.log("setTableFromat", setTableFromat);
  // // console.log("setKpiinputdata gene", setKpiinputdata);
  // // console.log("tableRendering from general tables", tableRendering);
  // // console.log("tableDraftRendering from general tables", tableDraftRendering);
  // // // console.log("kpicode", kpicode);
  // // console.log("1", orgplt, orgdept, setTableFromat);

  // // console.log("comments", comments);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableType, setTableType] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = window.localStorage.getItem("token");
        const headers = {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        };

        const requestData = {
          tableNumber: setTableFromat + ".",
        };

        // // console.log("requestData", requestData);

        const encryptData = encrypt({ requestData });

        const response = await axios.post(
          `${baseUrl}/gettables`,
          { encryptedData: encryptData },
          {
            withCredentials: true,
          }
        );

        // // console.log("response", response);

        const decryptedData = decryptData(response.data.data);
        const parsedData = JSON.parse(decryptedData);
        // // console.log("parsedData------", parsedData);

        setTableData(parsedData);
        setTableType(parsedData[0].Type);
        setLoading(false);
      } catch (error) {
        // console.log("Error fetching data:", error);
        setLoading(false);
        setError(true);
      }
    };

    fetchData();
  }, [setTableFromat]);



  // // console.log("tableType", tableType);
  // // console.log("======", error);

  return (
    <>
      {loading && <Loader loading={loading} />}
      <TableContainer>
        {error ? (
          <div
            style={{
              backgroundColor: "#FF6969",
              borderRadius: "8px",
              margin: "20px",
              padding: "10px",
              color: "white",
            }}
          >
            Trouble viewing table, please contact administrator
          </div>
        ) : (
          tableType && (
            <Table
              style={{
                borderRadius: "30px",
                background: "#f9f8f9",
                margin: "1rem 0",
                padding: "1rem",
              }}
            >
              {tableType && tableType === "Field-list" ? (
                <FieldListTable
                  orgplt={orgplt}
                  orgdept={orgdept}
                  setTableFromat={setTableFromat}
                  tableData={tableData}
                  setKpiinputdata={setKpiinputdata}
                  tableRendering={tableRendering}
                  tableDraftRendering={tableDraftRendering}
                  frequency={frequency}
                  status={status}
                  kpicode={kpicode}
                  reviewedBy={reviewedBy}
                  isreviewed={isreviewed}
                  comments={comments}
                />
              ) : tableType && tableType === "Add more" ? (
                <AddMoreTable
                  orgplt={orgplt}
                  orgdept={orgdept}
                  setTableFromat={setTableFromat}
                  tableData={tableData}
                  setKpiinputdata={setKpiinputdata}
                  tableRendering={tableRendering}
                  tableDraftRendering={tableDraftRendering}
                  frequency={frequency}
                  status={status}
                  kpicode={kpicode}
                  reviewedBy={reviewedBy}
                  isreviewed={isreviewed}
                  comments={comments}
                />
              ) : tableType && tableType === "Matrix" ? (
                <MatrixTable
                  orgplt={orgplt}
                  orgdept={orgdept}
                  setTableFromat={setTableFromat}
                  tableData={tableData}
                  setKpiinputdata={setKpiinputdata}
                  tableRendering={tableRendering}
                  tableDraftRendering={tableDraftRendering}
                  status={status}
                  frequency={frequency}
                  kpicode={kpicode}
                  reviewedBy={reviewedBy}
                  isreviewed={isreviewed}
                  comments={comments}
                />
              ) : (
                <FieldListTable
                  orgplt={orgplt}
                  orgdept={orgdept}
                  setTableFromat={setTableFromat}
                  tableData={tableData}
                  setKpiinputdata={setKpiinputdata}
                  tableRendering={tableRendering}
                  tableDraftRendering={tableDraftRendering}
                  frequency={frequency}
                  status={status}
                  kpicode={kpicode}
                  reviewedBy={reviewedBy}
                  isreviewed={isreviewed}
                  comments={comments}
                />
              )}
            </Table>
          )
        )}
      </TableContainer>
    </>
  );
};
