import React, { useCallback, useEffect, useMemo, useState } from "react";
import { VariableSizeList as List } from "react-window";
import { useLocation, useNavigate } from "react-router-dom";
import Hamburger from "../Navbars/Hamburger";
import b from "./Img/1000020928.png";
import a from "./Img/1000020929.png";
import "./Home.css";
import {
  Box,
  Button,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  backBtnfixed,
  buttonfixed,
  frameworksimgs,
  frameworktable,
} from "../../styles";
import { SelectionDetails } from "../KPI Table/SelectionDetails";
import { UserDetail } from "../KPI Table/UserDetail";
import { ArrowFilterDropdown } from "../KPI Table/ArrowFilterDropdown";
import { FramerScroll } from "../FramerScroll/FramerScroll";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import MultiStepAssignManager from "../../pages/MultiStepAssignManager/MultiStepAssignManager";
import { STATUS } from "react-joyride";
import { getLoginAttempts } from "../../services/loggedIn";
import { JoyRide } from "../../Joyride";
import AutoSizer from "react-virtualized-auto-sizer";
import { useDispatch, useSelector } from "react-redux";
import { setRunTour, setShowTourModal, setTourCompleted } from "../../redux/slice/TourGuideSlice";
import ConfirmModal from "../PopUpDialog/ConfirmModal";

let data = "";

const SelectKpis = () => {
  const location = useLocation();
  const responseData = location.state.responseData;
  const selectedData = location.state?.selectedData;
  const selectedCategories = location.state?.selectedCategories;
  const selectedSubcategories = location.state?.selectedSubcategories;
  const requestData = location.state?.selectedOption;
  const json = {
    priority: requestData,
  };
  const prioritiesJson = {
    priority: json.priority,
  };

  const org_email = location.state?.org?.organizationEmail;
  const org = location.state?.org;
  const navigate = useNavigate();
  const uniqueFrameworks = ["GRI", "Multiple", "BRSR"];

  const totalKpiCount = responseData && responseData.length;
  const [showModal, setshowModal] = useState(false);
  const [search, setSearch] = useState("");
  const [changedFrequencies, setChangedFrequencies] = useState(
    location.state?.changedFrequencies || []
  );
  const [checkedRows, setCheckedRows] = React.useState(
    location.state?.checkedRows ||
    responseData.reduce((acc, item) => {
      acc[item.KPICode] = false;
      return acc;
    }, {})
  );

  const [selectedFrequencies, setSelectedFrequencies] = useState(
    location.state?.selectedFrequencies ||
    responseData.reduce((acc, item) => {
      acc[item.KPICode] = item.Frequency;
      return acc;
    }, {})
  );
  const [checkedAll, setCheckedAll] = React.useState(
    location.state?.checkedAll || false
  );
  const [frameworkFilter, setFrameworkFilter] = useState(
    location.state?.frameworkFilter || ""
  );
  const loginAttempts = getLoginAttempts();


  const dispatch = useDispatch();

  const { showTourModal } = useSelector((state) => state.tourGuide);

  useEffect(() => {
    // Check if it's the user's first login
    if (loginAttempts <= 3) {
      // setRunTour(true);
      dispatch(setShowTourModal(false))
      dispatch(setTourCompleted(false))
    }
  }, []);

  const handleRunTour = () => {
    dispatch(setRunTour(true))
    dispatch(setShowTourModal(false))
  }

  const handleCancelTour = () => {
    dispatch(setShowTourModal(false))
  }

  // Update checkedAll whenever checkedRows changes
  useEffect(() => {
    const allChecked = responseData.every(item => checkedRows[item.KPICode]);
    setCheckedAll(allChecked);
  }, [checkedRows, responseData]);

  const steps = [
    {
      target: ".kpi-table",
      content:
        "The table shows detailed description of each KPI (KPI Code, Frequency and Department under which it falls)",
      disableBeacon: true,
    },
    {
      target: ".search-kpi",
      content: "KPIs can be searched on the basis of KPI codes and KPIs",
      disableBeacon: true,
    },
    {
      target: ".kpi-check",
      content: "Mark the checkbox corresponding to KPI that is to be assigned",
    },
    {
      target: ".framework-filter-envint",
      content: "Filter KPIs on the basis of frameworks for assignment",
    },
    {
      target: ".frequency-filter-envint",
      content:
        "Frequency can be altered from the dropdowns,each KPI has a default frequency which is visible here",
    },
    {
      target: ".assign-kpi-btn",
      content: "Click to assign KPIs ",
    },
  ];


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const searchData = useMemo(() => {
    const filteredData = responseData.filter((item) => {
      const frameworkFilterMatch =
        !frameworkFilter ||
        (frameworkFilter === "Multiple" &&
          item.GRI_BRSR === true &&
          item.GRI !== "nan" &&
          item.BRSR !== "nan") ||
        (frameworkFilter === "GRI" &&
          item.GRI_BRSR === false &&
          item.GRI !== "nan" &&
          item.BRSR === "nan") ||
        (frameworkFilter === "BRSR" &&
          item.GRI_BRSR === false &&
          item.GRI === "nan" &&
          item.BRSR !== "nan");

      const searchMatch = search
        ? item.KPICode.toLowerCase().includes(search.toLowerCase()) ||
        item.KPIdata.toLowerCase().includes(search.toLowerCase())
        : true;

      return frameworkFilterMatch && searchMatch;
    });

    return filteredData.sort((a, b) => a.KPICode.localeCompare(b.KPICode));
  }, [responseData, frameworkFilter, search]);

  const handleCheckRow = useCallback((e, item) => {
    const checked = e.target.checked;
    const kpiCode = item.KPICode

    setCheckedRows((prev) => ({
      ...prev,
      [kpiCode]: checked,
    }));
  }, []);

  const handleCheckAll = useCallback(
    (e) => {
      const checked = e.target.checked;
      setCheckedAll(checked);
      setCheckedRows(
        responseData.reduce((acc, item) => {
          acc[item.KPICode] = checked;
          return acc;
        }, {})
      );
    },
    [responseData]
  );
  const selectedCount = useMemo(() => {
    return searchData.filter(item => checkedRows[item.KPICode]).length;
  }, [searchData, checkedRows]);


  const handleAssign = () => {
    const selectedData = responseData.filter(
      (item) => checkedRows[item.KPICode]
    );

    const updatedKPIs = {};

    selectedData.forEach((data) => {
      const kpiCode = data.KPICode;

      const selectedFrequency =
        selectedFrequencies[kpiCode] || "DefaultFrequency";
      const existingFrequency =
        changedFrequencies.find(
          (changedData) => Object.keys(changedData)[0] === kpiCode
        )?.[kpiCode]?.[0] || "DefaultFrequency";

      if (selectedFrequency !== existingFrequency) {
        // Include only the data with changed frequency
        if (!updatedKPIs[kpiCode]) {
          updatedKPIs[kpiCode] = [];
        }

        updatedKPIs[kpiCode].push(
          selectedFrequency,
          data.Department || "ChangedDepartment"
        );
      } else {
        // Include all checked instances with no frequency change
        if (!updatedKPIs[kpiCode]) {
          updatedKPIs[kpiCode] = [];
        }

        updatedKPIs[kpiCode].push(
          selectedFrequency,
          data.Department || "ChangedDepartment"
        );
      }
    });

    const finalData = Object.entries(updatedKPIs).map(([kpiCode, values]) => ({
      [kpiCode]: values,
    }));

    if (!finalData.length) {
      return toast.error("Select at least one KPI to Assign");
    }

    navigate("/assignment/review-dept-ou", {
      state: {
        data0: finalData,
        data: org_email,
        codes: Object.keys(updatedKPIs),
        org: org,
        prioritiesJson: prioritiesJson,
        selectedData: location.state?.selectedData,
        selectedCategories: selectedCategories,
        selectedSubcategories: selectedSubcategories,
        selectedOption: location.state?.selectedOption,
        responseData: responseData,
        scorecard: location.state?.scorecard,
        changedFrequencies: changedFrequencies,
        checkedRows: checkedRows,
        selectedFrequencies: selectedFrequencies,
        checkedAll: checkedAll,
        frameworkFilter: frameworkFilter,
        selectedCount: selectedCount,
        totalKpiCount: totalKpiCount,
        selectedUsers: location.state?.selectedUsers,
      },
    });
  };

  const handleFrameworkFilter = (filter) => {
    setFrameworkFilter(filter);
  };


  const handleSearch = debounce((value) => {
    setSearch(value.trim());
  }, 400);

  const handleClose = () => {
    setshowModal(false);
  };

  const handleFrequencyChange = useCallback((event, kpiCode) => {
    const selectedFrequency = event.target.value;

    setSelectedFrequencies((prev) => ({
      ...prev,
      [kpiCode]: selectedFrequency,
    }));

    const existingData = changedFrequencies.find(
      (data) => Object.keys(data)[0] === kpiCode
    );

    if (existingData) {
      // if the KPI code already exists in the list, update its frequency
      const updatedData = {
        [kpiCode]: [
          selectedFrequencies[kpiCode] || "DefaultFrequency",
          "Department", // Change to the desired department value
        ],
      };

      setChangedFrequencies((prev) => {
        const index = prev.findIndex(
          (data) => Object.keys(data)[0] === kpiCode
        );
        return [...prev.slice(0, index), updatedData, ...prev.slice(index + 1)];
      });
    } else {
      // if the KPI code doesn't exist in the list, add it
      const newData = {
        [kpiCode]: [
          selectedFrequencies[kpiCode] || "DefaultFrequency",
          "DefaultDepartment", // Change to the desired department value
        ],
      };

      setChangedFrequencies((prev) => [...prev, newData]);
    }
  }, []);

  const handleBack = () => {
    navigate("/assignment/select-framework", {
      state: {
        org: org,
        prioritiesJson: prioritiesJson,
        selectedData: selectedData,
        selectedCategories: selectedCategories,
        selectedSubcategories: selectedSubcategories,
        selectedOption: location.state?.selectedOption,
        responseData: responseData,
        scorecard: location.state?.scorecard,
        changedFrequencies: changedFrequencies,
        checkedRows: checkedRows,
        selectedFrequencies: selectedFrequencies,
        checkedAll: checkedAll,
        frameworkFilter: frameworkFilter,
        // selectedCount: selectedCount,
        // totalKpiCount:totalKpiCount,
        selectedUsers: location.state?.selectedUsers,
      },
    });
  };

  const step = 3;

  const Row = useCallback(
    ({ index, style }) => {
      const item = searchData[index];
      const { height, width } = style;

      return (
        <div style={style}>
          <TableRow style={{ height: height }}>
            <TableCell style={{ width: "70px" }}>
              <input
                type="checkbox"
                checked={checkedRows[item.KPICode] || false}
                onChange={(e) => handleCheckRow(e, item)}
              />
            </TableCell>
            <Tooltip
              arrow
              placement="top"
              TransitionProps={{ timeout: 500 }}
              title={`${item.CategoryTags.split(":").slice(0).join(" : ")}`}
            >
              <TableCell
                style={{ width: "100px" }}
                className={
                  item.CategoryTags.split(":")[0] === "Environment"
                    ? "env-bg"
                    : item.CategoryTags.split(":")[0] === "Social"
                      ? "blue1"
                      : item.CategoryTags.split(":")[0] === "Governance"
                        ? "pink"
                        : "esg"
                }
              >
                {item.KPICode}
              </TableCell>
            </Tooltip>
            <TableCell style={{ width: "100px" }}>
              {item.GRI_BRSR === false &&
                item.GRI !== "nan" &&
                item.BRSR === "nan" ? (
                <img src={a} alt="gri" style={frameworksimgs} />
              ) : item.BRSR !== "nan" && item.GRI !== "nan" ? (
                <>
                  <img src={b} alt="brsr" style={frameworksimgs} />
                  <img src={a} alt="gri" style={frameworksimgs} />
                </>
              ) : (
                <img src={b} alt="brsr" style={frameworksimgs} />
              )}
            </TableCell>
            <Tooltip title={`Pillar : ${item.CategoryTags.split(":")[0]}`}>
              <TableCell sx={{ width: "600px" }}>{item.KPIdata}</TableCell>
            </Tooltip>

            <TableCell
              sx={{ width: "100px" }}
              className="frequency-filter-envint"
            >
              <Select
                value={selectedFrequencies[item.KPICode]}
                onChange={(e) => handleFrequencyChange(e, item.KPICode)}
                style={{ width: "100px" }}
              >
                <MenuItem value="Annual">Annual</MenuItem>
                <MenuItem value="Month">Monthly</MenuItem>
              </Select>
            </TableCell>

            <TableCell sx={{ width: "260px", textAlign: "center" }}>
              {item.Department}
            </TableCell>
          </TableRow>
        </div>
      );
    },
    [
      searchData,
      checkedRows,
      handleCheckRow,
      selectedFrequencies,
      handleFrequencyChange,
    ]
  );

  const getItemSize = (index) => {
    const item = searchData[index];
    const baseHeight = 50;
    const extraHeight = Math.ceil(item.KPIdata.length / 70) * 20;
    return baseHeight + extraHeight;
  };

  const filteredKpiCount = useMemo(() => {
    return searchData.length;
  }, [searchData]);

  return (
    <>
      <Hamburger />
      <JoyRide
        steps={steps}

      />
      <FramerScroll />
      <div style={{ padding: "1rem" }}>
        <TableContainer
          style={{
            backgroundColor: "#f9f8f9",
            padding: "0 0.5rem",
            borderRadius: "20px",
            width: "100%",
          }}
          className="table-container-kpi"
        >
          <Box sx={{ marginTop: "20px" }}>
            <MultiStepAssignManager step={step} />
          </Box>

          <div className="header-box">
            <h3 className="frame_heading">Select KPI's</h3>
            <TextField
              id="outlined-basic"
              label="Search KPI Code and KPI"
              variant="outlined"
              className="search-kpi"
              style={{ marginRight: "30px" }}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          <Table
            className="kpi-table"
            stickyHeader
            aria-label="sticky table"
            style={{
              borderRadius: "30px",
              width: "fit-content",
              margin: "auto",
              tableLayout: "fixed",
            }}
          >
            <div>
              <div style={{ width: "100%" }}>
                <TableCell
                  style={{ width: "70px", padding: "6px" }}
                  className="kpi-check"
                >
                  <input
                    type="checkbox"
                    checked={checkedAll}
                    // checked={Object.values(checkedRows).every(Boolean)}
                    onChange={handleCheckAll}
                  />
                </TableCell>
                <TableCell
                  sx={{ fontFamily: "Poppins", width: "100px" }}
                  style={frameworktable}
                >
                  KPI Code
                </TableCell>
                <TableCell
                  sx={{ fontFamily: "Poppins", width: "100px" }}
                  style={frameworktable}
                  className="framework-filter-envint"
                >
                  Framework
                  <ArrowFilterDropdown
                    options={uniqueFrameworks}
                    onSelect={handleFrameworkFilter}
                  />
                </TableCell>
                <TableCell
                  style={{
                    width: "600px",
                    fontFamily: "Poppins",
                    backgroundColor: "white",
                    color: "#3079bd",
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  KPI
                </TableCell>
                <TableCell
                  sx={{ fontFamily: "Poppins", width: "100px" }}
                  style={frameworktable}
                >
                  Frequency
                </TableCell>
                <TableCell
                  sx={{
                    fontFamily: "Poppins",
                    width: "260px",
                    textAlign: "center",
                  }}
                  style={frameworktable}
                >
                  Department
                </TableCell>
              </div>
            </div>
            <TableBody>
              <AutoSizer disableHeight>
                {({ width }) => (
                  <List
                    height={400}
                    itemCount={searchData.length}
                    itemSize={getItemSize}
                    width={width}
                  >
                    {Row}
                  </List>
                )}
              </AutoSizer>
            </TableBody>
          </Table>
          <br />
          <SelectionDetails
            selectedCount={selectedCount}
            totalKpiCount={filteredKpiCount}
          />
          <Button onClick={handleBack} style={backBtnfixed}>
            Back
          </Button>
          <Button
            style={buttonfixed}
            onClick={handleAssign}
            className="assign-kpi-btn"
          >
            Assign KPIs
          </Button>
        </TableContainer>
        {showModal && (
          <div>
            <UserDetail handleClose={handleClose} data={data} />
          </div>
        )}
      </div>
      {showTourModal &&
        <ConfirmModal
          open={showTourModal}
          onClose={handleCancelTour}
          title={"Page Tour"}
          message={"Would you like a tour of the page?"}
          confirmText={"Yes, show me around"}
          cancelText={"No, thanks"}
          onConfirm={handleRunTour}
        />
      }
    </>
  );
};

export default SelectKpis;
