import React, { useEffect, useState } from "react";
import {
  Accordion,
  Box,
  AccordionSummary,
  AccordionDetails,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import DashboardLayout from "../../components/DashboradLayout/DashboardLayout";
import b from "../../../components/Org/Img/brsr.png";
import a from "../../../components/Org/Img/gri.png";
import {
  backBtnfixed,
  buttoncontaindedfixed,
} from "../../../styles";
import { Profile } from "./Initials";
import AcceptModal from "./AcceptModal";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getLoginAttempts,
  getUserEmail,
  getUserName,
} from "../../../services/loggedIn";
import { baseUrl } from "../../../baseurl";
import { Loader } from "../../../components/Loader/Loader";
import { GeneralTables } from "../../../pages/ClientSide/ReportingDashboard/GeneralTables";
import { toast } from "react-toastify";
import { STATUS } from "react-joyride";
import { JoyRide } from "../../../Joyride";
import approvereject from "../../../assets/images/approverejectarrow.png";
import UndoIcon from '@mui/icons-material/Undo';
import CommentIcon from '@mui/icons-material/Comment';
import { CommentPopUp } from "./CommentPopUp";
import InfoIcon from '@mui/icons-material/Info';
import ConfirmModal from "../../../components/PopUpDialog/ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import { setRunTour, setShowTourModal, setTourCompleted } from "../../../redux/slice/TourGuideSlice";

export const SPOCAccordion = () => {
  const [expanded, setExpanded] = useState(false);
  const [fdata, setFdata] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [filter, setFilter] = useState("all");
  const [showModal, setShowModal] = useState(false);
  const [approvedCount, setApprovedCount] = useState(0);
  const [rejectedCount, setRejectedCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [changedKPIList, setChangedKPIList] = useState([]);
  const loginAttempts = parseInt(getLoginAttempts());
  const [commentBoxOpen, setCommentBoxOpen] = React.useState(false);
  const [selectedKPI, setSelectedKPI] = useState(null);
  const [submittedComments, setSubmittedComments] = useState([]);
  const [draftComment, setDraftComment] = useState('');
  const [highlightedCardIndex, setHighlightedCardIndex] = useState(null);
  const [userEmail, setUserEmail] = useState();
  const [commentData, setCommentData] = useState();
  const [commentsArray, setCommentsArray] = useState();
  const [commentStore, setCommentStore] = useState({});

  const handleCommentBoxOpen = (theme, index) => {
    const kpiData = filteredData[theme][index];
    const kpiKey = `${kpiData.KPICode}-${kpiData.Year || kpiData.Month}`;

    setUserEmail(kpiData.capturedBy);
    setSelectedKPI({ theme, index });
    setHighlightedCardIndex(index);
    setDraftComment('');
    setCommentBoxOpen(true);
    setSubmittedComments(commentStore[kpiKey] || []);
    setCommentData(commentsArray[theme][index].comments);
  };

  const handleCommentBoxClose = () => {
    setCommentBoxOpen(false);
    setSelectedKPI(null);
    setHighlightedCardIndex(null);
    setDraftComment('');
  };

  const dispatch = useDispatch();

  const { showTourModal, runTour, tourCompleted } = useSelector((state) => state.tourGuide);



  useEffect(() => {
    // Check if it's the user's first login
    if (loginAttempts <= 3) {
      // setRunTour(true);
      dispatch(setShowTourModal(false))
      dispatch(setTourCompleted(false))
    }
  }, []);



  const handleRunTour = () => {
    dispatch(setRunTour(true))
    dispatch(setShowTourModal(false))
  }

  const handleCancelTour = () => {
    dispatch(setShowTourModal(false))
  }


  const steps = [
    {
      target: ".approveall",
      content: "Click to approve all the responses at once",
      disableBeacon: true,
    },
    {
      target: ".rejectall",
      content: "Click to reject all the responses at once",
    },
    {
      target: ".frame-fil",
      content: "Filter KPI responses on the basis of frameworks",
    },
    {
      target: ".spoc-accord",
      content: (
        <div>
          <p>
            Click on thumbs up icon to approve and thumbs down icon to reject
            individual KPIs
          </p>
          <img
            src={approvereject}
            style={{ width: "100%", height: "100%" }}
            alt="Approve_Reject"
          />
        </div>
      ),
      styles: {
        tooltip: {
          width: "50vw",
          height: "50vh",
          overflow: "auto",
        },
      },
    },
  ];


  const location = useLocation();
  const email = getUserEmail();
  const plantName = location.state.orgplt;
  const deptName = location.state.orgdept;
  const userName = getUserName();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAccordionData = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          `${baseUrl}/getAllSavedResponsesForReview`,
          {
            operationalUnit: plantName,
            department: deptName,
            spocEmail: email,
          },
          {
            withCredentials: true,
          }
        );
        setFdata(response.data.KPIData);
        setFilteredData(response.data.KPIData);
        setCommentsArray(response.data.KPIData);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchAccordionData();
  }, []);


  if (loading || !fdata || !filteredData) {
    return <Loader loading={loading} />;
  }
  var sum = 0;
  Object.keys(fdata).forEach((theme) => {
    sum = sum + fdata[theme].length;
  });
  const handleAccordionOpenChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const reviewername_email = {
    spocEmail: email,
    spocName: userName,
  };
  const handleSelectAll = () => {
    const updatedFdata = { ...fdata };
    let approvedCount = 0;

    Object.keys(updatedFdata).forEach((theme) => {
      updatedFdata[theme].forEach((detail, i) => {
        const frequency = updatedFdata[theme][i].Year
          ? { Year: updatedFdata[theme][i].Year }
          : { Month: updatedFdata[theme][i].Month };

        if (allApproved) {
          if (detail.KPIStatus === "approved") {
            updatedFdata[theme][i].KPIStatus = "saved";
            setChangedKPIList([]);
          }
        } else {
          if (detail.KPIStatus !== "approved") {
            updatedFdata[theme][i].KPIStatus = "approved";

            setChangedKPIList((prevList) => {
              const existingIndex = prevList.findIndex(
                (kpi) =>
                  kpi.KPICode === updatedFdata[theme][i].KPICode &&
                  ((kpi.frequency.Year && kpi.frequency.Year === frequency.Year) ||
                    (kpi.frequency.Month && kpi.frequency.Month === frequency.Month))
              );

              const updatedKPI = {
                KPICode: updatedFdata[theme][i].KPICode,
                KPIInput: updatedFdata[theme][i].KPIInput,
                department: deptName,
                KPIStatus: updatedFdata[theme][i].KPIStatus,
                Format: updatedFdata[theme][i].Format,
                operationalUnit: plantName,
                userEmail: updatedFdata[theme][i].capturedBy,
                captureDateTime: updatedFdata[theme][i].lastCaptured,
                frequency: frequency,
                isReviewed: true,
                reviewedBy: reviewername_email,
                isEdited: false,
                isCommented: updatedFdata[theme][i].isCommented || false,
                comments: [...(commentsArray[theme][i].comments || [])],
              };

              if (existingIndex !== -1) {
                const updatedList = [...prevList];
                updatedList[existingIndex] = updatedKPI;
                return updatedList;
              } else {
                return [...prevList, updatedKPI];
              }
            });
          }
        }

        // Count approved KPIs
        if (updatedFdata[theme][i].KPIStatus === "approved") {
          approvedCount++;
        }
      });
    });

    setFdata(updatedFdata);
    setApprovedCount(approvedCount);
    setRejectedCount(
      Object.values(updatedFdata)
        .flat()
        .filter((detail) => detail.KPIStatus === "drafted").length
    );
  };

  const handleRejectAll = () => {
    const updatedFdata = { ...fdata };
    let rejectedCount = 0;

    Object.keys(fdata).forEach((theme) => {
      fdata[theme].forEach((detail, i) => {
        const frequency = updatedFdata[theme][i].Year
          ? { Year: updatedFdata[theme][i].Year }
          : { Month: updatedFdata[theme][i].Month };
        if (allRejected) {
          if (detail.KPIStatus === "drafted") {
            updatedFdata[theme][i].KPIStatus = "saved"; // Reset to a default state
            setChangedKPIList([]);
          }
        } else {
          if (detail.KPIStatus !== "drafted") {
            updatedFdata[theme][i].KPIStatus = "drafted";
            setChangedKPIList((prevList) => {
              const existingIndex = prevList.findIndex(
                (kpi) =>
                  kpi.KPICode === updatedFdata[theme][i].KPICode &&
                  ((kpi.frequency.Year && kpi.frequency.Year === frequency.Year) ||
                    (kpi.frequency.Month && kpi.frequency.Month === frequency.Month))
              );

              const updatedKPI = {
                KPICode: updatedFdata[theme][i].KPICode,
                KPIInput: updatedFdata[theme][i].KPIInput,
                department: deptName,
                KPIStatus: updatedFdata[theme][i].KPIStatus,
                Format: updatedFdata[theme][i].Format,
                operationalUnit: plantName,
                userEmail: updatedFdata[theme][i].capturedBy,
                captureDateTime: updatedFdata[theme][i].lastCaptured,
                frequency: frequency,
                isReviewed: true,
                reviewedBy: reviewername_email,
                isEdited: false,
                isCommented: false,
                comments: updatedFdata[theme][i].comments ? updatedFdata[theme][i].comments : []
              };

              if (existingIndex !== -1) {
                // Update the existing KPI with the new status
                const updatedList = [...prevList];
                updatedList[existingIndex] = updatedKPI;
                return updatedList;
              } else {
                return [...prevList, updatedKPI];
              }
            });
          }
        }
        if (updatedFdata[theme][i].KPIStatus === "drafted") {
          rejectedCount++;
        }
      });
    });

    setFdata(updatedFdata);
    setRejectedCount(rejectedCount);
    setApprovedCount(
      Object.values(updatedFdata)
        .flat()
        .filter((detail) => detail.KPIStatus === "approved").length
    );
  };

  const handleNextClick = () => {
    setSubmittedComments([])
    setShowModal(true);

    // Merge fdata and filteredData into one array
    const allKPIs = [
      ...Object.values(fdata).flat(),
      ...Object.values(filteredData).flat(),
    ];

    // Use a Map to track unique KPIs by KPICode
    const uniqueKPIs = new Map();

    allKPIs.forEach((kpi) => {
      const frequency = kpi.Year || kpi.Month;
      const uniqueKey = `${kpi.KPICode}_${frequency}`;
      if (!uniqueKPIs.has(uniqueKey)) {
        uniqueKPIs.set(uniqueKey, kpi);
      }
    });

    // Now count approved and drafted KPIs from the uniqueKPIs
    const approvedCount = Array.from(uniqueKPIs.values()).filter(
      (kpi) => kpi.KPIStatus === "approved"
    ).length;
    const rejectedCount = Array.from(uniqueKPIs.values()).filter(
      (kpi) => kpi.KPIStatus === "drafted"
    ).length;
    setApprovedCount(approvedCount);
    setRejectedCount(rejectedCount);
  };

  const closePopup = () => {
    setShowModal(false);
  };

  const openPopup = () => {
    setShowModal(true);
  };

  const updateKPIStatus = (theme, index, status) => {
    const updatedFdata = { ...filteredData };
    const currentStatus = updatedFdata[theme][index].KPIStatus;

    if (currentStatus !== status) {
      if (currentStatus === "approved") {
        setApprovedCount(approvedCount - 1);
      } else if (currentStatus === "drafted") {
        setRejectedCount(rejectedCount - 1);
      }

      if (status === "approved") {
        setApprovedCount(approvedCount + 1);
      } else if (status === "drafted") {
        setRejectedCount(rejectedCount + 1);
      }

      const frequency = updatedFdata[theme][index].Year
        ? { Year: updatedFdata[theme][index].Year }
        : { Month: updatedFdata[theme][index].Month };

      updatedFdata[theme][index].KPIStatus = status;
      updatedFdata[theme][index].isReviewed = true;
      updatedFdata[theme][index].reviewedBy = reviewername_email;
      updatedFdata[theme][index].captureDateTime =
        updatedFdata[theme][index].lastCaptured;
      updatedFdata[theme][index].isCommented = updatedFdata[theme][index].isCommented || false;
      updatedFdata[theme][index].isEdited = false;

      const kpiKey = `${updatedFdata[theme][index].KPICode}-${updatedFdata[theme][index].Year || updatedFdata[theme][index].Month}`;
      if (status === "saved") {
        setSubmittedComments([]);
        setCommentStore((prev) => {
          const updatedStore = { ...prev };
          delete updatedStore[kpiKey]; // Remove temporary comments for this KPI
          return updatedStore;
        });

        setChangedKPIList((prevList) =>
          prevList.filter(
            (kpi) =>
              kpi.KPICode !== updatedFdata[theme][index].KPICode ||
              ((kpi.frequency.Year && kpi.frequency.Year !== frequency.Year) ||
                (kpi.frequency.Month && kpi.frequency.Month !== frequency.Month))
          )
        );
      }
      else {
        setChangedKPIList((prevList) => {
          const existingIndex = prevList.findIndex(
            (kpi) =>
              kpi.KPICode === updatedFdata[theme][index].KPICode &&
              ((kpi.frequency.Year && kpi.frequency.Year === frequency.Year) ||
                (kpi.frequency.Month && kpi.frequency.Month === frequency.Month))
          );
          const updatedKPI = {
            KPICode: updatedFdata[theme][index].KPICode,
            KPIInput: updatedFdata[theme][index].KPIInput,
            department: deptName,
            KPIStatus: status,
            Format: updatedFdata[theme][index].Format,
            operationalUnit: plantName,
            userEmail: updatedFdata[theme][index].capturedBy,
            captureDateTime: updatedFdata[theme][index].lastCaptured,
            frequency: frequency,
            isReviewed: true,
            isEdited: false,
            isCommented: updatedFdata[theme][index].isCommented || false,
            reviewedBy: reviewername_email,
            comments: [...(commentsArray[theme][index].comments || [])],
          };

          if (existingIndex !== -1) {
            const updatedList = [...prevList];
            updatedList[existingIndex] = updatedKPI;
            return updatedList;
          } else {
            return [...prevList, updatedKPI];
          }
        });
      }
      setFilteredData((prevFilteredData) => {
        const updatedFilteredData = { ...prevFilteredData };
        if (updatedFilteredData[theme]) {
          updatedFilteredData[theme][index] = {
            ...updatedFilteredData[theme][index],
            captureDateTime: updatedFdata[theme][index].lastCaptured,
            KPIStatus: status,
            isReviewed: true,
            reviewedBy: reviewername_email,
            isEdited: false,
          };
        }
        return updatedFilteredData;
      });
      setApprovedCount(
        Object.values(fdata)
          .flat()
          .filter((detail) => detail.KPIStatus === "approved").length
      );
      setRejectedCount(
        Object.values(fdata)
          .flat()
          .filter((detail) => detail.KPIStatus === "drafted").length
      );
    }
  };

  const plantName_deptName = `${plantName}_${deptName}`;
  const myObject = {
    [plantName_deptName]: changedKPIList,
  };

  const handleThumbsUp = (theme, i) => {
    updateKPIStatus(theme, i, "approved");
  };

  const handleThumbsDown = (theme, i) => {
    updateKPIStatus(theme, i, "drafted");
  };
  const handleNoAction = (theme, i) => {
    updateKPIStatus(theme, i, "saved")
  }

  const handleAddComment = (comment) => {
    if (selectedKPI) {
      const { theme, index } = selectedKPI;
      const kpiData = filteredData[theme][index];
      const kpiKey = `${kpiData.KPICode}-${kpiData.Year || kpiData.Month}`;

      if (comment.trim() === "") {
        toast.error("Cannot enter an empty comment");
        return;
      }

      const newComment = {
        comment: comment,
        captureDate: new Date().toLocaleDateString(),
        captureTime: new Date().toLocaleTimeString(),
      };

      // Use functional updates to ensure the latest state is accessed
      setCommentStore((prev) => {
        const updatedComments = {
          ...prev,
          [kpiKey]: [...(prev[kpiKey] || []), newComment]
        };

        // Immediately prepare the combined comments using the updated store
        const combinedComments = [
          ...(commentsArray[theme][index].comments || []),
          ...(updatedComments[kpiKey] || [])
        ];

        // Update the state for the drawer to reflect this
        setSubmittedComments((prev) => [...prev, { theme, index, newComment }]);

        setChangedKPIList((prevList) => {
          const updatedList = [...prevList];
          const frequency = filteredData[theme][index].Year
            ? { Year: filteredData[theme][index].Year }
            : { Month: filteredData[theme][index].Month };

          const existingIndex = updatedList.findIndex(
            (kpi) =>
              kpi.KPICode === filteredData[theme][index].KPICode &&
              ((kpi.frequency.Year && kpi.frequency.Year === frequency.Year) ||
                (kpi.frequency.Month && kpi.frequency.Month === frequency.Month))
          );

          const updatedKPI = {
            KPICode: filteredData[theme][index].KPICode,
            KPIInput: filteredData[theme][index].KPIInput,
            department: deptName,
            KPIStatus: filteredData[theme][index].KPIStatus,
            Format: filteredData[theme][index].Format,
            operationalUnit: plantName,
            userEmail: filteredData[theme][index].capturedBy,
            captureDateTime: filteredData[theme][index].lastCaptured,
            frequency: frequency,
            isReviewed: true,
            reviewedBy: reviewername_email,
            isEdited: false,
            isCommented: true,
            comments: combinedComments,
            commentedBy: email
          };

          if (existingIndex !== -1) {
            updatedList[existingIndex] = updatedKPI;
          } else {
            updatedList.push(updatedKPI);
          }

          return updatedList;
        });

        return updatedComments;
      });

      setDraftComment('');
    }
  };


  const handleEditComment = (index, updatedComment) => {
    const updatedComments = [...submittedComments];
    if (updatedComment.trim() === "") {
      return toast.error("Cannot enter empty comment")
    }
    else {
      updatedComments[index].comment = updatedComment;
      setSubmittedComments(updatedComments);
    }
  };

  const handleDeleteComment = (index) => {
    const updatedComments = submittedComments.filter((_, i) => i !== index);
    setSubmittedComments(updatedComments);
  };

  const handleFilterChange = (event) => {
    const selectedFilter = event.target.value;
    setFilter(selectedFilter);

    if (selectedFilter === "GRI") {
      const newFilteredData = {};
      Object.keys(fdata).forEach((theme) => {
        newFilteredData[theme] = fdata[theme].filter(
          (detail) => detail.GRI !== "nan" && detail.BRSR === "nan"
        );
      });
      setFilteredData(newFilteredData);
    } else if (selectedFilter === "BRSR") {
      const newFilteredData = {};
      Object.keys(fdata).forEach((theme) => {
        newFilteredData[theme] = fdata[theme].filter(
          (detail) => detail.BRSR !== "nan" && detail.GRI === "nan"
        );
      });
      setFilteredData(newFilteredData);
    } else if (selectedFilter === "Both") {
      const newFilteredData = {};
      Object.keys(fdata).forEach((theme) => {
        newFilteredData[theme] = fdata[theme].filter(
          (detail) => detail.GRI !== "nan" && detail.BRSR !== "nan"
        );
      });
      setFilteredData(newFilteredData);
    } else if (selectedFilter === "all") {
      setFilteredData(fdata);
    }
  };

  const handleSave = () => {
    setShowModal(false);
  };
  const themes = Object.keys(filteredData);
  let savedTablesMap = new Map(); // Use Map to track unique KPI + frequency

  themes.forEach((theme) => {
    if (fdata[theme]) {
      fdata[theme].forEach((detail) => {
        if (detail.Format.includes("Table")) {
          if (
            detail.KPIStatus === "saved" ||
            detail.KPIStatus === "approved" ||
            detail.KPIStatus === "drafted"
          ) {
            // Create a unique key using KPICode + Year/Month
            const frequency = detail.Year || detail.Month;
            const uniqueKey = `${detail.KPICode}-${frequency}`;

            // Check if this KPI + frequency is already added
            if (!savedTablesMap.has(uniqueKey)) {
              savedTablesMap.set(uniqueKey, detail);
            }
          }
        }
      });
    }
  });

  // Convert map values to an array
  let savedTables = Array.from(savedTablesMap.values());



  const handleApprovalRejectionSubmit = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${baseUrl}/listOfKPIsForApproveReject`,
        myObject,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        toast.success(response.data.successMessages);
        navigate("/dashboard/spoc-review");
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading(false);
    }
    setShowModal(false);
  };

  const allApproved = Object.values(filteredData)
    ?.flat()
    .every((detail) => detail.KPIStatus === "approved");
  const allRejected = Object.values(filteredData)
    ?.flat()
    .every((detail) => detail.KPIStatus === "drafted");

  const hasApprovedOrRejectedKPI = Object.values(fdata)
    .flat()
    .some(
      (detail) =>
        detail.KPIStatus === "approved" || detail.KPIStatus === "drafted"
    );

  const handleBack = () => {
    navigate("/dashboard/spoc-review");
  };

  // console.log("myObj",myObject)

  return (
    <>
      <DashboardLayout>
        <JoyRide
          steps={steps}
        />
        <div className="client-dashboard-container">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "30px 0",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h1 className="frame_heading">Approve/Reject KPIs for:</h1>
              <span
                style={{
                  color: "#45b653",
                  fontSize: "1.2rem",
                  fontWeight: 700,
                }}
              >{`${plantName}`}</span>
              <span
                style={{
                  color: "#45b653",
                  fontSize: "1.2rem",
                  fontWeight: 700,
                }}
              >{`${deptName}`}</span>
            </div>
            <FormControl
              variant="outlined"
              sx={{ minWidth: 200 }}
              className="frame-fil"
            >
              <InputLabel id="filter-label">Select Framework</InputLabel>
              <Select
                labelId="filter-label"
                id="filter-dropdown"
                value={filter}
                onChange={handleFilterChange}
                label="Select Framework"
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="GRI">GRI</MenuItem>
                <MenuItem value="BRSR">BRSR</MenuItem>
                <MenuItem value="Both">Both</MenuItem>
              </Select>
            </FormControl>
            <Box>
              <Button
                sx={{ textTransform: "none" }}
                style={{
                  color: "white",
                  padding: "5px 15px",
                  borderRadius: "20px",
                  // border: "1px solid gray",
                  margin: "0 10px",
                  background: "#45b653",
                }}
                onClick={handleSelectAll}
                className="approveall"
              >
                {allApproved ? "Unapprove All" : "Approve All"}
              </Button>
              <Button
                sx={{ textTransform: "none" }}
                style={{
                  color: "white",
                  padding: "5px 15px",
                  borderRadius: "20px",
                  margin: "0 10px",
                  background: "#ff9c00",
                }}
                className="rejectall"
                onClick={handleRejectAll}
              >
                {allRejected ? "Unselect" : "Reject All"}
              </Button>
            </Box>
          </Box>
          {themes?.map((theme, key) => {
            return (
              <Accordion
                sx={{ position: "relative" }}
                key={key}
                className="accordion spoc-accord"
                expanded={expanded === key}
                onChange={handleAccordionOpenChange(key)}
              >
                <AccordionSummary
                  sx={{ zIndex: 0 }}
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h4>{theme.replaceAll("_", " ")}</h4>
                  </Box>
                </AccordionSummary>
                {filteredData[theme].map((detail, i) => {
                  const isApproved = detail.KPIStatus === "approved";
                  const isRejected = detail.KPIStatus === "drafted";
                  const isNoAction = detail.KPIStatus === "saved";

                  return (
                    <AccordionDetails
                      key={i}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        margin: "10px",
                        borderRadius: "15px",
                        border: isApproved
                          ? "1px solid #45b653"
                          : isRejected
                            ? "1px solid red"
                            : "1px solid #ccc",
                        backgroundColor:
                          i === highlightedCardIndex
                            ? "#f4dbc1"
                            : isApproved
                              ? "#e3f4f4"
                              : isRejected
                                ? "#ffd1dc"
                                : "transparent",
                      }}
                      className={`accordion-content-${key === 0 ? "1" : ""}`}
                    >
                      <div style={{ width: "75%" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <p>
                            {i + 1}. {detail.KPIdata}
                          </p>
                          <p style={{ color: "#3079bd" }}>
                            {detail.Year ? (
                              <>Year : {detail.Year}</>
                            ) : (
                              <>Month :{detail.Month}</>
                            )}
                          </p>
                        </div>
                        {detail.Format === "Binary" ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            <label
                              style={{ display: "flex", margin: "0 20px 0 0" }}
                            >
                              <input
                                type="radio"
                                checked={detail.KPIInput === "yes"}
                                disabled
                              />
                              <span>Yes</span>
                            </label>
                            <label
                              style={{ display: "flex", margin: "0 20px 0 0" }}
                            >
                              <input
                                type="radio"
                                checked={detail.KPIInput === "no"}
                                disabled
                              />
                              <span>No</span>
                            </label>
                          </div>

                        ) : (detail.Format === "Open Text" || detail.Format === "Open text") ? (

                          <textarea
                            style={{
                              minWidth: "100%",
                              border: "1px solid #eee",
                              padding: "20px",
                              background: "#F0F0F0",
                              height: "100px",
                              resize: "none",
                            }}
                            name=""
                            id=""
                            disabled
                          >
                            {detail.KPIInput}
                          </textarea>
                        ) : detail.Format === "Percent" ||
                          detail.Format === "Currency" ||
                          detail.Format === "Number" ? (
                          <input
                            value={detail.KPIInput}
                            style={{ width: "20%" }}
                            disabled
                          />
                        ) : detail.Format === "Ratio" ? (
                          <div style={{ display: "flex", alignItems: 'center' }}>
                            <input
                              style={{ width: "20%" }}
                              value={detail.KPIInput.x}
                              disabled
                            />{" "}
                            &nbsp;:&nbsp;{" "}
                            <input
                              style={{ width: "20%" }}
                              value={detail.KPIInput.y}
                              disabled
                            />
                          </div>
                        ) : detail.Format.includes("Table") ? (
                          savedTables.map((table, key) => {
                            const frequency = table.Year || table.Month;
                            if (
                              table.Format === detail.Format &&
                              table.KPICode === detail.KPICode &&
                              (frequency === detail.Year || frequency === detail.Month)
                            ) {
                              return (
                                <React.Fragment key={key}>
                                  <div>
                                    <br />
                                    <GeneralTables
                                      setTableFromat={table.Format}
                                      frequency={frequency}
                                      tableRendering={table.KPIInput}
                                      status={table.KPIStatus}
                                      kpicode={table.KPICode}
                                      reviewedBy={email}
                                      isreviewed="yes"
                                    />
                                  </div>
                                </React.Fragment>
                              );
                            }
                          })

                        ) : (
                          ""
                        )}
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "10%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Profile
                              name={detail.capturedBy[0]}
                              email={detail.capturedBy}
                            />
                            <div style={{ marginLeft: '20px' }}>
                              {detail.GRI !== "nan" && detail.BRSR === "nan" ? (
                                <div style={{ maxWidth: "100px", margin: '5px 0' }}>
                                  <img src={a} alt="GRI" style={{ width: '30px', height: '30px', margin: '0 2px' }} />
                                </div>
                              ) : detail.BRSR !== "nan" && detail.GRI === "nan" ? (
                                <div style={{ maxWidth: "100px", margin: '5px 0' }}>
                                  <img src={b} alt="BRSR" style={{ width: '30px', height: '30px', margin: '0 2px' }} />
                                </div>
                              ) : detail.GRI !== "nan" && detail.BRSR !== "nan" ? (
                                <div style={{ display: 'flex', margin: '5px 0' }}>
                                  <img src={a} alt="GRI" style={{ width: '30px', height: '30px', margin: '0 2px' }} />
                                  <img src={b} alt="BRSR" style={{ width: '30px', height: '30px', margin: '0 2px' }} />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div>
                              {detail.Description && (
                                <>
                                  <InfoIcon
                                    style={{ margin: '0 10px', color: 'darkgray', fontSize: '30px', cursor: 'pointer' }}
                                  // onClick={() =>
                                  //   handleOpenDecsModal(detail, i)
                                  // }
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            margin: '20px 0'
                          }}
                        >
                          <Tooltip title="Approve">
                            <span>
                              <IconButton
                                onClick={() => handleThumbsUp(theme, i)}
                                disabled={isApproved}
                                className="thumbup"
                              >
                                <ThumbUpIcon style={{ color: "#45b653" }} />
                              </IconButton>
                            </span>
                          </Tooltip>
                          <Tooltip title="Reject">
                            <span>
                              <IconButton
                                onClick={() => handleThumbsDown(theme, i)}
                                disabled={isRejected}
                                className="thumbdown"
                              >
                                <ThumbDownIcon style={{ color: "#ff9c00" }} />
                              </IconButton>
                            </span>
                          </Tooltip>
                          <Tooltip title="Undo">
                            <span>
                              <IconButton
                                onClick={() => handleNoAction(theme, i)}
                                disabled={isNoAction}
                                className="thumbdown"
                              >
                                <UndoIcon style={{ color: "darkgray" }} />
                              </IconButton>
                            </span>
                          </Tooltip>
                          <Tooltip title="Comment">
                            <span>
                              <IconButton
                                className="thumbdown"
                                onClick={() => handleCommentBoxOpen(theme, i)}
                                disabled={isApproved || isNoAction}
                              >
                                <CommentIcon style={{ color: "#3079bd" }} />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    </AccordionDetails>
                  );
                })}
              </Accordion>
            );
          })}
          {hasApprovedOrRejectedKPI && (
            <Button onClick={handleNextClick} style={buttoncontaindedfixed}>
              Next
            </Button>
          )}
          {showModal && (
            <AcceptModal
              message="Please select the action to be performed."
              closePopup={closePopup}
              openPopup={openPopup}
              handleSave={handleSave}
              approvedCount={approvedCount}
              rejectedCount={rejectedCount}
              total={sum}
              handleApprovalRejectionSubmit={handleApprovalRejectionSubmit}
            />
          )}
          <Button type="button" onClick={handleBack} style={backBtnfixed}>
            Back
          </Button>

          {commentBoxOpen &&
            <CommentPopUp
              open={commentBoxOpen}
              handleClose={handleCommentBoxClose}
              onSubmitComment={handleAddComment}
              submittedComments={submittedComments}
              draftComment={draftComment}
              setDraftComment={setDraftComment}
              onDeleteComment={handleDeleteComment}
              onEditComment={handleEditComment}
              userEmail={userEmail}
              spocEmail={email}
              commentsArray={commentData}


            />}
        </div>
      </DashboardLayout>

      {showTourModal &&
        <ConfirmModal
          open={showTourModal}
          onClose={handleCancelTour}
          title={"Page Tour"}
          message={"Would you like a tour of the page?"}
          confirmText={"Yes, show me around"}
          cancelText={"No, thanks"}
          onConfirm={handleRunTour}
        />
      }
    </>
  );
};
