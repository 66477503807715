// Function to filter organizations
export async function filterOrganizationsForUser(
  role,
  email,
  orgData,
  userData
) {
  // // console.log("orgData", orgData);
  // // console.log("userData", userData);
  // // console.log("role", role);
  // // console.log("email", email);

  // Check if userData exists and has the Manager key
  if (!userData || !userData.Manager) {
    console.error(
      "User data is not in the expected format or no managers found"
    );
    return [];
  }

  if (!userData || !userData.Consultant) {
    console.error(
      "User data is not in the expected format or no consultant found"
    );
  }

  if (role === "Manager") {
    // Find the manager in the users data
    const manager = userData.Manager.find((user) => user.email === email);

    if (!manager) {
      // // console.log("Manager not found");
      return []; // If manager not found, return empty array
    }

    // Get the list of organizations the manager is assigned to
    const managerProjects = Array.isArray(manager.projects)
      ? manager.projects.map((project) => project.orgEmail)
      : [];

    // // console.log("managerProjects", managerProjects);

    // Filter the organization data
    return orgData.filter((user) =>
      managerProjects.includes(
        user.organizationEmail || user.organizationName || user.organization
      )
    );
  } else {
    // Find the consultant in the users data
    const consultant = userData.Consultant.find((user) => user.email === email);

    if (!consultant) {
      return []; // If consultant not found, return empty array
    }

    // Get the list of organizations the consultant is assigned to
    const consultantProjects = Array.isArray(consultant.projects)
      ? consultant.projects.map((project) => project.orgEmail)
      : [];

    // // console.log("consultantProjects", consultantProjects);

    // Filter the organization data
    return orgData.filter((user) =>
      consultantProjects.includes(
        user.organizationEmail || user.organizationName || user.organization
      )
    );
  }
}

export const getJoyrideSteps = (userRole, steps) => {
  let filteredSteps = steps;

  if (userRole === "Consultant") {
    filteredSteps = steps.filter(
      (step) =>
        step.target !== ".editOrg" &&
        step.target !== ".deleteOrg" &&
        step.target !== ".add-org-btn"
    );
  }

  if (userRole === "Manager") {
    filteredSteps = steps.filter(
      (step) => step.target !== ".deleteOrg"
    );
  }

  // Reset disableBeacon for the first step after filtering
  if (filteredSteps.length > 0) {
    filteredSteps[0].disableBeacon = true;
  }

  return filteredSteps;
};
