import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../baseurl";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, } from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";
import "../clientdashboard.css";
import { usereditbutton } from "../../../styles";
import ClientLayout from "../../../components/ClientLayout/ClientLayout";
import encrypt, { decryptData } from "../../../services/crypto";
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import { Loader } from "../../../components/Loader/Loader";
import { getUserEmail } from "../../../services/loggedIn";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { STATUS } from "react-joyride";
import { getLoginAttempts } from "../../../services/loggedIn";
import { JoyRide } from "../../../Joyride";
import ConfirmModal from "../../../components/PopUpDialog/ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import { setRunTour, setShowTourModal, setTourCompleted } from "../../../redux/slice/TourGuideSlice";

export const HistoryOverview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { showTourModal, runTour, tourCompleted } = useSelector((state) => state.tourGuide);


  const [userAssignmentList, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [transposed, setTransposed] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const json = { email: getUserEmail() };

      const encryptData = encrypt({ json });

      try {
        const response0 = await axios.post(
          `${baseUrl}/getUserAssignments`,
          { encryptedData: encryptData },
          { withCredentials: true }
        );

        const decryptedData = decryptData(response0.data.userAssignmentList);

        const parsedData = JSON.parse(decryptedData);

        setUserData(parsedData.groupData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const [plant, setSelectedPlant] = useState(null);
  const [department, setSelectedDepartment] = useState(null);
  const loginAttempts = getLoginAttempts();

  useEffect(() => {
    // Check if it's the user's first login
    if (loginAttempts <= 3) {
      // setRunTour(true);
      dispatch(setShowTourModal(false))
      dispatch(setTourCompleted(false))
    }
  }, []);

  const steps = [
    {
      target: ".client-dashboard-table",
      content: "The table displays the history of all KPIs that have been filled under a specific operational unit and department.",
      disableBeacon: true,
    },
    {
      target: ".view-btn",
      content: "Click here to view the responses for KPIs",
    },
  ];

  const handleRunTour = () => {
    dispatch(setRunTour(true))
    dispatch(setShowTourModal(false))
  }

  const handleCancelTour = () => {
    dispatch(setShowTourModal(false))
  }





  const handleEntryClick = (plant, department) => {
    setSelectedPlant(plant);
    setSelectedDepartment(department);
    navigate("/reporting/history/kpi-data", {
      state: {
        orgplt: plant,
        orgdept: department,
      },
    });
  };

  const handleTranspose = () => {
    setTransposed(!transposed);
  }

  const plants = Object.keys(userAssignmentList).sort();

  const allDepartments = Array.from(
    new Set(
      plants.flatMap((plant) =>
        userAssignmentList[plant].flatMap((obj) => Object.keys(obj))
      )
    )
  ).sort();

  if (loading) {
    return <Loader loading={loading} />;
  }

  return (
    <div style={{ padding: "20px" }}>
      <ClientLayout>
        <JoyRide steps={steps} />
        <div className="client-dashboard-container">
          <Box sx={{ display: "flex", gap: 2 }}>
            <h3 className="frame_heading">History Overview</h3>
            <br />
            <ul style={{ width: "fit-content" }} className="ul">
              <li>
                <div className="color-box esg"></div> Total KPI's
              </li>
            </ul>
          </Box>
          <br />
          <div className="client-dashboard-table-container">
            <TableContainer
              sx={{
                maxHeight: "440px",
              }}
            >
              <Table border="1" className="client-dashboard-table">

                <TableBody>
                  <TableRow
                    sx={{ position: "sticky", top: "0px", zIndex: 1, background: "#fff" }}
                  >
                    <TableCell style={{ color: "#3079bd" }}>
                      <Tooltip title="Transpose Table">
                        <Button
                          onClick={handleTranspose}
                          sx={{ textTransform: "none", marginLeft: '10px' }}
                          style={{ color: '#3079bd', fontSize: '1.1rem' }}
                        >
                          {!transposed ? "OU" : "Depts"} <SwapHorizontalCircleIcon style={{ margin: '0 5px', fontSize: '2rem' }} /> {!transposed ? "Depts" : "OU"}
                        </Button>
                      </Tooltip>
                    </TableCell>
                    {transposed ? plants.map((plant) => (
                      <TableCell style={{ color: "#3079bd", fontWeight: "900" }} key={plant}>
                        {plant}
                      </TableCell>
                    )) : allDepartments.map((department) => (
                      <TableCell style={{ color: "#3079bd", fontWeight: "900" }} key={department}>
                        {department}
                      </TableCell>
                    ))}
                  </TableRow>
                  {!transposed
                    ? plants.map((plant) => (
                      <TableRow key={plant}>
                        <TableCell>{plant}</TableCell>
                        {allDepartments.map((department, idx) => {
                          const plantDepartments = userAssignmentList[plant] || [];
                          const departmentStats = plantDepartments.find(
                            (obj) => obj.hasOwnProperty(department)
                          );
                          const hasDepartment = !!departmentStats;

                          return (
                            <TableCell key={idx}>
                              {hasDepartment ? (
                                <>
                                  <p>
                                    <table
                                      style={{
                                        display: "flex",
                                        fontSize: "15%",
                                      }}
                                    >
                                      <tr>
                                        <td style={{ borderRadius: '50%', background: '#E3F4F4', width: '50px', height: '50px' }}>

                                          {departmentStats[department].total}
                                        </td>
                                      </tr>
                                      <tr>
                                      </tr>
                                    </table>
                                  </p>
                                  {departmentStats[department].total !==
                                    departmentStats[department].unfilled &&
                                    departmentStats[department].total !==
                                    departmentStats[department].drafted ? (
                                    <Tooltip title="View data">
                                      <Button
                                        onClick={() => handleEntryClick(plant, department)}
                                        style={usereditbutton}
                                        disabled={
                                          departmentStats[department].total ===
                                          departmentStats[department].unfilled
                                        }
                                        className="view-btn"
                                      >
                                        <VisibilityIcon />
                                      </Button>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="View data">
                                      <Button
                                        onClick={() => handleEntryClick(plant, department)}
                                        style={{
                                          textTransform: "capitalize",
                                          height: "30px",
                                          backgroundColor: "gray",
                                          color: "white",
                                        }}
                                        disabled
                                      >
                                        <VisibilityIcon />
                                      </Button>
                                    </Tooltip>
                                  )}
                                </>
                              ) : (
                                "N/A"
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))
                    : allDepartments.map((department) => (
                      <TableRow key={department}>
                        <TableCell>{department}</TableCell>
                        {plants.map((plant, idx) => {
                          const plantDepartments = userAssignmentList[plant] || [];
                          const departmentStats = plantDepartments.find(
                            (obj) => obj.hasOwnProperty(department)
                          );
                          const hasDepartment = !!departmentStats;

                          return (
                            <TableCell key={idx}>
                              {hasDepartment ? (
                                <>
                                  <p>
                                    <table
                                      style={{
                                        display: "flex",
                                        fontSize: "15%",
                                      }}
                                    >
                                      <tr>
                                        <td className="color esg">
                                          {departmentStats[department].total}
                                        </td>
                                      </tr>
                                      <tr>
                                      </tr>
                                    </table>
                                  </p>
                                  {departmentStats[department].total !==
                                    departmentStats[department].unfilled &&
                                    departmentStats[department].total !==
                                    departmentStats[department].drafted ? (
                                    <Tooltip title="View data">
                                      <Button
                                        onClick={() => handleEntryClick(plant, department)}
                                        style={usereditbutton}
                                        disabled={
                                          departmentStats[department].total ===
                                          departmentStats[department].unfilled
                                        }
                                      >
                                        <PreviewIcon />
                                      </Button>
                                    </Tooltip>
                                  ) : (
                                    "Unfilled"
                                  )}
                                </>
                              ) : (
                                "N/A"
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                </TableBody>

              </Table>
            </TableContainer>
          </div>
        </div>
      </ClientLayout>

      {showTourModal &&
        <ConfirmModal
          open={showTourModal}
          onClose={handleCancelTour}
          title={"Page Tour"}
          message={"Would you like a tour of the page?"}
          confirmText={"Yes, show me around"}
          cancelText={"No, thanks"}
          onConfirm={handleRunTour}
        />
      }
    </div>
  );
};
