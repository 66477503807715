import React, { useEffect, useState } from "react";
import "./LandingPage.css";
import { Link, useNavigate } from "react-router-dom";
import { FaUsers, FaWpforms } from "react-icons/fa6";
import { BsBuildings } from "react-icons/bs";
import Hamburger from "../../components/Navbars/Hamburger";
import { Loader } from "../../components/Loader/Loader";
import { getLoginAttempts } from "../../services/loggedIn";
import Joyride, { STATUS } from "react-joyride";
import { JoyRide } from "../../Joyride";
import { useDispatch, useSelector } from "react-redux";
import { setShowTourModal, setRunTour, setTourCompleted } from "../../redux/slice/TourGuideSlice";
import ConfirmModal from "../../components/PopUpDialog/ConfirmModal";

export const LandingPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { showTourModal, runTour, tourCompleted } = useSelector((state) => state.tourGuide);



  const [stepIndex, setStepIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const loginAttempts = parseInt(getLoginAttempts());
  // const [runTour, setRunTour] = useState(false)
  // const [tourCompleted, setTourCompleted] = useState(false)

  useEffect(() => {
    // Check if it's the user's first login
    if (loginAttempts <= 3) {
      // setRunTour(true);
      dispatch(setShowTourModal(true))
      dispatch(setTourCompleted(false))
    }
  }, []);

  const handleRunTour = () => {
    dispatch(setRunTour(true))
    dispatch(setShowTourModal(false))
  }

  const handleCancelTour = () => {
    dispatch(setShowTourModal(false))
  }


  const steps = [
    {
      target: "#navbar",
      content: "The menu items here correspond to the cards below.",
      disableBeacon: true,
    },
    {
      target: ".landing-page-container",
      content:
        "These cards provide quick access to different sections of the application.",
    },
    {
      target: ".link-box.blue",
      content: "Click on the Organization Management card to get started.",
    },

  ];

  // const disableScroll = () => {
  //   document.body.style.overflow = 'hidden';
  // };


  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // 2 seconds delay
    //     // Check if it's the user's first login
    //     const tourCompleted = localStorage.getItem("tourCompleted");
    //     // // // console.log("tour", tourCompleted);
    //     if (loginAttempts <= 1 && tourCompleted !== "true") {
    //       setRunTour(true);
    //     }
    return () => clearTimeout(timer);

  }, []);



  const handleCardClick = (path) => {
    // // // console.log("Navigating to:", path);
    navigate(path);
  };
  if (loading) {
    return <Loader loading={loading} />
  }

  return (
    <>
      <Hamburger />
      <JoyRide steps={steps} />
      <div className="landing-page-container">
        <div
          onClick={() => handleCardClick("/organization/management")}
          className="link-box blue"
        >
          <div className="circle">
            <BsBuildings className="circle-icon" />

            {/* <h3>Organization Management</h3> */}
          </div>
          <h4 className="landing-head">
            Organization <br /> Management
          </h4>
        </div>

        <Link to="/user/management" className="link-box green">
          <div className="circle">
            <FaUsers className="circle-icon" />
            {/* <h3>User Management</h3> */}
          </div>
          <h4 className="landing-head">User Management</h4>
        </Link>

        <Link to="/assignment/select-organization" className="link-box orange">
          <div className="circle">
            <FaWpforms className="circle-icon" />
            {/* <h3>Form Manager</h3> */}
          </div>
          <h4 className="landing-head">Assignment Manager</h4>
        </Link>
      </div>
      {showTourModal &&
        <ConfirmModal
          open={showTourModal}
          onClose={handleCancelTour}
          title={"Page Tour"}
          message={"Would you like a tour of the page?"}
          confirmText={"Yes, show me around"}
          cancelText={"No, thanks"}
          onConfirm={handleRunTour}
        />
      }
    </>
  );
};
