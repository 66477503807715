import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from "../baseurl";
import { clearData, getOrg, getUserRole, setLoginData } from "./loggedIn";
import ThankyouModal from "../components/PopUpDialog/ThankyouModal";
import { tokenValidate } from "./CommonApis";
import { Loader } from "../components/Loader/Loader";


const MainProtectedRoute = ({ element, routeType }) => {

  const [isValidToken, setIsValidToken] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showExpirationPopup, setShowExpirationPopup] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const orgName = getOrg();
  const userRole = getUserRole();

  const validateToken = async () => {
    try {
      const response = await tokenValidate();
      return response.data?.Token || false;
    } catch (error) {
      console.error("Error validating token:", error);
      if (error.response?.status === 403 || error.response?.status === 401) {
        // toast.error("Forbidden: Login Again");
        setShowExpirationPopup(true);
      }
      return false;
    }
  };

  const fetchUserData = async () => {
    try {
      const userResponse = await axios.get(`${baseUrl}/user`, {
        withCredentials: true,
      });
      if (userResponse.data.Token) {
        setLoginData(userResponse);
        return userResponse.data.Token;
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
    return false;
  };

  const handleSessionExpired = () => {
    setShowExpirationPopup(false);
    clearData();
    navigate("/", { replace: true });
  };

  useEffect(() => {
    const checkToken = async () => {
      try {
        const token = await validateToken();
        if (token) {
          const requiredItems = [
            "org",
            "userRole",
            "orgEmail",
            "countryCurrency",
            "loginAttempts",
            "userName",
            "email",
          ];
          const isLocalStorageEmpty = requiredItems.some((item) => {
            const value = localStorage.getItem(item);
            return !value || value === "undefined";
          });

          if (isLocalStorageEmpty) {
            const userToken = await fetchUserData();
            setIsValidToken(!!userToken);
            if (!userToken) setShowExpirationPopup(true);
          } else {
            setIsValidToken(true);
          }
        } else {
          setIsValidToken(false);
          setShowExpirationPopup(true);
        }
      } catch (error) {
        console.error("Error during token check:", error);
        setIsValidToken(false);
        setShowExpirationPopup(true);
      } finally {
        setIsLoading(false);
      }
    };

    checkToken();
  }, [location]);

  if (isLoading) {
    return <Loader loading={isLoading} />;
  }

  if (!isValidToken) {
    return (
      <ThankyouModal
        openPopup={showExpirationPopup}
        closePopup={() => setShowExpirationPopup(false)}
        handleNavigate={handleSessionExpired}
        message={"Your session has expired. Please login again."}
        title={"Session Expired"}
        confirmText={"Login Again"}
      />
    );
  }

  switch (routeType) {
    case "envintUser":
      return isValidToken && orgName === "Envint"
        ? element
        : navigate("/", { replace: true });
    case "clientUser":
      if (isValidToken && orgName !== "Envint" && userRole !== "SPOC" && userRole !== "Management") {
        return element;
      } else if (isValidToken && orgName !== "Envint" && (userRole === "SPOC" || userRole === "Management")) {
        navigate("/dashboard/home", { replace: true });
        return null;
      } else {
        navigate("/", { replace: true });
        return null;
      }
    case "clientSpoc":
      return isValidToken && orgName !== "Envint" && (userRole === "SPOC" || userRole === "Management")
        ? element
        : navigate("/", { replace: true });
    default:
      navigate("/", { replace: true });
      return null;
  }
};

export default MainProtectedRoute;
