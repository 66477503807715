import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    runTour: false,
    tourCompleted: false,
    showTourModal: false
}

export const tourGuideSlice = createSlice({
    name: "tourGuide",
    initialState,
    reducers: {
        setRunTour: (state, action) => {
            state.runTour = action.payload
        },
        setTourCompleted: (state, action) => {
            state.tourCompleted = action.payload
        },
        setShowTourModal: (state, action) => {
            state.showTourModal = action.payload
        }
    }
})

export const { setRunTour, setTourCompleted, setShowTourModal } = tourGuideSlice.actions

export default tourGuideSlice.reducer