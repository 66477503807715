import { toast } from 'react-toastify';
import axios from 'axios';
import { baseUrl } from '../baseurl';



// Error message mappings
const errorMessages = {
    400: 'Invalid request. Please check your input and try again.',
    401: 'Your session has expired. Please login again.',
    403: 'You don\'t have permission to perform this action.',
    404: 'The requested resource was not found.',
    500: 'Server error or Please Check your Internet Connection.',
    502: 'Server is temporarily unavailable.',
    503: 'Service unavailable. Please try again later.',
};

// error handler utility
export const handleError = (error) => {
    let errorMessage = 'An unexpected error occurred';

    if (axios.isAxiosError(error)) {
        const status = error.response?.status;
        errorMessage = errorMessages[status] || error.response?.data?.message || error.message;
    } else if (error instanceof Error) {
        errorMessage = error.message;
    }
    const validationToastId = 'invalid-character-toast';

    if (!toast.isActive(validationToastId)) {
        toast.error(errorMessage, {
            toastId: validationToastId,
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    // console in development
    if (process.env.NODE_ENV === 'development') {
        console.error('Error details:', error);
    }
};

// Example of a custom axios instance with error handling
export const api = axios.create({
    baseURL: baseUrl,
    // timeout: 10000,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
});

// Add global error handling interceptor
api.interceptors.response.use(
    (response) => response,
    (error) => {
        handleError(error);
        return Promise.reject(error);
    }
);