import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import EditNoteIcon from "@mui/icons-material/EditNote";
import "../clientdashboard.css";
import { usereditbutton, userviewbutton } from "../../../styles";
import ClientLayout from "../../../components/ClientLayout/ClientLayout";
import encrypt, { decryptData } from "../../../services/crypto";
import {
  getOrganizationImage,
  getUserAssignments,
} from "../../../services/ClientSideApis";
import PreviewIcon from "@mui/icons-material/Preview";
import ProgressBar from "./ProgressBar/ProgressBar";
import { Loader } from "../../../components/Loader/Loader";
import { getOrg, getOrgEmail, getUserEmail } from "../../../services/loggedIn";
import { getLoginAttempts } from "../../../services/loggedIn";
import { JoyRide } from "../../../Joyride";
import { useDispatch, useSelector } from "react-redux";
import { setRunTour, setShowTourModal, setTourCompleted } from "../../../redux/slice/TourGuideSlice";
import ConfirmModal from "../../../components/PopUpDialog/ConfirmModal";
import VisibilityIcon from '@mui/icons-material/Visibility';

export const ReportingDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const { showTourModal, runTour, tourCompleted } = useSelector((state) => state.tourGuide);

  const [userAssignmentList, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [savedKpiCount, setSavedKpiCount] = useState(0);
  const [transposed, setTransposed] = useState(false);
  const loginAttempts = getLoginAttempts();

  useEffect(() => {
    // Check if it's the user's first login
    if (loginAttempts <= 3) {
      // setRunTour(true);
      dispatch(setShowTourModal(false))
      dispatch(setTourCompleted(false))
    }
  }, []);

  const steps = [
    {
      target: ".client-dashboard-table-container",
      content:
        "The table shows the assigned KPIs to be filled for each department/Operational unit combination",
      disableBeacon: true,
    },
    {
      target: ".fill-data",
      content: "Click on the button to fill the assigned KPIs.",
    },
  ];

  const handleRunTour = () => {
    dispatch(setRunTour(true))
    dispatch(setShowTourModal(false))
  }

  const handleCancelTour = () => {
    dispatch(setShowTourModal(false))
  }




  useEffect(() => {
    const fetchOrgImg = async () => {
      try {
        const organizationEmail = getOrgEmail();
        const res = await getOrganizationImage(organizationEmail);
        const orgImgUrl = await res.data.imageUrl;
        setImgUrl(orgImgUrl);
      } catch (error) {
        console.error("Error", error);
      }
    };

    fetchOrgImg();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const json = { email: getUserEmail() };

      const encryptData = encrypt({ json });
      try {
        const response = await getUserAssignments(encryptData);
        const decryptedData = decryptData(response.data.userAssignmentList);

        const parsedData = JSON.parse(decryptedData);
        setUserData(parsedData.groupData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleEntryClick = (plant, department) => {
    const plantDepartments = userAssignmentList[plant] || [];
    const departmentStats = plantDepartments.find((obj) =>
      obj.hasOwnProperty(department)
    );

    let savedCount = 0;
    let totalCount = 0;
    let approvedCount;
    let rejectedCount;

    if (departmentStats) {
      savedCount = departmentStats[department].saved;
      totalCount = departmentStats[department].total;
      approvedCount = departmentStats[department].approved;
      rejectedCount = departmentStats[department].rejected;
    }
    navigate("/reporting/fill-kpis", {
      state: {
        orgplt: plant,
        orgdept: department,
        savedCount: savedCount,
        totalCount: totalCount,
        approvedCount: approvedCount,
        rejectedCount: rejectedCount,
      },
    });
  };

  const toggleTransposed = () => {
    setTransposed(!transposed);
  };

  const plants = Object.keys(userAssignmentList).sort();

  const allDepartments = Array.from(
    new Set(
      plants.flatMap((plant) =>
        userAssignmentList[plant].flatMap((obj) => Object.keys(obj))
      )
    )
  ).sort();

  const plantsToDisplay = !transposed ? allDepartments : plants;
  const departmentsToDisplay = !transposed ? plants : allDepartments;


  if (loading) {
    return <Loader loading={loading} />;
  }

  const StatusButton = ({ departmentStats, department, plant, transposed, handleEntryClick }) => {
    const stats = departmentStats[!transposed ? plant : department];
    const completedCount = (stats.saved || 0) + (stats.approved || 0);
    const isComplete = completedCount === stats.total;

    return (
      <>
        {!isComplete &&
          <Tooltip
            sx={{ marginTop: "20px" }}
            title="Fill data"
          >
            <Button
              onClick={() =>
                handleEntryClick(
                  !transposed ? department : plant,
                  !transposed ? plant : department
                )
              }
              style={userviewbutton}
              className="fill-data"
            >
              <EditNoteIcon />
              Fill data
            </Button>
          </Tooltip>
        }
        {isComplete &&
          <Tooltip
            sx={{ marginTop: "20px" }}
            title="View data"
          >
            <Button
              onClick={() =>
                handleEntryClick(
                  !transposed ? department : plant,
                  !transposed ? plant : department
                )
              }
              style={usereditbutton}
            >
              <VisibilityIcon sx={{ width: "18px" }} />
              View data
            </Button>
          </Tooltip>
        }
      </>
    )
  }

  return (
    <div style={{ padding: "20px" }}>
      <ClientLayout>
        <JoyRide
          steps={steps}
        />
        <div className="client-dashboard-container">
          <Box sx={{ display: "flex", margin: "10px 0 20px 0" }}>
            <h3 className="frame_heading">Reporting Dashboard</h3>
          </Box>
          <div className="client-dashboard-table-container">
            <TableContainer sx={{ maxHeight: "440px" }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "25px",
                  padding: "10px",
                }}
                className="report-dashboard-table"
              >
                <TableBody>
                  <TableRow
                    sx={{ position: "sticky", zIndex: 1, background: "#fff" }}
                  >
                    <TableCell style={{ color: "#3079bd" }}>
                      <Tooltip title="Transpose Table">
                        <Button
                          onClick={toggleTransposed}
                          sx={{ textTransform: "none", marginLeft: "10px" }}
                          style={{ color: "#3079bd", fontSize: "1.1rem" }}
                        >
                          {!transposed ? "OU" : "Depts"}{" "}
                          <SwapHorizontalCircleIcon
                            style={{ margin: "0 5px", fontSize: "2rem" }}
                          />{" "}
                          {!transposed ? "Depts" : "OU"}
                        </Button>
                      </Tooltip>
                    </TableCell>
                    {plantsToDisplay.length > 0 ? plantsToDisplay.map((plant) => (
                      <TableCell
                        style={{
                          color: "#3079bd",
                          fontWeight: "600",
                          fontSize: "1.2rem",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100px",
                        }}
                        key={plant}
                      >
                        {plant}
                      </TableCell>
                    )) :
                      <TableCell>
                        <h4 style={{}}>
                          No Assignments Yet!
                        </h4>
                      </TableCell>
                    }
                  </TableRow>

                  {departmentsToDisplay.map((department) => (
                    <TableRow key={department}>
                      <TableCell style={{
                        color: "rgb(69, 182, 83)",
                        fontWeight: "600",
                        fontSize: "1.2rem",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100px",
                      }}>{department}</TableCell>
                      {plantsToDisplay.map((plant, idx) => {
                        const plantDepartments = !transposed
                          ? userAssignmentList[department] || []
                          : userAssignmentList[plant] || [];
                        const departmentStats = plantDepartments.find((obj) =>
                          obj.hasOwnProperty(!transposed ? plant : department)
                        );
                        const hasDepartment = !!departmentStats;

                        return (
                          <TableCell key={idx}>
                            {hasDepartment ? (
                              <>
                                <ProgressBar
                                  currentScore={
                                    departmentStats[
                                      !transposed ? plant : department
                                    ].saved +
                                    departmentStats[
                                      !transposed ? plant : department
                                    ].approved
                                  }
                                  totalScore={
                                    departmentStats[
                                      !transposed ? plant : department
                                    ].total
                                  }
                                />
                                <StatusButton
                                  departmentStats={departmentStats}
                                  department={department}
                                  plant={plant}
                                  transposed={transposed}
                                  handleEntryClick={handleEntryClick}
                                />
                              </>
                            ) : (
                              "N/A"
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </ClientLayout>

      {showTourModal &&
        <ConfirmModal
          open={showTourModal}
          onClose={handleCancelTour}
          title={"Page Tour"}
          message={"Would you like a tour of the page?"}
          confirmText={"Yes, show me around"}
          cancelText={"No, thanks"}
          onConfirm={handleRunTour}
        />
      }
    </div>
  );
};
