import "./index.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./pages/Login/Login.js";
import { Route, Routes } from "react-router-dom";
import UserManagment from "./pages/UserManagement/UserManagment.jsx";
import { OrgManagement } from "./pages/OrganizationManagement/OrgManagement.jsx";
import ForgotPassword from "./pages/ForgotPassword/FP.js";
import { Error } from "./pages/ErrorPage/Error.jsx";
import { AddDept } from "./components/Organization/AddDept/AddDept.jsx";
import { AddDeptMatrix } from "./components/Organization/AddDept/AddDeptMatrix.jsx";
import { EditDept } from "./components/Organization/EditDept/EditDept.jsx";
import { EditDeptMatrix } from "./components/Organization/EditDept/EditDeptMatrix.jsx";
import { LandingPage } from "./pages/LandingPage/LandingPage.jsx";
import PlantTable from "./components/Organization/ViewDOUTable/ViewDoutable.jsx";
import VarOtp from "./pages/Otp/OTP";
import FrameWorks from "./components/Org/FrameW.jsx";
import { Organizations } from "./components/Org/Organizations";
import ManageUser from "./pages/ManageUser/ManageUser.jsx";
import { PriorityDropdown } from "./components/Org/Fw.js";
import TableReview from "./components/Org/TableReview";
import Department from "./components/Org/Department.js";
import DepartmentChange from "./components/Org/DepartmentTable.js";
import DepartmentView from "./components/Org/DepartmentView.js";
import React, { useEffect } from "react";
// --------------------------------------------------------------------
import { ReportingDashboard } from "./pages/ClientSide/ReportingDashboard/ReportingDashboard.js";
import { DataAccordionNew } from "./pages/ClientSide/ReportingDashboard/DataAccordionNew.jsx";
import { HistoryOverview } from "./pages/ClientSide/KPIHistory/HistoryOverview.js";
import DataTableTables from "./pages/ClientSide/ExtraFiles/DataTableTables.js";
import ProtectedRoute from "./services/ProtectedRoute.js";
import MonitoringDashboard from "./dashboard/pages/MonitoringDashboard/MonitoringDashboard.jsx";
import DepartmentTracker from "./dashboard/pages/DepartmentTracker/DepartmentTracker.js";
import UserAdp from "./dashboard/pages/UserAdoption/UserAdp.js";
import { DemoOrgTable } from "./DemoOrgTable.jsx";
import DeptCompTable from "./dashboard/pages/DepartmentTracker/DeptTrackerTable.jsx";
import DeptCompfilter from "./dashboard/pages/DepartmentTracker/DeptCompfilter.js";
import OuComp from "./dashboard/pages/OuTracker/OuTracker.js";
import DashboardLandingPage from "./dashboard/pages/LandingPage/DashboardLandingPage.jsx";
import UserAdoption from "./dashboard/pages/UserAdoption/UserAdoption.js";
import { FilterKPIDataHistory } from "./pages/ClientSide/KPIHistory/FilterKPIDataHistory.js";
import DownloadReport from "./dashboard/pages/DownlaodReports/DownloadReport.jsx";
import SelectKpis from "./components/Org/SelectKpis.js";
import MultiStepAddOrg from "./pages/MultiStepAddOrg/MultiStepAddOrg.js";
import MultiStepAddUser from "./pages/MultiStepAddUser/MultiStepAddUser.js";
import MultiStepEditUser from "./pages/MultiStepEditUser/MultiStepEditUser.js";
import MultiStepEditOrg from "./pages/MultiStepEditOrg/MultiStepEditOrg.js";
import { SpocDataReview } from "./dashboard/pages/SPOCDataReview/SpocDataReview.jsx";
import { SPOCAccordion } from "./dashboard/pages/SPOCDataReview/SPOCAccordion.jsx";
import PopUpDialog from "./components/PopUpDialog/PopUpDialog.jsx";
import ClientLandingPage from "./pages/ClientSide/ClientLandingPage/ClientLandingPage.jsx";
import CommonProtectedRoute from "./services/CommonProtectedRoute.js";
import { clearData } from "./services/loggedIn.js";
import { tokenValidate } from "./services/CommonApis.js";
import MainProtectedRoute from "./services/MainProtectedRoute.js";

import ViewUser from "./pages/ViewUser/ViewUser";
import ViewOrganization from "./pages/OrganizationManagement/ViewOrganization";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary.jsx";

function App() {
  useEffect(() => {
    const validateToken = async () => {
      try {
        const res = await tokenValidate();

        const tokenValid = res.data?.Token;
        if (!tokenValid) {
          clearData();
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          clearData();
        } else {
          console.error("Error occurred:", error.message);
        }
      }
    };

    validateToken().catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    const handleKeydown = (e) => {
      // Check if Ctrl (or Cmd on Mac) + P is pressed
      if ((e.ctrlKey || e.metaKey) && e.key === 'p') {
        e.preventDefault(); // Prevent the print dialog
        toast.error('Print functionality is disabled.');
      }
    };

    window.addEventListener('keydown', handleKeydown);
    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} theme="colored" />
      <Routes>
        {/* Login  */}
        <Route path="/" element={<Login />} />
        <Route path="/varotp" element={<VarOtp />} />
        <Route
          path="/home"
          element={
            <MainProtectedRoute
              element={<LandingPage />}
              routeType={"envintUser"}
            />
          }
        />
        <Route path="/forgotpassword" element={<ForgotPassword />} />

        {/* User management */}
        <Route path="/user">
          <Route
            path="management"
            element={
              <MainProtectedRoute
                element={<UserManagment />}
                routeType={"envintUser"}
              />
            }
          />
          <Route
            path="add-user"
            element={
              <MainProtectedRoute
                element={<MultiStepAddUser />}
                routeType={"envintUser"}
              />
            }
          />
          <Route
            path="add-dept-ou"
            element={
              <MainProtectedRoute
                element={<Department />}
                routeType={"envintUser"}
              />
            }
          />

          <Route
            path="update-user"
            element={
              <MainProtectedRoute
                element={<MultiStepEditUser />}
                routeType={"envintUser"}
              />
            }
          />

          <Route
            path="update-dept-ou"
            element={
              <MainProtectedRoute
                element={<DepartmentChange />}
                routeType={"envintUser"}
              />
            }
          />

          <Route
            path="view-dept-ou"
            element={
              <MainProtectedRoute
                element={<DepartmentView />}
                routeType={"envintUser"}
              />
            }
          />
        </Route>

        {/* Organization Management */}
        <Route path="/organization">
          <Route
            path="management"
            element={
              <MainProtectedRoute
                element={<OrgManagement />}
                routeType={"envintUser"}
              />
            }
          />
          <Route
            path="add-org"
            element={
              <MainProtectedRoute
                element={<MultiStepAddOrg />}
                routeType={"envintUser"}
              />
            }
          />
          <Route
            path="add-dept"
            element={
              <MainProtectedRoute
                element={<AddDept />}
                routeType={"envintUser"}
              />
            }
          />
          <Route
            path="add-dept-table"
            element={
              <MainProtectedRoute
                element={<AddDeptMatrix />}
                routeType={"envintUser"}
              />
            }
          />
          <Route
            path="update-org"
            element={
              <MainProtectedRoute
                element={<MultiStepEditOrg />}
                routeType={"envintUser"}
              />
            }
          // element={<MultiStepEditOrg />}
          />
          <Route
            path="edit-dept"
            element={
              <MainProtectedRoute
                element={<EditDept />}
                routeType={"envintUser"}
              />
            }
          // element={<EditDept />}
          />

          <Route
            path="edit-dept-table"
            element={
              <MainProtectedRoute
                element={<EditDeptMatrix />}
                routeType={"envintUser"}
              />
            }
          // element={<EditDeptMatrix />}
          />
          <Route
            path="view-dept-ou-table"
            element={
              <MainProtectedRoute
                element={<PlantTable />}
                routeType={"envintUser"}
              />
            }
          />
        </Route>

        {/* Assignment Manager routes */}
        <Route path="/assignment">
          <Route
            path="select-organization"
            element={
              <MainProtectedRoute
                element={<Organizations />}
                routeType={"envintUser"}
              />
            }
          />
          <Route
            path="select-priority"
            element={
              <MainProtectedRoute
                element={<PriorityDropdown />}
                routeType={"envintUser"}
              />
            }
          />
          <Route
            path="select-framework"
            element={
              <MainProtectedRoute
                element={<FrameWorks />}
                routeType={"envintUser"}
              />
            }
          />
          <Route
            path="select-kpis"
            element={
              <MainProtectedRoute
                element={<SelectKpis />}
                routeType={"envintUser"}
              />
            }
          />
          <Route
            path="review-dept-ou"
            element={
              <MainProtectedRoute
                element={<TableReview />}
                routeType={"envintUser"}
              />
            }
          />
        </Route>

        {/* Client Side */}
        <Route path="/reporting">
          <Route
            path="home"
            element={
              <MainProtectedRoute
                element={<ClientLandingPage />}
                routeType={"clientUser"}
              />
            }
          />
          <Route
            path="dashboard"
            element={
              <MainProtectedRoute
                element={<ReportingDashboard />}
                routeType={"clientUser"}
              />
            }
          />
          <Route
            path="fill-kpis"
            element={

              <MainProtectedRoute
                element={<DataAccordionNew />}
                routeType={"clientUser"}
              />

            }
          />
          <Route
            path="history/overview"
            element={
              <MainProtectedRoute
                element={<HistoryOverview />}
                routeType={"clientUser"}
              />
            }
          />
          <Route
            path="history/kpi-data"
            element={
              <MainProtectedRoute
                element={<FilterKPIDataHistory />}
                routeType={"clientUser"}
              />
            }
          />
          <Route
            path="DataTableViewForTables"
            element={
              <MainProtectedRoute
                element={<DataTableTables />}
                routeType={"clientUser"}
              />
            }
          />
          <Route
            path="saved-drafted"
            element={
              <MainProtectedRoute
                element={<PopUpDialog />}
                routeType={"clientUser"}
              />
            }
          />
        </Route>

        {/* Dashboard */}
        <Route path="/dashboard">
          <Route
            path="home"
            element={
              <MainProtectedRoute
                element={<DashboardLandingPage />}
                routeType={"clientSpoc"}
              />
            }
          />
          <Route
            path="tracker/kpi-monitoring"
            element={
              <MainProtectedRoute
                element={<MonitoringDashboard />}
                routeType={"clientSpoc"}
              />
            }
          />
          <Route
            path="tracker/department"
            element={
              <MainProtectedRoute
                element={<DepartmentTracker />}
                routeType={"clientSpoc"}
              />
            }
          />
          <Route
            path="tracker/operation-unit"
            element={
              <MainProtectedRoute
                element={<OuComp />}
                routeType={"clientSpoc"}
              />
            }
          />
          <Route
            path="tracker/user-adoption"
            element={
              <MainProtectedRoute
                element={<UserAdp />}
                routeType={"clientSpoc"}
              />
            }
          />
          <Route
            path="spoc-review"
            element={
              <MainProtectedRoute
                element={<SpocDataReview />}
                routeType={"clientSpoc"}
              />
            }
          />
          <Route
            path="review-filled-kpis"
            element={
              <MainProtectedRoute
                element={<SPOCAccordion />}
                routeType={"clientSpoc"}
              />
            }
          />
        </Route>
        {/* Extras */}
        <Route
          path="/organization_list"
          element={<ProtectedRoute element={<ManageUser />} />}
        />
        <Route path="/demo_org_table" element={<DemoOrgTable />} />
        <Route path="/demo_dept_comp" element={<DeptCompTable />} />
        <Route path="/filterDeptComp" element={<DeptCompfilter />} />
        <Route path="/userAdop" element={<UserAdoption />} />
        <Route
          path="/download_report"
          element={<CommonProtectedRoute element={<DownloadReport />} />}
        />
        <Route
          path="/view-org"
          element={<ProtectedRoute element={<ViewOrganization />} />}
        />
        <Route
          path="/view-user"
          element={<ProtectedRoute element={<ViewUser />} />}
        />

        <Route path="/*" element={<Error />} />
      </Routes>
    </>
  );
}

export default App;
