import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "../../../baseurl";
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import "../clientdashboard.css";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DataTableTables from "../ExtraFiles/DataTableTables";
import Slide from "@mui/material/Slide";
import ClientLayout from "../../../components/ClientLayout/ClientLayout";
import encrypt, { decryptData } from "../../../services/crypto";
import { backBtnfixed } from "../../../styles";
import { getUserEmail } from "../../../services/loggedIn";

export const FilterKPIDataHistory = () => {
  const navigate = useNavigate();
  const [fdata, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const data = location.state;
  const { orgplt, orgdept } = data;
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [dataByPillar, setDataByPillar] = useState({});
  const [pillars, setPillars] = useState({});
  const [popupProps, setPopupProps] = useState({});
  const [popupload, setPopupLoad] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [message, setMessage] = useState("");

  const currentDate = new Date();
  const currentMonth = new Date().toLocaleString("en-us", { month: "short" });
  const slicedYear = currentDate.getFullYear().toString().slice(2);
  // // console.log("slicedYear", slicedYear);
  const concatedmonth = `${currentMonth}'${slicedYear}`;

  useEffect(() => {
    const fetchData = async () => {
      // Fetch data from the API
      setLoading(true);
      try {
        // const token = window.localStorage.getItem("token");
        // const headers = {
        //   Authorization: `${token}`,
        //   "Content-Type": "application/json",
        // };
        const res = {
          operationalUnit: data.orgplt,
          department: data.orgdept,
          userEmail: getUserEmail(),
        };

        const encryptData = encrypt({ res });

        const filterRes = await axios.post(
          `${baseUrl}/getAssignmentHistoryData`,
          { encryptedData: encryptData },
          { withCredentials: true }
        );
        // // // console.log("filterRes", filterRes);

        const decryptedData = decryptData(filterRes.data);
        const parsedData = JSON.parse(decryptedData);
        // // // console.log("parsedData", parsedData);

        setDataByPillar(parsedData.transformedData);
        // setDataByPillar(filterData);

        const currentFy = await getFinancialYear(currentDate);

        setSelectedYear(currentFy);
        setSelectedMonth(concatedmonth);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const closePopup = () => {
    setPopupOpen(false);
  };

  const handleDataChange = () => {
    if (dataByPillar[selectedYear]) {
      const yearData = dataByPillar[selectedYear];
      const monthlyData = yearData.monthFrequency.find(
        (month) => Object.keys(month)[0] === selectedMonth
      );
      // // console.log("monthlyData", monthlyData);

      const displayData = selectedYear
        ? selectedMonth && monthlyData
          ? [...yearData.annualFrequency, ...monthlyData[selectedMonth]]
          : yearData.annualFrequency
        : [];

      // // // console.log("displayData", displayData);
      // // // console.log("monthlyData[selectedMonth]", monthlyData[selectedMonth]);

      reorganizeDataByPillar(displayData);
    } else {
      reorganizeDataByPillar([]); // Reset data if selectedYear is not found
    }
  };

  useEffect(() => {
    handleDataChange();
  }, [selectedYear, selectedMonth]);

  const years = Object.keys(dataByPillar || {});
  const monthNames =
    dataByPillar?.[selectedYear]?.monthFrequency?.map(
      (month) => Object.keys(month)[0]
    ) || [];

  if (!monthNames.includes(concatedmonth)) {
    monthNames.push(`No data available for ${concatedmonth}`);
  }

  const handleChangeSelectedYear = (event) => {
    setSelectedYear(event.target.value);
    setSelectedMonth("");
  };

  const handleChangeSelectedMonth = (event) => {
    // setSelectedMonth(event.target.value);
    const selectedValue = event.target.value;
    if (!selectedValue.startsWith("No data available")) {
      setSelectedMonth(selectedValue);
    }
  };

  const reorganizeDataByPillar = (data) => {
    const pillars = {};
    // Extract unique pillars and group items accordingly
    data?.forEach((item) => {
      //   if (item.KPIStatus != "unfilled") {
      const tags = item.CategoryTags.split(":");
      const pillar = tags[0].replaceAll(" ", "_"); // Consider the first value as the pillar
      // Create a new array for the pillar if it doesn't exist
      // Push the item's details to the pillar

      if (!pillars[pillar]) {
        pillars[pillar] = [];
      }
      pillars[pillar].push(item);
    });

    const initialFormData = {};
    const initialCurrentYears = {};
    const initialCurrentMonths = {};

    Object.keys(pillars).forEach((theme) => {
      pillars[theme].forEach((detail) => {
        if (detail.KPICode) {
          if (detail.Year) {
            initialCurrentYears[detail.KPICode] = detail.Year;
          } else if (detail.Month) {
            initialCurrentMonths[detail.KPICode] = detail.Month;
          }
          initialFormData[detail.KPICode] = detail.KPIInput;
        }
      });
    });
    setPillars(pillars);
  };

  const getFinancialYear = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();

    // Financial year starts from April, so if the current month is April or later,
    // it belongs to the current financial year; otherwise, it belongs to the previous financial year
    const financialYearStartMonth = 3; // April (0-based index)
    if (month >= financialYearStartMonth) {
      return `${year}-${year + 1}`;
    } else {
      return `${year - 1}-${year}`;
    }
  };

  const handleNav = async (incommingTableForamt) => {
    // setPopupLoad(true);
    let filter = [];
    filledTable.map((table) => {
      if (table.Format === incommingTableForamt) {
        filter.push(table);
      }
    });
    setPopupOpen(true);
    setPopupProps({ response: filter, tdata: incommingTableForamt });
  };
  let filledTable = [];
  let unfilledTables = [];
  const uniqueKPICodes = new Set();
  Object.keys(pillars).forEach((theme) => {
    if (pillars[theme]) {
      pillars[theme].forEach((detail, i) => {
        if (detail.Format && detail.Format.includes("Table")) {
          if (detail.KPIStatus === "saved") {
            filledTable.push(detail);
          }
          if (detail.KPIStatus === "unfilled") {
            unfilledTables.push(detail);
          }
        }
      });
    }
  });

  const renderInputField = (format, kpiInput, detail) => {
    if (detail && detail.Format && detail.Format.includes("Table")) {
      <React.Fragment>
        <>
          <div>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Button
                onClick={() => handleNav(detail.Format)}
                style={{
                  border: "1px solid #3079bd",
                  // background: "white",
                }}
              >
                View Previous Tables
              </Button>
            </div>
          </div>
        </>
      </React.Fragment>;
    } else {
      switch (format) {
        case "Open Text":
          return <TextField fullWidth disabled multiline value={kpiInput} />;
        case "Number":
          return <TextField disabled type="number" value={kpiInput} />;
        case "Binary":
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                // paddingLeft: "2px",
                marginTop: "-20px",
              }}
            >
              <p style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="radio"
                  name={detail.KPICode}
                  value="yes"
                  checked={detail.KPIInput === "yes"}
                  disabled={detail.KPIStatus === "saved"}
                />
                <span style={{ paddingLeft: "8px" }}>Yes</span>
              </p>
              <p style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="radio"
                  value="no"
                  name={detail.KPICode}
                  checked={kpiInput === "no"}
                  disabled={detail.KPIStatus === "saved"}
                />
                <span style={{ paddingLeft: "8px" }}>No</span>
              </p>
            </div>
          );
        case "Percent":
          const percentage = kpiInput.replaceAll("%", "");
          // // // console.log("percent", percentage);
          return (
            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <TextField
                type="number"
                disabled
                value={percentage}
              // InputProps={{ endAdornment: "%" }}
              />
              %
            </Box>
          );
        case "MCQ":
          return (
            <div>
              <div style={{ display: "flex" }}>
                {detail.Remarks
                  ? detail.Remarks.split(",").map((option, index) => (
                    <label
                      key={index}
                    >
                      <input
                        disabled
                        type="radio"
                        name={detail.KPICode}
                        value={option}
                        checked={detail.KPIInput === option}
                        style={{ paddingLeft: "20px" }}
                      />
                      {option}
                    </label>
                  ))
                  : "No remark from Backend"}
              </div>
            </div>
          );
        case "Ratio":
          return (
            <Box display="flex" alignItems="center" gap={1}>
              <TextField disabled type="number" value={kpiInput.x} label="X" />
              <Typography>:</Typography>
              <TextField disabled type="number" value={kpiInput.y} label="Y" />
            </Box>
          );
        case "Cuurency": //spelling mistake
          return (
            <TextField
              type="number"
              name={detail.KPICode}
              value={detail.kpiInput ? detail.kpiInput : ""}
              placeholder={detail.Format}
              className="client-form-input"
              style={{ width: "16%" }}
              step="0.01"
            />
          );
        case "Telephone":
          let parsedData = JSON.parse(detail.KPIInput);
          const countryCode = parsedData?.countryCode;
          const number = parsedData?.number;
          return (
            <Box sx={{ display: "flex", gap: "10px" }}>
              <TextField
                disabled
                sx={{ width: "70px" }}
                value={countryCode ? countryCode : ""}
              />
              <TextField
                disabled
                sx={{ width: "120px" }}
                value={number ? number : ""}
              />
            </Box>
          );
        default:
          return <TextField fullWidth disabled multiline value={kpiInput} />;
      }
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      {/* <ClientNavbar /> */}
      <ClientLayout>
        <div
          onSubmit={(e) => e.preventDefault()}
          className="client-dashboard-container"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "16px",
              paddingBottom: "20px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h1 className="frame_heading">Filled KPI's for :</h1>
              <span
                style={{
                  color: "#45b653",
                  fontSize: "1.2rem",
                  fontWeight: 700,
                }}
              >{`${orgdept}`}</span>
              <span
                style={{
                  color: "#45b653",
                  fontSize: "1.2rem",
                  fontWeight: 700,
                }}
              >{`${orgplt}`}</span>
            </div>
            <Box sx={{ display: "flex", gap: 2 }}>
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel id="year-label">Select Year</InputLabel>
                <Select
                  id="year"
                  value={selectedYear}
                  label="Select Year"
                  onChange={handleChangeSelectedYear}
                >
                  <MenuItem value="">
                    <em>Select Year</em>
                  </MenuItem>
                  {years.map((year, index) => (
                    <MenuItem key={index} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel id="month-label">Select Month</InputLabel>
                <Select
                  labelId="month-label"
                  id="month"
                  value={selectedMonth}
                  label="Select Month"
                  onChange={handleChangeSelectedMonth}
                >
                  <MenuItem value="">
                    <em>Select Month</em>
                  </MenuItem>
                  {monthNames.map((month) => (
                    <MenuItem
                      key={month}
                      value={month.startsWith("No data available") ? "" : month}
                      disabled={month.startsWith("No data available")}
                    >
                      {month}
                    </MenuItem>
                  ))}
                  {selectedYear && !monthNames.length && (
                    <MenuItem value="" disabled>
                      {`No Data stored at a monthly frequency in the year ${selectedYear}`}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
          </Box>

          <Divider />
          <br />
          {selectedYear && !Object.keys(pillars).length && (
            <h4 style={{ color: "red", paddingTop: "20px" }}>
              {`No data available for the year ${selectedYear} .`}
            </h4>
          )}
          {pillars &&
            Object.keys(pillars).map((pillar) => (
              <Accordion
                sx={{ marginTop: "7px", marginBottom: "7px" }}
                key={pillar}
              >
                <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                  <h4>{pillar.replace("_", " ")}</h4>
                </AccordionSummary>
                <AccordionDetails>
                  {pillars[pillar].map((detail, index) => (
                    <div
                      style={{ padding: "10px", margin: "2%" }}
                      key={detail.KPICode}
                    >
                      <div>
                        {index + 1}. {detail.KPIdata}{" "}
                      </div>
                      <br />
                      {renderInputField(detail.Format, detail.KPIInput, detail)}
                      {detail.Format.includes("Table") && (
                        <div>
                          <br />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Button
                              onClick={() => handleNav(detail.Format)}
                              style={{
                                border: "1px solid #3079bd",
                                // background: "white",
                              }}
                            >
                              View Previous Tables
                            </Button>
                          </div>
                        </div>
                      )}
                      {popupOpen ? (
                        <Dialog
                          fullScreen
                          TransitionComponent={Transition}
                          onClose={closePopup}
                          aria-labelledby="customized-dialog-title"
                          open={true}
                        // style={{ width: "100%" }}
                        >
                          <DialogTitle
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <h3 style={{ color: "#3079bd" }}>
                              {
                                popupProps.response[0].CategoryTags.split(
                                  ":"
                                )[1]
                              }{" "}
                              (Previous Data)
                            </h3>
                            <Button>
                              <IconButton
                                edge="start"
                                color="inherit"
                                onClick={closePopup}
                                aria-label="close"
                              >
                                <CloseIcon />
                              </IconButton>
                            </Button>
                          </DialogTitle>
                          <Divider />
                          <DialogContent>
                            <div>
                              {index + 1}. {popupProps.response[0].KPIdata} ({" "}
                              {popupProps.response[0].KPICode} )
                            </div>
                            <DataTableTables
                              renderd={popupProps.response}
                              formate={popupProps.tdata}
                            />
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={closePopup}>Close</Button>
                          </DialogActions>
                        </Dialog>
                      ) : (
                        <Backdrop
                          sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                          }}
                          open={popupload}
                        >
                          <CircularProgress color="inherit" />
                        </Backdrop>
                      )}
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
        </div>

        <Button type="button" onClick={() => navigate(-1)} style={backBtnfixed}>
          Back
        </Button>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </ClientLayout>
    </div>
  );
};
