import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fieldListKpiInputdata: [],
  addMoreKpiInputdata: [],
  matrixKpiInputdata: [],
};

const kpiSlice = createSlice({
  name: "kpi",
  initialState,
  reducers: {
    setKPIinputdata: (state, action) => {
      // state.fieldListKpiInputdata = action.payload;
      const newKpiData = action.payload;
      const key = Object.keys(newKpiData)[0]; // This will be frequency~kpicode

      const existingIndex = state.fieldListKpiInputdata.findIndex(item => Object.keys(item)[0] === key);

      if (existingIndex !== -1) {
        // Update existing KPI data
        state.fieldListKpiInputdata[existingIndex] = newKpiData;
      } else {
        // Add new KPI data
        state.fieldListKpiInputdata.push(newKpiData);
      }
    },
    setAddMoreKpiInputdata: (state, action) => {
      // state.addMoreKpiInputdata = action.payload;
      const newKpiData = action.payload;
      const key = Object.keys(newKpiData)[0]; // This will be frequency~kpicode

      const existingIndex = state.addMoreKpiInputdata.findIndex(item => Object.keys(item)[0] === key);

      if (existingIndex !== -1) {
        // Update existing KPI data
        state.addMoreKpiInputdata[existingIndex] = newKpiData;
      } else {
        // Add new KPI data
        state.addMoreKpiInputdata.push(newKpiData);
      }
    },
    setMatrixKpiInputdata: (state, action) => {
      // state.matrixKpiInputdata = action.payload;
      const newKpiData = action.payload;
      const key = Object.keys(newKpiData)[0]; // This will be frequency~kpicode

      const existingIndex = state.matrixKpiInputdata.findIndex(item => Object.keys(item)[0] === key);

      if (existingIndex !== -1) {
        // Update existing KPI data
        state.matrixKpiInputdata[existingIndex] = newKpiData;
      } else {
        // Add new KPI data
        state.matrixKpiInputdata.push(newKpiData);
      }
    },
    resetFieldKPIState: (state) => {
      state.fieldListKpiInputdata = [];
    },
    resetAddMoreKPIState: (state) => {
      state.addMoreKpiInputdata = [];
    },
    resetMatrixKPIState: (state) => {
      state.matrixKpiInputdata = [];
    },
  },
});

export const {
  setKPIinputdata,
  setAddMoreKpiInputdata,
  setMatrixKpiInputdata,
  resetFieldKPIState,
  resetAddMoreKPIState,
  resetMatrixKPIState,
} = kpiSlice.actions;
export default kpiSlice.reducer;
