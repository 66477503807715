import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TableContainer, Table, TableBody, TableRow, TableCell, Button, Tooltip, Box } from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import EditNoteIcon from "@mui/icons-material/EditNote";
import "../../../pages/ClientSide/clientdashboard.css";
import { usereditbutton, userviewbutton } from "../../../styles";
import {
  getOrganizationImage,
} from "../../../services/ClientSideApis";
import PreviewIcon from "@mui/icons-material/Preview";
import { Loader } from "../../../components/Loader/Loader";
import ProgressBar from "../../../pages/ClientSide/ReportingDashboard/ProgressBar/ProgressBar";
import DashboardLayout from "../../components/DashboradLayout/DashboardLayout";
import axios from "axios";
import { baseUrl } from "../../../baseurl";
import { getLoginAttempts, getOrgEmail, getUserEmail } from "../../../services/loggedIn";
import { STATUS } from "react-joyride";
import { JoyRide } from "../../../Joyride";
import { useDispatch, useSelector } from "react-redux";
import { setRunTour, setShowTourModal, setTourCompleted } from "../../../redux/slice/TourGuideSlice";
import ConfirmModal from "../../../components/PopUpDialog/ConfirmModal";

export const SpocDataReview = () => {
  const [userAssignmentList, setUserAssignmentList] = useState({});
  const [loading, setLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [savedKpiCount, setSavedKpiCount] = useState(0);
  const [transposed, setTransposed] = useState(false); // State to track transposition

  const loginAttempts = getLoginAttempts();


  const dispatch = useDispatch();

  const { showTourModal, runTour, tourCompleted } = useSelector((state) => state.tourGuide);



  useEffect(() => {
    // Check if it's the user's first login
    if (loginAttempts <= 3) {
      // setRunTour(true);
      dispatch(setShowTourModal(false))
      dispatch(setTourCompleted(false))
    }
  }, []);



  const handleRunTour = () => {
    dispatch(setRunTour(true))
    dispatch(setShowTourModal(false))
  }

  const handleCancelTour = () => {
    dispatch(setShowTourModal(false))
  }


  const steps = [
    {
      target: ".spoc-review-table",
      content: "The table shows the count of filled responses by all users in a particular department and operational units",
      disableBeacon: true,
    },
    {
      target: ".spoc-review-btn",
      content:
        "Click to see and review responses",
    }
  ];

  const navigate = useNavigate();


  useEffect(() => {
    const fetchOrgImg = async () => {
      try {
        const organizationName = localStorage.getItem("org");
        const res = await getOrganizationImage(getOrgEmail());
        const orgImgUrl = await res.data.imageUrl;
        setImgUrl(orgImgUrl);
      } catch (error) {
        console.error("Error", error);
      }
    };

    fetchOrgImg();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const email = getUserEmail();
      try {
        const response = await axios.post(`${baseUrl}/getDashboardStatisticsForReview`,
          {
            email: email
          }
          ,
          {
            withCredentials: true
          }
        )

        setUserAssignmentList(response.data.spocAssignmentList)
        // console.log("/////",response.data.spocAssignmentList)

      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleEntryClick = (plant, department) => {
    const plantDepartments = userAssignmentList[plant] || [];
    const departmentStats = plantDepartments.find((obj) =>
      obj.hasOwnProperty(department)
    );

    let savedCount = 0;
    let totalCount = 0;

    if (departmentStats) {
      savedCount = departmentStats[department].saved;
      totalCount = departmentStats[department].total;
    }
    navigate("/dashboard/review-filled-kpis", {
      state: {
        orgplt: plant,
        orgdept: department,
        savedCount: savedCount,
        totalCount: totalCount,
      },
    });
  };

  const toggleTransposed = () => {
    setTransposed(!transposed);
  };

  const plants = Object.keys(userAssignmentList).sort();

  const allDepartments = Array.from(
    new Set(
      plants.flatMap((plant) =>
        userAssignmentList[plant].flatMap((obj) => Object.keys(obj))
      )
    )
  ).sort();

  const plantsToDisplay = !transposed ? allDepartments : plants;
  const departmentsToDisplay = !transposed ? plants : allDepartments;

  if (loading) {
    return <Loader loading={loading} />
  }

  return (
    <div style={{ padding: "20px" }}>
      <DashboardLayout>
        <JoyRide steps={steps} />
        <div className="client-dashboard-container spoc-review-table">
          <Box sx={{ display: "flex", margin: '10px 0 20px 0' }}>
            <h3 className="frame_heading">Reviewing Dashboard</h3>
          </Box>
          <div className="client-dashboard-table-container">
            <TableContainer sx={{ maxHeight: "440px" }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "25px",
                  padding: "10px",
                }}>
                <TableBody>
                  <TableRow
                    sx={{ position: "sticky", zIndex: 1, background: '#fff' }}
                  >
                    <TableCell style={{ color: '#3079bd' }}>
                      <Tooltip title="Transpose Table">
                        <Button
                          onClick={toggleTransposed}
                          sx={{ textTransform: "none", marginLeft: '10px' }}
                          style={{ color: '#3079bd', fontSize: '1.1rem' }}
                        >
                          {!transposed ? "OU" : "Depts"}  <SwapHorizontalCircleIcon style={{ margin: '0 5px', fontSize: '2rem' }} /> {!transposed ? "Depts" : "OU"}
                        </Button>
                      </Tooltip>
                    </TableCell>
                    {plantsToDisplay.map((plant) => (
                      <TableCell style={{ color: "#3079bd", fontWeight: '600', fontSize: "1.2rem", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px' }} key={plant}>
                        {plant}
                      </TableCell>
                    ))}
                  </TableRow>

                  {departmentsToDisplay.map((department) => (
                    <TableRow key={department}>
                      <TableCell>{department}</TableCell>
                      {plantsToDisplay.map((plant, idx) => {
                        const plantDepartments = !transposed
                          ? userAssignmentList[department] || []
                          : userAssignmentList[plant] || [];
                        const departmentStats = plantDepartments.find((obj) =>
                          obj.hasOwnProperty(!transposed ? plant : department)
                        );
                        const hasDepartment = !!departmentStats;

                        return (
                          <TableCell key={idx}>
                            {hasDepartment ? (
                              <>
                                <ProgressBar
                                  currentScore={
                                    departmentStats[
                                      !transposed ? plant : department
                                    ].saved
                                  }
                                  totalScore={
                                    departmentStats[
                                      !transposed ? plant : department
                                    ].total
                                  }
                                />
                                {departmentStats[
                                  !transposed ? plant : department
                                ].saved !==
                                  departmentStats[
                                    !transposed ? plant : department
                                  ].total && (departmentStats[
                                    !transposed ? plant : department
                                  ].saved > 0) && (
                                    <Tooltip
                                      sx={{ marginTop: "20px" }}
                                      title="Review Data"
                                    >
                                      <Button
                                        onClick={() =>
                                          handleEntryClick(
                                            !transposed
                                              ? department
                                              : plant,
                                            !transposed
                                              ? plant
                                              : department
                                          )
                                        }
                                        style={userviewbutton}
                                        className="spoc-review-btn"
                                      >
                                        <VisibilityIcon />
                                        Review Data
                                      </Button>
                                    </Tooltip>
                                  )}
                                {departmentStats[
                                  !transposed ? plant : department
                                ].saved ===
                                  departmentStats[
                                    !transposed ? plant : department
                                  ].total && (departmentStats[
                                    !transposed ? plant : department
                                  ].saved) && (
                                    <Tooltip
                                      sx={{ marginTop: "20px" }}
                                      title="Review data"
                                    >
                                      <Button
                                        onClick={() =>
                                          handleEntryClick(
                                            !transposed
                                              ? department
                                              : plant,
                                            !transposed
                                              ? plant
                                              : department
                                          )
                                        }
                                        style={usereditbutton}
                                      >
                                        <VisibilityIcon />{" "}
                                        Review Data
                                      </Button>
                                    </Tooltip>
                                  )}
                              </>
                            ) : (
                              "N/A"
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </DashboardLayout>

      {showTourModal &&
        <ConfirmModal
          open={showTourModal}
          onClose={handleCancelTour}
          title={"Page Tour"}
          message={"Would you like a tour of the page?"}
          confirmText={"Yes, show me around"}
          cancelText={"No, thanks"}
          onConfirm={handleRunTour}
        />
      }
    </div>
  );
};