import React from "react";
import "./Error.css";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";

export const Error = () => {

  const navigate = useNavigate();
  return (
    <div className="error">
      <img
        className="error-img"
        src="https://www.supportblackowned.com/images/2022/06/01/5b024136e7aa3c1804ec6cdf.png"
        alt=""
      />
      <h3 className="pnf">PAGE NOT FOUND</h3>
      <p className="error-text">
        The page you are looking for might have been removed, had its name
        <br />
        changed or is temporarily unavailable.
      </p>
      <Button
        variant="outlined"
        className="error-btn"
        onClick={() => navigate(-1)}
      >
        Go to Home
      </Button>
    </div>
  );
};
