import React from "react";
import { TableRow, TableCell, Tooltip, Button } from "@mui/material";
import {userseebutton,userdeletebutton,usereditbutton,usereditbuttonDisabled,userdeletebuttonDisabled,} from "../../styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";
import { FaArrowsAltH } from "react-icons/fa";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import {getOrg, getUserRole} from "../../services/loggedIn";

export const OrgTableRow = ({
  user,
  id,
  onView,
  onDelete,
  onUpdate,
  handleCheckboxTable,
  onManage,
}) => {
  const departmentsData = JSON.parse(user.departments);
  const departmentNames = [];
  departmentsData.forEach((dept) => {
    const deptName = Object.keys(dept)[0];
    departmentNames.push(deptName);
  });
  const departmentCount = departmentNames.length;
  let operationalUnitNames;
  if (user.deleteStatus === false) {
    const oudata = JSON.parse(JSON.parse(user.operationalUnits));
    operationalUnitNames = oudata.map((unit) => Object.keys(unit)[0]);
  }
  if (user.deleteStatus === true) {
    return null;
  }

  const renderActions = (user) => {
    const userRole = getUserRole();
    const currentOrg = getOrg();
    if (user.organizationName === currentOrg && userRole === "Manager") {
      return (
        <>
          <TableCell>
            <Tooltip title="Edit organization">
              <Button
                className="button editOrg"
                id="view0"
                disabled
                onClick={() => onUpdate(user)}
                style={usereditbuttonDisabled}
              >
                <EditNoteIcon />
              </Button>
            </Tooltip>
          </TableCell>
          <TableCell>
            <Tooltip title="Delete organization">
              <Button
                className="button deleteOrg"
                id="delete0"
                disabled
                onClick={() => onDelete(user)}
                style={userdeletebuttonDisabled}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </TableCell>
        </>
      );
    } else if (userRole === "Consultant") {
      return (
        <>
          <TableCell>
            <Tooltip title="Edit organization">
              <Button
                className="button editOrg"
                id="view0"
                disabled
                onClick={() => onUpdate(user)}
                style={usereditbuttonDisabled}
              >
                <EditNoteIcon />
              </Button>
            </Tooltip>
          </TableCell>
          <TableCell>
            <Tooltip title="Delete organization">
              <Button
                className="button deleteOrg"
                id="delete0"
                disabled
                onClick={() => onDelete(user)}
                style={userdeletebuttonDisabled}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </TableCell>
        </>
      );
    } else {
      return (
        <>
          <TableCell>
            <Tooltip title="Edit organization">
              <Button
                className="button editOrg"
                id="view0"
                onClick={() => onUpdate(user)}
                style={usereditbutton}
              >
                <EditNoteIcon />
              </Button>
            </Tooltip>
          </TableCell>
          <TableCell>
            <Tooltip title="Delete organization">
              <Button
                className="button deleteOrg"
                id="delete0"
                onClick={() => onDelete(user)}
                style={userdeletebutton}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </TableCell>
        </>
      );
    }
  };

  return (
    <TableRow className="table-row" style={{ zIndex: "-1", width: "100%" }}>
      <TableCell>{id}</TableCell>
      <TableCell style={{ wordBreak: "break-all" }}>
        <img
          src={user.image}
          alt="logo"
          style={{ width: "50px", height: "50px", objectFit: "contain" }}
        />
      </TableCell>
      <TableCell>{user.organizationName}</TableCell>
      <TableCell>{user.spocEmail}</TableCell>
      <TableCell>{user.spocMobile}</TableCell>
      <TableCell>{departmentCount}</TableCell>
      <TableCell>
        <Tooltip title="View department & operation units association">
          <Button
            className="button"
            id="view0"
            onClick={() => handleCheckboxTable(user)}
            style={usereditbutton}
          >
            <FaArrowsAltH />
          </Button>
        </Tooltip>
      </TableCell>
      <TableCell>{operationalUnitNames.length}</TableCell>
      <TableCell>
        <Tooltip title="View Details">
          <Button
            className="button viewOrg"
            onClick={() => onView(user)}
            style={userseebutton}
          >
            <VisibilityIcon />
          </Button>
        </Tooltip>
      </TableCell>

      {renderActions(user)}

      <TableCell>
        <Tooltip title="Manage User">
          <Button
            className="button manageUsers"
            id="delete0"
            onClick={() => onManage(user)}
            style={userseebutton}
          >
            <ManageAccountsIcon />
          </Button>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};
